import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./layouts/UseContext";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";

function Auth() {
  const navigate = useNavigate();
  const { setUser, userId } = useContext(UserContext);
  const { logoutUser } = useContext(UserContext);
  const { user, loginWithRedirect, logout } = useAuth0();
  const location = useLocation();
  const queryParamsStatus = new URLSearchParams(location?.search);
  const InstId = queryParamsStatus.get("InstId");
  const Role = queryParamsStatus.get("Role");
  const DSID = queryParamsStatus.get("drivingSchoolId");
  const oldStudentId = queryParamsStatus.get("oldStudentId");
  const [reload, setReload] = useState("");
  const [apiCallCount, setApiCallCount] = useState(0);

  const [showTroubleMessage, setShowTroubleMessage] = useState(false);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    logoutUser();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowTroubleMessage(true);
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (Role != null && oldStudentId != null) {
      loginWithRedirect({
        authorizationParams: {
          role: Role,
          oldStudentId: oldStudentId,
        },
      });
    } else if (Role != null && DSID != null) {
      loginWithRedirect({
        authorizationParams: {
          role: Role,
          dsId: DSID,
        },
      });
    } else if (Role != null && InstId != null) {
      loginWithRedirect({
        authorizationParams: {
          role: Role,
          instructorId: InstId,
        },
      });
    } else if (Role != null) {
      loginWithRedirect({
        authorizationParams: {
          role: Role,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (user && apiCallCount < 25) {
      setApiCallCount(apiCallCount + 1);

      let data = JSON.stringify({
        given_name: user?.given_name,
        family_name: user?.family_name,
        nickname: user?.nickname,
        name: user?.name,
        picture: user?.picture,
        locale: user?.locale,
        updated_at: user?.updated_at,
        email: user?.email,
        email_verified: user?.email_verified,
        sub: user?.sub,
        userRole: user?.role_metadata ?? user?.app_metadata?.role ?? null,
        userSubRole:
          user?.dsid_metadata != null || user?.app_metadata?.dsid != null
            ? "ADMIN"
            : "SUPER_ADMIN",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/userAuthentication/authLogin`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          let user_id = response?.data?.data?.id;
          setUser(user_id);
          localStorage.setItem(
            "userSubRole",
            response?.data?.data?.userSubRole
          );
          console.log("mein hu auth ka data", response?.data?.data);
          localStorage.setItem("userRole", response?.data?.data?.userRole);
          localStorage.setItem(
            "userData2",
            JSON.stringify(response?.data?.data)
          );

          if (response?.data?.data?.userRole === "INSTRUCTOR") {
            alert("You are not a Client");
            logout({ logoutParams: { returnTo: window.location.origin } });
            logoutUser();
          } else if (
            response?.data?.data?.userRole !== "INSTRUCTOR" &&
            user?.email_verified === false
          ) {
            navigate("/emailVerify");
            console.log("3");
          } else if (
            (user?.role_metadata === "STUDENT" &&
              response?.data?.data?.userRole === "STUDENT" &&
              response?.data?.data?.dataStored === false &&
              user?.email_verified === true) ||
            (user?.role_metadata === "STUDENT" &&
              response?.data?.data?.userRole === "STUDENT" &&
              response?.data?.data?.dataStored === false &&
              user?.email_verified === "true")
          ) {
            let data = JSON.stringify({
              file: user?.picture,
              userAuthenticationId: user_id,
              instructorId: null,
              oldStudentId:
                user?.oldInst_metadata ?? user?.app_metadata?.oldInst ?? null,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_BASE_URL}/api/student/uploadAuthImage`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios
              .request(config)
              .then((response) => {
                console.log("meine pic bheji");
                user_id = response?.data?.data?.id;
                setUser(user_id);
                setReload(Math.random());
                console.log("1");
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (
            (user?.role_metadata !== "STUDENT" &&
              response?.data?.data?.userRole === "STUDENT") ||
            (user?.role_metadata === "STUDENT" &&
              response?.data?.data?.userRole !== "STUDENT") ||
            (user?.role_metadata !== "DRIVING_SCHOOL" &&
              response?.data?.data?.userRole === "DRIVING_SCHOOL") ||
            (user?.role_metadata === "DRIVING_SCHOOL" &&
              response?.data?.data?.userRole !== "DRIVING_SCHOOL") ||
            (user?.role_metadata === "BOOK_DRIVE" &&
              response?.data?.data?.userRole !== "BOOK_DRIVE") ||
            (user?.role_metadata !== "BOOK_DRIVE" &&
              response?.data?.data?.userRole === "BOOK_DRIVE")
          ) {
            // navigate("/?role=c");
            logoutUser();
            logout({
              logoutParams: {
                returnTo: `${window.location.origin}/?r=c`,
              },
            });
          } else if (
            (user?.role_metadata === "DRIVING_SCHOOL" &&
              response?.data?.data?.dataStored === false &&
              user?.email_verified === true) ||
            (user?.app_metadata?.role === "DRIVING_SCHOOL" &&
              response?.data?.data?.dataStored === false &&
              user?.email_verified === true) ||
            (response?.data?.data?.userRole === "BOOK_DRIVE" &&
              response?.data?.data?.dataStored === false &&
              user?.email_verified === true)
          ) {
            navigate("/admin/addDrivingSchool");
            console.log("2");
          } else if (
            (response?.data?.data?.userRole === "BOOK_DRIVE" ||
              response?.data?.data?.userRole === "DRIVING_SCHOOL") &&
            response?.data?.data?.dataStored === true &&
            user?.email_verified === true
          ) {
            navigate("/admin/stripeConnect");
            console.log("2.1");
          } else if (
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.preferenceForLesson === true &&
              response?.data?.data?.experience === true) ||
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === "true" &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.preferenceForLesson === true &&
              response?.data?.data?.experience === true)
          ) {
            navigate("/myLessons");
            console.log("4");
          } else if (
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === true &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === false) ||
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === "true" &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === false)
          ) {
            navigate(`/clientPersonalDetails`);
            console.log("5");
          } else if (
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === true &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.experience === false) ||
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === "true" &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.experience === false)
          ) {
            navigate(`/clientExperience`);
            console.log("6");
          } else if (
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === true &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.experience === true &&
              response?.data?.data?.preferenceForLesson === false) ||
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === "true" &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.experience === true &&
              response?.data?.data?.preferenceForLesson === false)
          ) {
            navigate(`/preferenceForLessons`);
            console.log("7");
          } else if (
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === true &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.experience === true &&
              response?.data?.data?.preferenceForLesson === true &&
              response?.data?.data?.paymentMethod === false) ||
            (response?.data?.data?.userRole === "STUDENT" &&
              user?.email_verified === "true" &&
              response?.data?.data?.dataStored === true &&
              response?.data?.data?.personalDetails === true &&
              response?.data?.data?.experience === true &&
              response?.data?.data?.preferenceForLesson === true &&
              response?.data?.data?.paymentMethod === false)
          ) {
            navigate(`/myLessons`);
            console.log("8");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("9");
          logout({ logoutParams: { returnTo: window.location.origin } });
          logoutUser();
          navigate(`/`);
        });
    } else if (apiCallCount >= 25) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      logoutUser();
      console.log("10");
      navigate(`/`);
    }
  }, [user, reload]);

  return (
    <div className="login_outer_container" style={{ backgroundColor: "white" }}>
      <div className="login_inner_container">
        <CircularProgress />
        {showTroubleMessage && (
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              color: "red",
              justifyItems: "end",
            }}
          >
            Having trouble in login?{" "}
            <span onClick={handleLogout}>
              <u>Click Here</u>
            </span>{" "}
            and try later.
          </p>
        )}
      </div>
    </div>
  );
}

export default Auth;
