import React, { useEffect, useState, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import axios from "axios";

export const DrivingSchool = () => {
  const storedUserId = localStorage.getItem("userId");
  const storedUserRole = localStorage.getItem("userRole");
  const [loading, setLoading] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSchoolList, setFilteredSchoolList] = useState([]);

  useEffect(() => {
    if (storedUserId) {
      setLoading(true);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          const filteredSchoolList = response?.data.filter(
            (school) => school.id !== storedUserId
          );

          console.log(filteredSchoolList);
          setFilteredSchoolList(filteredSchoolList);

          setSchoolList(filteredSchoolList);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [storedUserId]);

  useEffect(() => {
    let lowercaseValue = searchQuery?.toLowerCase();
    const filtered = schoolList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.businessName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.contactNumber?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.vat?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.email?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredSchoolList(filtered);
  }, [searchQuery, schoolList]);

  return (
    <div className="admin_outer_container">
      <NavbarAdmin />
      <div style={{ width: "100%", margin: "1rem 2rem" }}>
        <div className="adminInstructors_list_row1">
          <h1>Driving Schools</h1>
        </div>

        <div className="searchBar">
          <BsSearch style={{ color: "#6FA5EF" }} />
          <input
            type="text"
            placeholder="Search...."
            onChange={(e) => {
              console.log(e.target.value);
              setSearchQuery(e.target.value);
            }}
            style={{ outline: "none" }}
            className=""
          />
        </div>

        {loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10rem",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeading">
                    Driving Schools
                  </TableCell>
                  <TableCell className="tableHeading">VAT</TableCell>
                  <TableCell className="tableHeading">Email</TableCell>
                  <TableCell className="tableHeading">Phone Number</TableCell>
                  <TableCell className="tableHeading">Address</TableCell>
                  <TableCell className="tableHeading">Status</TableCell>
                  <TableCell className="tableHeading">
                    Instructor List
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSchoolList?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <div className="instructor_info_1">
                        <img
                          src={row?.profilePic}
                          style={{ borderRadius: "50%" }}
                          alt="asds"
                        />
                        <div className="license_name">
                          <p> {row?.businessName}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="contact_info">
                        <p> {row?.vat}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="contact_info">
                        <p>
                          {row?.email && row.email.length > 20
                            ? `${row.email.substring(0, 20)}...`
                            : row.email}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="contact_info">
                        <p> {row?.contactNumber}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="contact_info">
                        <p>
                          {row?.address && row.address.length > 20
                            ? `${row.address.substring(0, 20)}...`
                            : row.address}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row?.isActive === true ? "Active" : "Suspend"}
                    </TableCell>

                    <TableCell>
                      <NavLink to={`/admin/instructor/${row?.id}`}>
                        <div className="contact_info">
                          <button>Instructors</button>
                        </div>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};
