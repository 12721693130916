import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Footer() {
  const [openTerms, setOpenTerms] = useState(false);
  const [openPolicy, setOpenPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    // Called when the iframe content has loaded
    setIsLoading(false);
  };

  const location = useLocation();

  // Check if the current URL is "/termsAndCondition"
  if (
    location.pathname === "/termsAndConditions" ||
    location.pathname === "/policy"
  ) {
    return null; // Don't render the footer
  }

  return (
    <>
      <footer
        className="footer"
        style={{
          color: "#6FA5EF",
        }}
      >
        <p
          style={{
            color: "#6fa5ef",
            // backgroundColor: "white",
          }}
        >
          Copyright © 2023 {/* <NavLink to="/" style={{ color: "#6fa5ef" }}> */}
          BookDrive
          {/* </NavLink>{" "} */} |{" "}
          <NavLink
            onClick={() => setOpenTerms(true)}
            style={{ color: "#6fa5ef" }}
          >
            Terms and Services{" "}
          </NavLink>
          |{" "}
          <NavLink
            onClick={() => setOpenPolicy(true)}
            style={{ color: "#6fa5ef" }}
          >
            Privacy Policy
          </NavLink>
        </p>
      </footer>
      <Modal
        open={openPolicy}
        onClose={() => {
          setOpenPolicy(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2" style={{ width: "400px", height: "500px" }}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <iframe
            style={{ width: "100%", height: "90%", marginBottom: ".5rem" }}
            onLoad={handleIframeLoad}
            src={window.location.origin + "/policy"}
            frameborder="0"
          ></iframe>
          <button
            style={{
              position: "sticky",
              width: "max-content",
              textAlign: "end",
              backgroundColor: "#6FA5EF",
            }}
            onClick={() => {
              setOpenPolicy(false);
            }}
          >
            OK
          </button>
        </Box>
      </Modal>

      <Modal
        open={openTerms}
        onClose={() => {
          setOpenTerms(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2" style={{ width: "400px", height: "500px" }}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <iframe
            style={{ width: "100%", height: "90%", marginBottom: ".5rem" }}
            onLoad={handleIframeLoad}
            src={window.location.origin + "/termsAndConditions"}
            frameborder="0"
          ></iframe>
          <button
            style={{
              position: "sticky",
              width: "max-content",
              textAlign: "end",
              backgroundColor: "#6FA5EF",
            }}
            onClick={() => {
              setOpenTerms(false);
            }}
          >
            OK
          </button>
        </Box>
      </Modal>
    </>
  );
}
