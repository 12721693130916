import React, { useContext, useState } from "react";
import { Select, Space } from "antd";
import { TbPigMoney, TbTruckDelivery } from "react-icons/tb";
import { BsFillBookFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { IoMdDoneAll } from "react-icons/io";
import { FiClock } from "react-icons/fi";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import { useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
const { Option } = Select;

export const AdminDashboard = () => {
  const storedUserId = localStorage.getItem("userId");
  const storedUserRole = localStorage.getItem("userRole");
  const [dashboardData, setDashboardData] = useState("");
  const [instructorList, setInstructorList] = useState([]);
  const [id, setId] = useState(storedUserId);
  const [month, setMonth] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const CustomTimeOption = () => {
    return (
      <div style={{ marginTop: "1rem", display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Start Date</label>
          <input
            type="date"
            value={startDate}
            max={new Date().toISOString().split("T")[0]}
            onChange={(e) => setStartDate(e.target.value)}
            style={{
              marginRight: "8px",
              width: "max-content",
              padding: "0.5rem",
              borderRadius: "5px",
            }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>End Date</label>
          <input
            type="date"
            value={endDate}
            max={new Date().toISOString().split("T")[0]}
            onChange={(e) => setEndDate(e.target.value)}
            min={startDate} // Set the min attribute to Start Date
            disabled={!startDate} // Disable the End Date input if Start Date is not filled
            style={{
              marginRight: "8px",
              width: "max-content",
              padding: "0.5rem",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    let url;

    if (storedUserRole === "BOOK_DRIVE") {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=${storedUserId}`;
    }

    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {},
    };
    axios
      .request({ ...config, url: url })
      .then((response) => {
        console.log(response?.data?.data?.instructor);
        setInstructorList(response?.data?.data?.instructor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storedUserId]);

  useEffect(() => {
    let apiInstId;
    let dsId;
    let requestBody;

    if (id === storedUserId) {
      apiInstId = "";
      dsId = storedUserId;
    } else {
      apiInstId = id;
      dsId = "";
    }

    if (month === "") {
      requestBody = {
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      requestBody = {
        startDate: null,
        endDate: null,
      };
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/dashboard/totalValue?instructorId=${apiInstId}&month=${month}&drivingSchoolId=${dsId}`,
      headers: {},
      data: requestBody,
    };

    console.log(config.url);

    axios
      .request(config)
      .then((response) => {
        setDashboardData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, month, storedUserId, endDate]);

  const handleOptionSelect = (selectedOption) => {
    console.log(selectedOption);
    setMonth(selectedOption.value);
  };

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />

        <div style={{ width: "100%", margin: "1rem 2rem" }}>
          <div className="adminDashboard_row1">
            <div className="leftside">
              <h1>Dashboard</h1>
            </div>
            <div>
              <div className="rightSide">
                <Space style={{ width: "100%" }}>
                  <Select
                    defaultValue="All Instructors"
                    showSearch
                    onChange={(value) => {
                      setId(value);
                    }}
                    style={{ width: "150px" }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={storedUserId}>All Instructors</Option>
                    {Array.isArray(instructorList) &&
                      instructorList?.map((instructor) => (
                        <Option
                          key={instructor?.profile?.instructorId}
                          value={instructor?.profile?.instructorId}
                        >
                          {`${instructor?.profile?.firstName} ${instructor?.profile?.lastName}`}
                        </Option>
                      ))}
                  </Select>
                </Space>
                <Space style={{ width: "100%" }}>
                  <Select
                    defaultValue="0"
                    style={{
                      width: "100%",
                    }}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      setMonth(selectedOption);
                    }}
                    options={[
                      {
                        value: "0",
                        label: "All Months",
                      },
                      {
                        value: "1",
                        label: "Last Month",
                      },
                      { value: "3", label: "Last 3 Months" },
                      { value: "6", label: "Last 6 Months" },
                      { value: "", label: "Custom" },
                    ]}
                  />
                </Space>
              </div>
              <div>
                {month === "" && (
                  <CustomTimeOption
                    onSave={(customOption) => handleOptionSelect(customOption)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="adminDashboard_row2">
            <div className="blocks">
              <div className="block">
                <TbPigMoney size={40} />
                <p>Revenue</p>
                <h2>£ {dashboardData?.revenue ?? "0"}</h2>
              </div>
              {id === storedUserId ? (
                <NavLink to={`/admin/calendar/${storedUserId}`}>
                  <div className="block">
                    <BsFillBookFill size={40} />
                    <p>Bookings</p>
                    <h2>{dashboardData?.Bookings ?? "0"}</h2>
                  </div>
                </NavLink>
              ) : (
                <NavLink to={`/inst/calendar/${id}`}>
                  <div className="block">
                    <BsFillBookFill size={40} />
                    <p>Bookings</p>
                    <h2>{dashboardData?.Bookings ?? "0"}</h2>
                  </div>
                </NavLink>
              )}
              {id === storedUserId ? (
                <NavLink to={`/admin/calendar/${storedUserId}`}>
                  <div className="block">
                    <MdCancel size={40} />
                    <p>Cancelled Bookings</p>
                    <h2>{dashboardData?.cancelledBookings ?? "0"}</h2>
                  </div>
                </NavLink>
              ) : (
                <NavLink to={`/inst/calendar/${id}`}>
                  <div className="block">
                    <MdCancel size={40} />
                    <p>Cancelled Bookings</p>
                    <h2>{dashboardData?.cancelledBookings ?? "0"}</h2>
                  </div>
                </NavLink>
              )}

              {id === storedUserId ? (
                <NavLink to={`/admin/calendar/${storedUserId}`}>
                  <div className="block">
                    <IoMdDoneAll size={40} />
                    <p>Lessons Completed</p>
                    <h2>{dashboardData?.lessonCompleted ?? "0"}</h2>
                  </div>
                </NavLink>
              ) : (
                <NavLink to={`/inst/calendar/${id}`}>
                  <div className="block">
                    <IoMdDoneAll size={40} />
                    <p>Lessons Completed</p>
                    <h2>{dashboardData?.lessonCompleted ?? "0"}</h2>
                  </div>
                </NavLink>
              )}

              {id === storedUserId ? (
                <NavLink to={`/admin/students?tab=2`}>
                  <div className="block">
                    <FiClock size={40} />
                    <p>Waiting List</p>
                    <h2>{dashboardData?.waitingList ?? "0"}</h2>
                  </div>
                </NavLink>
              ) : (
                <NavLink to={`/admin/students?instId=${id}&tab=2`}>
                  <div className="block">
                    <FiClock size={40} />
                    <p>Waiting List</p>
                    <h2>{dashboardData?.waitingList ?? "0"}</h2>
                  </div>
                </NavLink>
              )}

              {/* <NavLink to={`/admin/students?tab=2`}>
                <div className="block">
                  <FiClock size={40} />
                  <p>Waiting List</p>
                  <h2>{dashboardData?.waitingList ?? "0"}</h2>
                </div>
              </NavLink> */}

              <div className="block">
                <TbTruckDelivery size={40} />
                <p>Delivered hours</p>
                <h2>{dashboardData?.deliveredHours ?? "0"}</h2>
              </div>
            </div>
            <div className="rightBlock">
              <div className="upperSection">
                <p>Pass Rate</p>
                <h1>
                  {dashboardData?.passRate?.toFixed(2) ?? "0"} <small>%</small>
                </h1>
              </div>
              <div className="lowerSection">
                <div>
                  <p>Pass</p>
                  <h4>{dashboardData?.pass}</h4>
                </div>
                <div>
                  <p>Fail</p>
                  <h4>{dashboardData?.fail}</h4>
                </div>
                <div>
                  <p>Cancelled</p>
                  <h4>{dashboardData?.cancelled}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
