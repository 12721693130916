import React, { useContext, useEffect, useState } from "react";
import logo from "./../assets/img/BookDrive_logo-invert.jpg";
import { NavLink } from "react-router-dom";
import { MdCircleNotifications } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { IoHelpBuoy } from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "./UseContext";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Help } from "./Help";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { Dropdown, Space } from "antd";

const Navbar = () => {
  const { logout } = useAuth0();
  const { logoutUser } = useContext(UserContext);
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    logoutUser();
  };
  const [studentData, setStudentData] = useState("");
  const { userId } = useContext(UserContext);
  const [notiNumber, setNotiNumber] = useState();
  const { user } = useAuth0();

  const calculateTime = (updatedAt) => {
    const currentDate = new Date();
    const backendDate = new Date(updatedAt);
    const currentTime = currentDate.getTime();
    const backendTime = backendDate.getTime();
    const timeDifference = currentTime - backendTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let formattedTimeDifference = "";

    if (days > 0) {
      formattedTimeDifference = `${days} days`;
    } else if (hours > 0) {
      formattedTimeDifference = `${hours} hours`;
    } else if (minutes > 0) {
      formattedTimeDifference = `${minutes} mins`;
    } else {
      formattedTimeDifference = `${seconds} secs`;
    }

    return formattedTimeDifference;
  };

  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
        headers: {},
      };
      axios
        .request(config)
        .then((response) => {
          setStudentData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId]);

  const [notificationList, setNotificationList] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification/markRead?createdForId=${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setNotiNumber();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const readNotification = () => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification/markRead?createdForId=${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setNotiNumber();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/notification?createdForId=${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response.data.sort(
            (a, b) => b?.updatedAt - a?.updatedAt
          );
          setNotificationList(sorted);
          let unreadNoti = response.data.filter((item) => !item.read).length;
          setNotiNumber(unreadNoti);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId]);

  const [menuOpen, setMenuOpen] = React.useState(false);

  const softDeleteNotification = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification/SoftDelete/clientID/${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setNotificationList([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const items = [
    {
      key: "1",
      label: (
        <NavLink
          activeClassName="active"
          className="nav-link  "
          style={{ color: "black" }}
          to="/myLessons"
        >
          My Lessons
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink
          activeClassName="active"
          className="nav-link  "
          style={{ color: "black" }}
          to="/connectedInstructor"
        >
          Connected Instructor
        </NavLink>
      ),
    },
    {
      key: "3",
      label: (
        <NavLink
          activeClassName="active"
          className="nav-link"
          style={{ color: "black" }}
          to="/findInstructor"
        >
          Find an Instructor
        </NavLink>
      ),
    },
    {
      key: "4",
      label: (
        <NavLink
          activeClassName="active"
          className="nav-link "
          style={{ color: "black" }}
          to="/benefits"
        >
          Benefits
        </NavLink>
      ),
    },
    {
      key: "5",
      label: <hr style={{ width: "100%" }} />,
    },
    {
      key: "6",
      label: (
        <NavLink
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          to={"/userProfile"}
        >
          <CgProfile />
          <p>My Profile</p>
        </NavLink>
      ),
    },
    {
      key: "7",
      label: (
        <div
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={() => setMenuOpen(true)}
        >
          <IoHelpBuoy /> Help
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <NavLink
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            gap: " 5px",
          }}
          onClick={handleLogout}
        >
          <BiLogOut /> Logout
        </NavLink>
      ),
    },
  ];

  const sortedNotifications = [...notificationList].sort((a, b) => {
    // Assuming createdAt is in ISO date string format, you can use Date.parse
    const dateA = Date.parse(a.createdAt);
    const dateB = Date.parse(b.createdAt);
    return dateB - dateA; // Sort in descending order
  });

  return (
    <>
      <nav className="nav">
        <div className="left_nav">
          <div className="logo">
            {userId ? (
              <NavLink to="/myLessons" style={{ marginTop: "0" }}>
                <img src={logo} alt="" />
              </NavLink>
            ) : (
              ""
              // <NavLink to="/" style={{ marginTop: "0" }}>
              //   <img src={logo} alt="" />
              // </NavLink>
            )}
          </div>

          {userId ? (
            <>
              <ul className="navbar-items">
                <li className="nav-item active">
                  <NavLink
                    activeClassName="active"
                    className="nav-link  "
                    to="/myLessons"
                  >
                    My Lessons
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link  "
                    to="/connectedInstructor"
                  >
                    Connected Instructor
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link  "
                    to="/findInstructor"
                  >
                    Find an Instructor
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link  "
                    to="/benefits"
                  >
                    Benefits
                  </NavLink>
                </li>
              </ul>
            </>
          ) : (
            ""
          )}
        </div>

        {userId ? (
          <>
            <div className="right_navbar" style={{ alignItems: "center" }}>
              <div className="dropdown" style={{ margin: "0 1rem" }}>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open Notifications">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      className="notification-badge"
                      sx={{ p: 0 }}
                    >
                      <MdCircleNotifications
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          color: "white",
                        }}
                      />
                      {notiNumber > 0 && (
                        <div className="badge">{notiNumber}</div>
                      )}
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px", maxHeight: "600px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <div className="notificationHeader">
                      <h4>Notifications</h4>
                      <p
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => softDeleteNotification()}
                      >
                        Clear All
                      </p>
                    </div>
                    {sortedNotifications?.map((i, index) => (
                      <MenuItem key={index} className="navbarNotification">
                        <div className="notificationLi">
                          <div className="notificationContent">
                            <Accordion>
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                  display: "flex ",
                                  justifyContent: "space-between ",
                                  alignItems: "center",
                                  width: "100%",
                                  padding: "0",
                                }}
                              >
                                <p style={{ overflow: "hidden", margin: 0 }}>
                                  {i?.title}
                                </p>

                                <div
                                  style={{
                                    fontWeight: "600",
                                    display: "flex",
                                    alignItems: "center",
                                    letterSpacing: "0",
                                  }}
                                >
                                  <p>{calculateTime(i?.createdAt)}</p>
                                  <KeyboardArrowDownIcon />
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>{i?.message}</AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </div>

              <div className="dropdown" style={{ margin: "0 1rem" }}>
                <button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{
                    margin: "0",
                    backgroundColor: "transparent",
                    padding: "0",
                  }}
                >
                  <img
                    src={studentData?.profile?.profilePicture ?? user?.picture}
                    style={{
                      width: "20px",
                      height: "20px",
                      border: ".5px solid white",
                      borderRadius: "50%",
                      padding: "5px",

                      color: "white",
                      backgroundColor:
                        studentData?.profile?.profileColor ?? "#6FA5EF",
                    }}
                    alt=""
                  />
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  // style={{ color: "white" }}
                >
                  <MenuItem onClick={handleClose}>
                    <NavLink
                      style={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      to={"/userProfile"}
                    >
                      <CgProfile />
                      <p>My Profile</p>
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={() => setMenuOpen(true)}>
                    <div
                      style={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <IoHelpBuoy /> Help
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink
                      style={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      onClick={handleLogout}
                    >
                      <BiLogOut /> Logout
                    </NavLink>
                  </MenuItem>
                </Menu>
                <Help menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </nav>
      <nav className="nav_responsive">
        <div className="logo">
          {userId ? (
            <NavLink to="/myLessons" style={{ marginTop: "0" }}>
              <img src={logo} alt="BookDrive" />
            </NavLink>
          ) : (
            ""
            // <NavLink to="/" style={{ marginTop: "0" }}>
            //   <img src={logo} alt="BookDrive" />
            // </NavLink>
          )}
        </div>
        {userId ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Open Notifications">
              <NavLink to="/notifications" style={{ marginTop: "0" }}>
                <IconButton
                  className="notification-badge"
                  sx={{ p: 0 }}
                  onClick={readNotification}
                  style={{ width: "auto" }}
                >
                  <MdCircleNotifications
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      color: "white",
                    }}
                  />
                  {notiNumber > 0 && <div className="badge">{notiNumber}</div>}
                </IconButton>
              </NavLink>
            </Tooltip>
            <Space direction="vertical">
              <Space wrap>
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                >
                  <MenuIcon className="hamburger" />
                </Dropdown>
              </Space>
            </Space>
          </div>
        ) : (
          ""
        )}
      </nav>
    </>
  );
};

export default Navbar;
