import React, { useContext, useState, useEffect } from "react";
import progress25 from "./../../../assets/img/25-1.png";
import { AiOutlineUpload } from "react-icons/ai";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddressField from "../../../layouts/AddressField";
import { UserContext } from "../../../layouts/UseContext";
import PhoneInput from "react-phone-number-input";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { CircularProgress } from "@mui/material";

const PersonalDetails = () => {
  const { userId } = useContext(UserContext);
  const [clientData, setClientData] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.data);
        setClientData(response?.data?.data);
        setName(response?.data?.data?.profile?.firstName);
        setAddress(response?.data?.data?.address[0]?.address);
        setPhoneNumber(response?.data?.data?.profile?.phnNumber);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  React.useEffect(() => {
    if (
      name?.trim() !== "" &&
      phoneNumber?.trim() !== "" &&
      address?.trim() !== ""
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [name, address, phoneNumber]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          canvas.toBlob((blob) => {
            setSelectedImage(new File([blob], "cropped_image.png"));
          }, "image/png");
        };

        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const formData = new FormData();

    if (!selectedImage) {
      formData.append("file", user?.picture);
      formData.append("studentId", userId);
    } else {
      formData.append("file", selectedImage);
      formData.append("studentId", userId);
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/student/image`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [selectedImage, userId, user]);

  const handleSubmit = () => {
    let data = JSON.stringify({
      id: userId,
      name: name,
      address: address,
      postalCode: postalCode,
      mobileNumber: phoneNumber,
      theoryPassedOn: "",
      personalDetails: true,
      experience: false,
      preferenceForLesson: false,
      paymentMethod: false,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/signupDetails`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        navigate(`/clientExperience`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="signup_outer_container">
      <div className="signup_inner_container">
        <div className="heading">
          <img src={progress25} alt="" />
          <h4>Personal Details</h4>
        </div>
        <div>
          <div className="changeProfilePicture" style={{ marginBottom: "0" }}>
            <label
              htmlFor="fileInput"
              className="circle"
              style={{
                width: "150px",
                height: "150px",
                marginLeft: "-24px",
                marginTop: "-25px",
              }}
            >
              <img
                className="image"
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : user?.picture
                }
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "10px",
                }}
                alt="profile"
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {isLoading ? (
                <CircularProgress
                  className="camera-button"
                  style={{ bottom: "0px", right: "0px" }}
                  color="success"
                  size={20}
                />
              ) : (
                <CameraAltIcon
                  className="camera-button"
                  style={{ bottom: "0px", right: "0px" }}
                />
              )}
            </label>
          </div>
          <div className="inputField">
            <p>Your Name</p>
            <input
              className="inputField_input"
              type="text"
              placeholder="Enter Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="inputField ">
            <p>Address</p>
            <AddressField
              style={{ width: "100%" }}
              setNewAddress={setAddress}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              newAddress={address}
            />

            <div className="info">
              <HiOutlineInformationCircle
                style={{ height: "24px", width: "24px" }}
              />
              <p>This will be your primary location for Pick up</p>
            </div>
          </div>
          <div className="inputField">
            <p>Mobile Number</p>
            <div className=" inputField_input phoneCountry">
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GB"
                value={phoneNumber}
                limitMaxLength
                style={{ outline: "none" }}
                onChange={setPhoneNumber}
              />
            </div>
          </div>
          <div className="submitContent">
            {/* <NavLink to={"/clientExperience"}> */}
            <button
              className="button"
              onClick={handleSubmit}
              disabled={!isComplete}
            >
              Next
            </button>
            {/* </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
