import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { UserContext } from "./UseContext";

const Protected = (props) => {
  const { Component } = props;
  const { userId } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const storedUserId = localStorage.getItem("userId");

  useEffect(() => {
    if (!storedUserId) {
      navigate("/");
    }

    setLoading(false);
  }, [storedUserId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
