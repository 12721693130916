import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Active, Inactive, Waitlist, Passed } from "./StudentComponent";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StudentTabs({
  refresh,
  setRefresh,
  searchQuery,
  instId,
  tab,
}) {
  console.log("asdsadasdasd", tab);
  const [value, setValue] = React.useState(tab ? parseInt(tab, 10) : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="mylessontabs_notResponsive">
        <Box sx={{ width: "100%" }}>
          <Box className="mylessonsTabs" style={{ marginBottom: "1rem" }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              className="tabsMyLessons"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              // orientation={isMobile ? "vertical" : "horizontal"}
            >
              <Tab
                className={`clientNavLink ${
                  value === 0 ? "active" : ""
                } boldText`}
                label="Active"
                {...a11yProps(0)}
              />
              <Tab
                className={`clientNavLink ${
                  value === 1 ? "inactive" : ""
                } boldText`}
                label="Inactive "
                {...a11yProps(1)}
              />
              <Tab
                className={`clientNavLink ${
                  value === 2 ? "waitlist" : ""
                } boldText`}
                label="Waitlist"
                {...a11yProps(2)}
              />
              <Tab
                className={`clientNavLink ${
                  value === 3 ? "passed" : ""
                } boldText`}
                label="Pass/Fail"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0} sx={{ padding: "0" }}>
            <Active
              searchQuery={searchQuery}
              setRefresh={setRefresh}
              refresh={refresh}
              instId={instId}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Inactive
              searchQuery={searchQuery}
              setRefresh={setRefresh}
              refresh={refresh}
              instId={instId}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Waitlist
              searchQuery={searchQuery}
              setRefresh={setRefresh}
              refresh={refresh}
              instId={instId}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Passed
              searchQuery={searchQuery}
              setRefresh={setRefresh}
              refresh={refresh}
              instId={instId}
            />
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
}
