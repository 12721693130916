import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useState, useContext } from "react";
// import NavbarAdmin from "../../../layouts/AdminNavbar";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { UserContext } from "../../../layouts/UseContext";
import AddressField from "../../../layouts/AddressField";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddInstructors = ({ setReload }) => {
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [adi, setAdi] = useState("");
  const [pdi, setPdi] = useState("");
  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedRadio((prev) => (prev === value ? null : value));

    if (value === "adi") {
      setAdi("");
    } else if (value === "pdi") {
      setPdi("");
    }
  };
  const [gender, setGender] = useState("");

  const { userId } = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      drivingSchoolId: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      adiCode: adi,
      pdiCode: pdi,
      gender: gender,
      address: address,
      phoneNumber: phoneNumber,
      autoCharges: null,
      charges: null,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/createUnrigsterInstructor`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        if (response?.data?.success == false) {
          toast.error("Instructor already exists with this Email ID!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          handleClose();
          setReload(Math.random());
          toast.success("Instructor added!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  return (
    <div style={{ textAlign: "end" }}>
      <button onClick={handleOpen}>+ Add Instructors</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Add Instructor
          </Typography>

          <form onSubmit={handleSubmit}>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <p>
                  First Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="newLesson_row3_block2">
                <p>
                  Last Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <p>
                  Email Address<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="newLesson_row3_block2">
                <p>
                  {" "}
                  Contact Number<span style={{ color: "red" }}>*</span>
                </p>
                <div className=" inputField_input phoneField">
                  <PhoneInput
                    international
                    defaultCountry="GB"
                    value={phoneNumber}
                    style={{ outline: "none" }}
                    onChange={setPhoneNumber}
                    limitMaxLength
                  />
                </div>
              </div>
            </div>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <p>
                  Address<span style={{ color: "red" }}>*</span>
                </p>
                <AddressField
                  style={{ width: "100%" }}
                  setNewAddress={setAddress}
                  postalCode={postalCode}
                  setPostalCode={setPostalCode}
                  newAddress={address}
                />
              </div>
              <div className="newLesson_row3_block2">
                <p>
                  Gender<span style={{ color: "red" }}>*</span>
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: ".5rem 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <input
                      type="radio"
                      name="gender"
                      value="MALE"
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />{" "}
                    <span style={{ marginRight: "10px" }}>Male</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <input
                      type="radio"
                      name="gender"
                      value="FEMALE"
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />{" "}
                    <span style={{ marginRight: "10px" }}>Female</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <input
                      type="radio"
                      name="gender"
                      value="DO_NOT_WANT_TO_DISCLOSE"
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />{" "}
                    <span style={{ marginRight: "10px" }}>Other</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    ADI Code<span style={{ color: "red" }}>*</span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="adiPdi"
                      id="adi"
                      value="adi"
                      style={{ margin: 0 }}
                      checked={selectedRadio === "adi"}
                      onChange={() => handleRadioChange("adi")}
                    />
                    <p style={{ flexWrap: "nowrap" }}>N/A</p>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Enter ADI Code"
                  style={{
                    backgroundColor: selectedRadio === "adi" ? "#eee" : "white",
                  }}
                  onChange={(e) => setAdi(e.target.value)}
                  required={!pdi && selectedRadio !== "adi"}
                  disabled={selectedRadio === "adi"}
                  value={adi}
                />
              </div>
              <div className="newLesson_row3_block2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    PDI Code<span style={{ color: "red" }}>*</span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="pdi"
                      name="adiPdi"
                      value="pdi"
                      style={{ margin: 0 }}
                      checked={selectedRadio === "pdi"}
                      onChange={() => handleRadioChange("pdi")}
                    />
                    <p style={{ flexWrap: "nowrap" }}>N/A</p>
                  </div>
                </div>
                <input
                  type="text"
                  style={{
                    backgroundColor: selectedRadio === "pdi" ? "#eee" : "white",
                  }}
                  placeholder="Enter PDI Code"
                  onChange={(e) => setPdi(e.target.value)}
                  required={!adi && selectedRadio !== "pdi"}
                  disabled={selectedRadio === "pdi"}
                  value={pdi}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <button
                type="submit"
                style={{ color: "white", backgroundColor: "#2A335F" }}
                // onClick={handleSubmit}
              >
                Invite To Book Drive
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AddInstructors;
