import React, { useEffect, useState } from "react";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import { IoMdSend } from "react-icons/io";
import AddUserImage from "./../../../assets/img/Add-user.jpg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const AddUser = () => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [drivingList, setDrivingList] = useState([]);
  const drivingID = localStorage.getItem("userId");

  useEffect(() => {
    if (drivingID) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/users?drivingSchoolId=${drivingID}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setDrivingList(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [drivingID]);

  // const handleInputChange = (event) => {
  //   setInputValue(event.target.value);
  // };

  const handleSendInvite = (e) => {
    e.preventDefault();

    const updatedSelectedEmails = [...selectedEmails, inputValue];
    let data = JSON.stringify({
      drivingSchoolId: drivingID,
      emailIds: updatedSelectedEmails,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/inviteAdmin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setInputValue("");
        toast.success("Invite sent!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        setInputValue("");
        toast.error("Try again later", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  // const isValidEmail = (email) => {
  //   // Regular expression for validating email format
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  // const handleKeyDown = (event) => {
  //   if (
  //     (event.key === "Enter" || event.key === ";") &&
  //     inputValue.trim() !== ""
  //   ) {
  //     const enteredEmail = inputValue.trim();

  //     if (isValidEmail(enteredEmail)) {
  //       setSelectedEmails([...selectedEmails, enteredEmail]);
  //       setInputValue("");
  //     } else {
  //       toast.error("Invalid Email Format", {
  //         position: "bottom-center",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     }

  //     event.preventDefault();
  //   }
  // };

  // const handleRemove = (removedEmail) => {
  //   setSelectedEmails(
  //     selectedEmails?.filter((email) => email !== removedEmail)
  //   );
  // };

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />
        <div style={{ width: "100%", margin: "3rem" }}>
          <h1>Add Team</h1>

          <div className="adduser_row1">
            <div className="left">
              <h4>Email</h4>

              <form
                onSubmit={handleSendInvite}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <div className="email-input">
                  <input
                    type="email"
                    required
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    // onKeyDown={handleKeyDown}
                    placeholder="Type or paste email addresses..."
                  />
                </div>
                <button
                  type="submit"
                  style={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <p style={{ width: "max-content" }}>Send Invite</p>
                  <IoMdSend />
                </button>
              </form>
              {/* <div className="email-input"> */}
              {/* {selectedEmails.map((email, index) => (
                    <span key={index} className="token">
                      <p>{email}</p>
                      <span
                        className="remove-icon"
                        onClick={() => handleRemove(email)}
                      >
                        &times;
                      </span>
                    </span>
                  ))} */}

              {/* </div> */}

              {/* <p
                style={{
                  color: "grey",
                  fontSize: "small",
                }}
              >
                <i>To add multiple emails (Seperate with ";" or Press Enter)</i>
              </p> */}
              <div style={{ marginTop: "1rem" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {drivingList?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <div className="contact_info">{index + 1}</div>
                          </TableCell>
                          <TableCell>
                            <div className="contact_info">
                              <p> {row?.name}</p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="contact_info">
                              <p> {row?.email}</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="right">
              <img src={AddUserImage} alt="" />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddUser;
