const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const formatTime = (time) => {
  const hours = String(time.getHours()).padStart(2, "0");
  const minutes = String(time.getMinutes()).padStart(2, "0");
  const seconds = String(time.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

function calculateToTime(fromTime1, duration) {
  const fromTimeInMinutes =
    parseInt(fromTime1.split(":")[0]) * 60 + parseInt(fromTime1.split(":")[1]);

  const toTimeInMinutes = fromTimeInMinutes + parseInt(duration);

  const hours = Math.floor(toTimeInMinutes / 60);
  const minutes = toTimeInMinutes % 60;

  const toTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return toTime;
}

function getDayInCapitalLetters(dateString) {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getDate())) {
    console.error("Invalid date:", dateString);
    return null; // or handle the error in an appropriate way
  }

  const options = { weekday: "long" };
  const dayInLetters = new Intl.DateTimeFormat("en-US", options).format(date);

  return dayInLetters.toUpperCase();
}

module.exports = {
  formatDate,
  formatTime,
  calculateToTime,
  getDayInCapitalLetters,
};
