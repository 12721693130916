import Navbar from "../../../layouts/Navbar";
import { NavLink } from "react-router-dom";
import FailedCross from "./../../../assets/img/fail.gif";
import { useParams } from "react-router";

const Cancel = () => {
  const { id } = useParams();
  return (
    <div className="login_outer_container" style={{ backgroundColor: "white" }}>
      <div className="login_inner_container">
        <div
          style={{
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "black",
          }}
        >
          <img src={FailedCross} alt="" />
          <h1>Failed</h1>
          <h2>Failed to purchase Credits. Try Again! </h2>
          <NavLink to={`/instructorProfile/${id}`}>
            <button
              style={{
                width: "max-content",
                padding: ".5rem 1rem ",
                borderRadius: "10px",
                marginTop: "2rem",
                border: "1px solid gray",
              }}
            >
              OK
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
