import React, { useState, useContext, useEffect } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { UserContext } from "./UseContext";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Select, Space } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Help = ({ menuOpen, setMenuOpen }) => {
  const { userId } = useContext(UserContext);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [messageFail, setMessageFail] = useState(false);

  const sethelp = () => {
    if (!selectedTopic || !message.trim()) {
      toast.info("Complete all details", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setIsLoading(true);
    let data = JSON.stringify({
      studentId: userId,
      subject: selectedTopic,
      message: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/help/sendMail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        setMessage("");
        setMenuOpen(false);
        toast.success("Message Sent...", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error! Try again.", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/help/getHelpCategories`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setSubjectList(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setSubjectList]);

  const handleTopicChange = (event) => {
    setSelectedTopic(event);
  };

  return (
    <>
      <Modal
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
          setMessageFail(false);
          setMessageSuccess(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2" style={{ width: "400px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1>Help</h1>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMenuOpen(false);
                setMessageFail(false);
                setMessageSuccess(false);
              }}
            />
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div class="modal-body">
              <div>
                <h4>
                  Subject <span style={{ color: "red" }}>*</span>
                </h4>
                <Space style={{ width: "100%" }}>
                  <Select
                    defaultValue="Select"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleTopicChange}
                    options={subjectList.map((subject) => ({
                      value: subject,
                      label: subject,
                    }))}
                  />
                </Space>

                <h4 style={{ marginTop: "1rem" }}>
                  Message <span style={{ color: "red" }}>*</span>
                </h4>
                <textarea
                  style={{
                    borderRadius: "10px",
                    height: "10rem",
                    width: "100%",
                    // marginTop: ".5rem",
                    borderRadius: "10px",
                    padding: "10px",
                    boxSizing: "border-box",
                    border: "1px solid #8990AF",
                    outline: "none",
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your query....."
                />
              </div>
            </div>
            <div class="modal-footer" style={{ marginTop: "1rem" }}>
              <button
                type="button"
                style={{
                  padding: "10px",
                  backgroundColor: "#2A335F",
                  border: "none",
                  color: "white",
                  width: "100%",
                  borderRadius: "10px",
                }}
                // disabled={isSubmitDisabled}
                className="btn btn-primary"
                onClick={sethelp}
              >
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <CircularProgress
                      size={"15px"}
                      style={{ color: "white" }}
                    />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            <ToastContainer />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
