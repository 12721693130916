import React, { useState } from "react";
import Navbar from "../../../../layouts/Navbar";
import noProfilePicture from "../../../../assets/img/noProfileImage.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useEffect } from "react";
import { UserContext } from "../../../../layouts/UseContext";
import { useContext } from "react";
import axios from "axios";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PhoneInput from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddressField from "../../../../layouts/AddressField";
import { FaStripe } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const UserProfileEdit = () => {
  const { userId } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [profileColor, setProfileColor] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [newName, setNewName] = useState();
  const [newNumber, setNewNumber] = useState();
  const [newAddress, setNewAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [preferenceLesson, setPreferenceLesson] = useState();
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(
          "asdasdasdasd",
          response?.data?.data?.profile?.preferenceLesson
        );
        setUserData(response.data);
        setIsLoading(false);
        setProfileColor(response?.data?.data?.profile?.profileColor);
        setNewName(response?.data?.data?.profile?.firstName);
        setNewNumber(response.data?.data?.profile?.phnNumber);
        setNewAddress(response?.data?.data?.address[0]?.address);
        setPreferenceLesson(response?.data?.data?.profile?.preferenceLesson);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    const parsePreferenceLesson = () => {
      const matchResult = preferenceLesson?.match(
        /^((?:\w{3},?\s?)+)?(?: - (\d{2}:\d{2})(?: - (\d{2}:\d{2}))?)?$/
      );

      if (matchResult) {
        const [, selectedDaysStr, startTime, endTime] = matchResult;

        if (selectedDaysStr) {
          const daysArray = selectedDaysStr.split(/,\s?/);
          setSelectedDays(daysArray);
        }

        if (startTime) {
          setStartTime(startTime);
        }

        if (endTime) {
          setEndTime(endTime);
        }
      }
    };

    parsePreferenceLesson();
  }, [preferenceLesson]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const newTime = new Date();
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          canvas.toBlob((blob) => {
            setSelectedImage(new File([blob], `cropped_image${newTime}.png`));
          }, "image/png");
        };

        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const uploadImage = async () => {
      setIsLoading(true);
      const formData = new FormData();

      if (selectedImage) {
        formData.append("file", selectedImage);
      }

      formData.append("studentId", userId);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/student/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setIsLoading(false);
        setSelectedImage(null);
        window.location.reload();
        console.log(response.data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    if (selectedImage || userId) {
      uploadImage();
    }
  }, [selectedImage, userId]);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handleDayClick = (day) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleTimeChange = (e, type) => {
    const value = e.target.value;

    // Update start and end time based on the input type
    if (type === "start") {
      setStartTime(value);
    } else if (type === "end") {
      setEndTime(value);

      // Check if end time is before start time
      if (
        new Date(`2023-01-01 ${value}`) < new Date(`2023-01-01 ${startTime}`)
      ) {
        setError("End time cannot be before start time");
      } else {
        setError("");
      }
    }
  };

  const updateStudentDetails = () => {
    setIsLoading(true);
    const selectedDaysString = selectedDays.join(", ");
    const timeRangeString = `${startTime} - ${endTime}`;
    const dataString = `${selectedDaysString} - ${timeRangeString}`;
    console.log("asdasd", dataString);

    let data = JSON.stringify({
      id: userId,
      name: newName,
      mobileNumber: newNumber,
      address: newAddress,
      postalCode: postalCode,
      profileColor: profileColor,
      preferenceLesson: dataString,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/updateDetails`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        navigate("/userProfile");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "10rem",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <div className="clientdashboard_inner_container userProfile">
          <h1>My Profile</h1>
          <div className="changeProfilePicture">
            <label
              htmlFor="fileInput"
              className="circle"
              style={{ backgroundColor: profileColor }}
            >
              {console.log("----", userData?.data?.profile?.profilePicture)}
              <img
                className="image"
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : userData?.data?.profile?.profilePicture ??
                      noProfilePicture
                }
                alt="profile"
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {isLoading ? (
                <CircularProgress
                  className="camera-button"
                  style={{ bottom: "0px", right: "0px" }}
                  color="success"
                  size={20}
                />
              ) : (
                <CameraAltIcon
                  className="camera-button"
                  style={{ bottom: "0px", right: "0px" }}
                />
              )}
            </label>
            <div className="colorShades">
              <button
                style={{ backgroundColor: "#2A335E" }}
                onClick={() => setProfileColor("#2A335E")}
              ></button>
              <button
                style={{ backgroundColor: "#ECEEFF", padding: "0" }}
                onClick={() => setProfileColor("#ECEEFF")}
              ></button>
              <button
                style={{ backgroundColor: "#CFA616", padding: "0" }}
                onClick={() => setProfileColor("#CFA616")}
              ></button>
              <button
                style={{ backgroundColor: "#54ABEE", padding: "0" }}
                onClick={() => setProfileColor("#54ABEE")}
              ></button>
            </div>
          </div>

          <div className="userInputField">
            <p>
              <b>Name</b>
            </p>
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="userInputField ">
            <p>
              <b>Email</b>
            </p>
            <input
              disabled
              type="text"
              value={userData?.data?.profile?.email}
            />
          </div>
          <div className="userInputField">
            <p>
              <b>Phone Number</b>
            </p>
            <div className="phoneCountry">
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GB"
                value={newNumber}
                limitMaxLength
                onChange={setNewNumber}
              />
            </div>
          </div>
          <div className="userInputField">
            <p>
              <b>Address</b>
            </p>
            {/* <div className="googleAddress"> */}

            <AddressField
              address={newAddress}
              // style={{ width: "100%" }}
              setNewAddress={setNewAddress}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              newAddress={newAddress}
            />
          </div>
          <div className="userInputField">
            <p>
              <b>Payment Method</b>
            </p>
            <div className="paymentInfo">
              <p style={{ marginRight: ".5rem", color: "#8990AF" }}>
                {" "}
                Powered by
              </p>
              <FaStripe size={"3rem"} style={{ color: "#8990AF" }} />
            </div>
          </div>

          <div className="userInputField">
            <p>
              <b>Preference For Lessons</b>
            </p>

            <div className="days_lesson">
              {daysOfWeek.map((day) => (
                <button
                  key={day}
                  onClick={() => handleDayClick(day)}
                  style={{
                    color: selectedDays.includes(day) ? "white" : "#6FA5EF",
                    backgroundColor: selectedDays.includes(day)
                      ? "#6FA5EF"
                      : "white",
                  }}
                >
                  {day}
                </button>
              ))}
            </div>
            <div className="timePickers">
              <input
                type="time"
                value={startTime}
                onChange={(e) => handleTimeChange(e, "start")}
              />
              <input
                type="time"
                value={endTime}
                onChange={(e) => handleTimeChange(e, "end")}
              />
            </div>
            {error && (
              <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
            )}
          </div>

          <button
            onClick={() => updateStudentDetails()}
            style={{
              border: "none",
              borderRadius: "10px",
              backgroundColor: "#2A335F",
              color: "white",
              width: "max-content",
              padding: "0.5rem 1rem",
            }}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CircularProgress size={"15px"} style={{ color: "white" }} />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default UserProfileEdit;
