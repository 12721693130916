import React, { useEffect, useRef } from "react";

const AddressField = ({
  newAddress,
  setNewAddress,
  postalCode,
  setPostalCode,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    types: ["geocode"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();

      if (place && place.address_components) {
        let extractedAddress = "";
        let extractedPostalCode = "";

        for (const component of place.address_components) {
          if (component.types.includes("postal_code")) {
            extractedPostalCode = component.long_name;
          } else {
            extractedAddress += component.long_name + ", ";
          }
        }

        setNewAddress(extractedAddress);
        setPostalCode(extractedPostalCode);
      }
    });
  }, [setNewAddress, setPostalCode]);

  return (
    <div>
      <input
        ref={inputRef}
        placeholder={
          newAddress === "" ? "Enter Your Address or Postal Code" : newAddress
        }
        required
        className="inputField_input"
        style={{ border: "1px solid #8990AF" }}
      />
    </div>
  );
};

export default AddressField;
