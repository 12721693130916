import React, { useContext, useState } from "react";
import logo from "./../../../assets/img/BookDrive_logo-invert.jpg";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../layouts/UseContext";
import AddressField from "../../../layouts/AddressField";
import { useEffect } from "react";

const SignUp = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userSubRole");
  console.log(userRole);
  const { userId, setUser } = useContext(UserContext);
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [vat, setVat] = useState("");
  const [res, setRes] = useState("");
  const { user } = useAuth0();

  console.log(user?.app_metadata?.dsid);

  // console.log(userId, user);
  if (userRole === "SUPER_ADMIN") {
    console.log("yes");
  }

  useEffect(() => {
    console.log(userId);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?drivingSchoolId=${userId}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response?.data[0]?.stripeAccountId));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  const handleSubmit = () => {
    if (userRole === "ADMIN") {
      let data = JSON.stringify({
        profilePic: user?.picture,
        userAuthenticationid: userId,
        drivingSchoolid: user?.app_metadata?.dsid,
        name: name,
        contactNumber: phoneNumber,
        address: address,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/createAdmin`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          setRes(response);
          localStorage.setItem("drivingSchool", JSON.stringify(response.data));
          setUser(response?.data?.id);
          localStorage.setItem("userData2", JSON.stringify(response?.data));
          console.log("mein hu nayi id", response?.data?.id);
          navigate(`/admin/stripeConnect`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (userRole === "SUPER_ADMIN") {
      let data = JSON.stringify({
        profilePic: user?.picture,
        userAuthenticationid: userId,
        name: name,
        businessName: businessName,
        contactNumber: phoneNumber,
        address: address,
        vat: vat,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          setRes(response);
          localStorage.setItem("drivingSchool", JSON.stringify(response.data));
          setUser(response?.data?.id);
          localStorage.setItem("userData2", JSON.stringify(response?.data));
          console.log("mein hu nayi id", response?.data?.id);
          navigate(`/admin/stripeConnect`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="addBook_outer_container">
      <img className="bookdrive_Logo" src={logo} alt="" />
      <div className="addBook_inner_container">
        <h1>Sign Up</h1>
        <h4 style={{ marginTop: "1rem" }}>Let`s create an account</h4>
        {/* <form onSubmit={handleSubmit}> */}
        <div className="inputField">
          <div>
            <img src={user?.picture} />
          </div>
        </div>

        <div className="inputField">
          <p>Name</p>
          <div className="inputField_input">
            <input type="text" onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        {userRole === "SUPER_ADMIN" ? (
          <div className="inputField">
            <p>Business Name</p>
            <div className="inputField_input">
              <input
                type="text"
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="inputField">
          <p>Contact Number</p>
          <div
            style={{ padding: "0 10px", boxSizing: "border-box" }}
            className="inputField_input phoneCountryAB"
          >
            <PhoneInput
              international
              defaultCountry="GB"
              limitMaxLength
              value={phoneNumber}
              style={{ outline: "none" }}
              onChange={setPhoneNumber}
            />
          </div>
        </div>
        <div className="inputField">
          <p>Address</p>

          <div className="inputField_input">
            <AddressField
              style={{ width: "100%" }}
              setNewAddress={setAddress}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              newAddress={address}
            />
          </div>
        </div>
        {userRole === "SUPER_ADMIN" ? (
          <div className="inputField">
            <p>VAT</p>
            <div className="inputField_input">
              <input type="text" onChange={(e) => setVat(e.target.value)} />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="submitContent">
          {/* <NavLink to="/clientPersonalDetails"> */}
          <button onClick={handleSubmit}>Next</button>
          {/* </NavLink> */}
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default SignUp;
