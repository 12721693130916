import { useEffect } from "react";
import axios from "axios";

export const useFetchInstructorData = (instructorId, setInstructorData) => {
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/${instructorId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setInstructorData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instructorId, setInstructorData]);
};

export const useFetchAdminData = (adminId, setAdminData) => {
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?drivingSchoolId=${adminId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setAdminData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [adminId, setAdminData]);
};

export const useFetchInstructorList = (
  storedUserId,
  setInstructorList,
  storedUserRole
) => {
  useEffect(() => {
    let url;

    if (storedUserRole === "BOOK_DRIVE") {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=${storedUserId}`;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("asdasdasdasdasdasd", response?.data);
        setInstructorList(response?.data?.data?.instructor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storedUserId]);
};

export const useFetchClientList = (storedUserId, setClientList) => {
  useEffect(() => {
    if (storedUserId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student?pageNumber=0&pageSize=9999&key=active&drivingId=${storedUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setClientList(response?.data?.data?.student);
          // console.log(response?.data?.data?.student);
        })
        .catch((error) => {
          console.log(error);
          // setIsCancelling(false);
        });
    }
  }, [storedUserId]);
};

export const useFetchInstCalendar = (
  id,
  currentYear,
  currentMonth,
  setCalendarInstructorData,
  reload
) => {
  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/byMonth/instructor/${id}/${currentYear}/${currentMonth}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setCalendarInstructorData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, reload, currentYear, currentMonth]);
};

export const useFetchBookDriveCalendar = (
  id,
  currentYear,
  currentMonth,
  setCalendarInstructorData,
  reload
) => {
  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/allInstructorLessons/byMonth/${currentYear}/${currentMonth}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setCalendarInstructorData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, reload, currentYear, currentMonth]);
};

export const useFetchDSCalendar = (
  id,
  currentYear,
  currentMonth,
  setCalendarInstructorData,
  reload
) => {
  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/byMonth/drivingSchool/${id}/${currentYear}/${currentMonth}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setCalendarInstructorData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, reload, currentYear, currentMonth]);
};

export const useFetchInstBlocks = (Instid, setInstructorData) => {
  useEffect(() => {
    if (Instid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/dashboard/totalValue?instructorId=${Instid}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data.data, null, 2));
          setInstructorData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [Instid]);
};

export const useFetchInstructorUpcoming = (
  userId,
  instructorId,
  setStuInsUpcomingData,
  setStuInsCredit,
  reload
) => {
  useEffect(() => {
    if (userId && instructorId) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/lesson?status=UPCOMING,REQUESTED&studentId=${userId}&instructorId=${instructorId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response?.data?.instructors?.sort(
            (a, b) => a.lessonDate - b.lessonDate
          );
          setStuInsUpcomingData(sorted);
          setStuInsCredit(response?.data?.avilableCreditForStuWithIns);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, instructorId, setStuInsUpcomingData, reload]);
};

export const useFetchInstructorCompleted = (
  userId,
  instructorId,
  setStuInsCompletedData,
  reload
) => {
  useEffect(() => {
    if (userId) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/lesson?status=COMPLETED&studentId=${userId}&instructorId=${instructorId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response?.data?.instructors?.sort(
            (a, b) => b.lessonDate - a.lessonDate
          );
          setStuInsCompletedData(sorted);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, instructorId, setStuInsCompletedData, reload]);
};
export const useFetchStuDsCredits = (
  adminId,
  userId,
  reload,
  setStuInsCredit
) => {
  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/fetchDrivingSchoolCredit?studentId=${userId}&drivingSchoolId=${adminId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          setStuInsCredit(response?.data?.credits);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, adminId, reload]);
};

export const useFetchInstructorCancelled = (
  userId,
  instructorId,
  setStuInsCancelledData,
  reload
) => {
  useEffect(() => {
    if (userId) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/lesson?status=CANCELLED&studentId=${userId}&instructorId=${instructorId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response?.data?.instructors?.sort(
            (a, b) => b.updateAt - a.updateAt
          );
          setStuInsCancelledData(sorted);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, instructorId, setStuInsCancelledData, reload]);
};

export const useFetchStudentUpcoming = (
  userId,
  setUpcomingData,
  setIsLoading,
  reload
) => {
  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}?status=UPCOMING,REQUESTED`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response?.data?.instructors?.sort(
            (a, b) => a.lessonDate - b.lessonDate
          );

          setUpcomingData(sorted);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, setUpcomingData, setIsLoading, reload]);
};

export const useFetchStudentCompleted = (
  userId,
  setCompletedData,
  setIsLoading,
  reload
) => {
  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}?status=COMPLETED`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response?.data?.instructors?.sort(
            (a, b) => b.lessonDate - a.lessonDate
          );
          // console.log("lalala", sorted);
          setCompletedData(sorted);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, setCompletedData, setIsLoading, reload]);
};

export const useFetchStudentCancelled = (
  userId,
  setCancelledData,
  setIsLoading,
  reload
) => {
  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}?status=CANCELLED`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          let sorted = response?.data?.instructors?.sort(
            (a, b) => b.updateAt - a.updateAt
          );
          setCancelledData(sorted);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, setCancelledData, setIsLoading, reload]);
};

export const useFetchInstructorTimeSlot = (
  instructorId,
  instructorSlotData,
  setInstructorSlotData,
  dateSelected,
  reload
) => {
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/${instructorId}/${dateSelected}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const startTimes = response?.data?.availableTimeSlots?.map(
          (timeRange) => timeRange.split("-")[0]
        );
        setInstructorSlotData(startTimes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instructorId, setInstructorSlotData, dateSelected, reload]);
};
