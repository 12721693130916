import React, { useEffect, useState, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Space } from "antd";
import { NavLink } from "react-router-dom";
import AddInstructor from "./AddInstructor";
import AddCredits from "./AddCredits";
import DemoIns from "./../../../assets/img/demo_image.png";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import axios from "axios";
import noProfileImage from "../../../assets/img/noProfileImage.png";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";

export const DrivingSchoolInstructor = (params) => {
  const { DSid } = useParams();
  const storedUserRole = localStorage.getItem("userRole");
  const storedUserId = localStorage.getItem("userId");
  const [filteredList, setFilteredList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [reload, setReload] = useState(0);
  const [loading, isLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const getDropdownItems = (row) => {
    const suspendOption = {
      key: "1",
      label: (
        <div onClick={() => handleSuspendClick(row?.profile?.instructorId)}>
          Suspend
        </div>
      ),
    };

    const activeOption = {
      key: "2",
      label: (
        <div onClick={() => handleActivateClick(row?.profile?.instructorId)}>
          Activate
        </div>
      ),
    };

    const calendarOption = {
      key: "3",
      label: (
        <NavLink to={`/admin/instructorsProfile/${row?.profile?.instructorId}`}>
          Calendar
        </NavLink>
      ),
    };

    const addCredit = {
      key: "4",
      label: <AddCredits instructorId={row?.profile?.instructorId} />,
    };

    return row?.profile?.isActive === false
      ? [activeOption, calendarOption]
      : [suspendOption, calendarOption, addCredit];
  };

  useEffect(() => {
    isLoading(true);
    let url;

    url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=${DSid}`;

    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {},
    };
    axios
      .request({ ...config, url: url })
      .then((response) => {
        console.log(response?.data?.data?.instructor);
        setFilteredList(response?.data?.data?.instructor);
        setAllList(response?.data?.data?.instructor);
        isLoading(false);
      })
      .catch((error) => {
        console.log(error);
        isLoading(false);
      });
  }, [DSid, reload]);

  const handleSuspendClick = (instId) => {
    let data = JSON.stringify({
      id: instId,
      userRole: "INSTRUCTOR",
      active: false,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleActivateClick = (instId) => {
    let data = JSON.stringify({
      id: instId,
      userRole: "INSTRUCTOR",
      active: true,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let lowercaseValue = searchQuery?.toLowerCase();
    const filtered = allList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.profile?.lastName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.firstName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.adiCode?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.pdiCode?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, allList]);

  return (
    <div className="admin_outer_container">
      <NavbarAdmin />
      <div style={{ width: "100%", margin: "1rem 2rem" }}>
        <div className="adminInstructors_list_row1">
          <h1>Instructors</h1>
          <AddInstructor />
        </div>

        <div className="searchBar">
          <BsSearch style={{ color: "#6FA5EF" }} />
          <input
            type="text"
            placeholder="Search...."
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ outline: "none" }}
          />
        </div>

        {loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10rem",
            }}
          >
            <CircularProgress />
          </div>
        ) : allList?.length === 0 ? (
          <h2 style={{ textAlign: "center", marginTop: "10rem" }}>
            No connected instructors under this driving school.
          </h2>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="columnName2">
                    Name/License Number
                  </TableCell>
                  <TableCell className="columnName2">Contact</TableCell>
                  <TableCell className="columnName2">Badge Number</TableCell>
                  <TableCell className="columnName2">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredList?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <NavLink
                        to={`/admin/instructorsProfile/${row?.profile?.instructorId}`}
                      >
                        <div className="instructor_info_1">
                          <img
                            src={row?.profile?.profilePicture ?? noProfileImage}
                            alt="asds"
                          />
                          <div className="license_name">
                            <p>{`${row?.profile?.firstName} ${row?.profile?.lastName}`}</p>
                            <p> {row?.name}</p>
                          </div>
                        </div>
                      </NavLink>
                    </TableCell>
                    <TableCell>
                      <div className="contact_info">
                        <p> {row?.profile?.phoneNumber}</p>
                        <p> {row?.profile?.email}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="contact_info">
                        <p> {row?.profile?.adiCode}</p>
                        <p> {row?.profile?.pdiCode}</p>
                        <p> {row?.profile?.badgeExpiry}</p>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row?.profile?.isActive === true ? "Active" : "Suspend"}
                    </TableCell>
                    <TableCell align="right">
                      <Space direction="vertical">
                        <Space wrap>
                          <Dropdown
                            menu={{
                              items: getDropdownItems(row),
                            }}
                            placement="bottomRight"
                          >
                            <BsThreeDotsVertical
                              style={{ cursor: "pointer" }}
                            />
                          </Dropdown>
                        </Space>
                      </Space>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};
