import React, { useState, useContext } from "react";
import progress50 from "./../../../assets/img/50-2.png";
import { BiArrowBack } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../../layouts/UseContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Experience = () => {
  const { userId } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedTransmission, setSelectedTransmission] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [unfilled, setUnfilled] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { user } = useAuth0();

  React.useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.data);
        setSelectedSlot(response?.data?.data?.profile?.hoursDriven);
        setSelectedDate(response?.data?.data?.profile?.theoryPassedOn);
        setSelectedTransmission(
          response?.data?.data?.profile?.transmissionTypePreference
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  // console.log(user);

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const formattedDate = selectedDate.toISOString().split("T")[0];

    setSelectedDate(formattedDate);
    setIsCheckboxChecked(false);
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    setSelectedDate("");
  };

  const handleSubmit = () => {
    if (selectedTransmission === "") {
      setUnfilled(true);
    } else if (isCheckboxChecked === false && selectedDate === "") {
      setUnfilled(true);
    } else {
      let data = JSON.stringify({
        id: userId,
        hoursDriven: selectedSlot,
        theoryPassedOn: selectedDate,
        transmissionType: selectedTransmission,
        drivePassedOn: isCheckboxChecked,
        personalDetails: true,
        experience: true,
        address: "",
        preferenceForLesson: false,
        paymentMethod: false,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/signupDetails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          navigate(`/preferenceForLessons`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div className="signup_outer_container">
      <div className="signup_inner_container">
        <div className="heading">
          <img src={progress50} alt="" />
          <h4>Experience</h4>
        </div>
        <>
          <div className="block1">
            <p>
              <b>How many hours have you driven? (optional)</b>
            </p>
            <div className="button_group1">
              <button
                style={{
                  background: selectedSlot === "1-5" ? "#6FA5EF" : "white",
                }}
                onClick={() => setSelectedSlot("1-5")}
              >
                1-5
              </button>
              <button
                style={{
                  background: selectedSlot === "6-10" ? "#6FA5EF" : "white",
                }}
                onClick={() => setSelectedSlot("6-10")}
              >
                6-10
              </button>
              <button
                style={{
                  background: selectedSlot === "11-15" ? "#6FA5EF" : "white",
                }}
                onClick={() => setSelectedSlot("11-15")}
              >
                11-15
              </button>
              <button
                style={{
                  background: selectedSlot === "16-20" ? "#6FA5EF" : "white",
                }}
                onClick={() => setSelectedSlot("16-20")}
              >
                16-20
              </button>
              <button
                style={{
                  background: selectedSlot === "21-30" ? "#6FA5EF" : "white",
                }}
                onClick={() => setSelectedSlot("21-30")}
              >
                21-30
              </button>
              <button
                style={{
                  background: selectedSlot === "30+" ? "#6FA5EF" : "white",
                }}
                onClick={() => setSelectedSlot("30+")}
              >
                30+
              </button>
            </div>
          </div>
          <div className="inputField">
            <p>
              Theory Passed on: <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="date"
              className="inputField_input"
              value={selectedDate}
              onChange={handleDateChange}
              disabled={isCheckboxChecked}
              max={getCurrentDate()}
            />
          </div>
          <div className="tAndC">
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
              disabled={!!selectedDate}
            />
            <p>Haven’t passed</p>
          </div>
          <div className="block1">
            <p>
              <b>
                Transmittion Type <span style={{ color: "red" }}>*</span>
              </b>
            </p>
            <div className="button_group2">
              <button
                style={{
                  background:
                    selectedTransmission === "MANUAL" ? "#6FA5EF" : "white",
                  color: selectedTransmission === "MANUAL" ? "white" : "black",
                }}
                onClick={() => setSelectedTransmission("MANUAL")}
              >
                Manual
              </button>
              <button
                style={{
                  background:
                    selectedTransmission === "AUTOMATIC" ? "#6FA5EF" : "white",
                  color:
                    selectedTransmission === "AUTOMATIC" ? "white" : "black",
                }}
                onClick={() => setSelectedTransmission("AUTOMATIC")}
              >
                Automatic
              </button>
              {/* <button
                style={{
                  background:
                    selectedTransmission === "BOTH" ? "#6FA5EF" : "white",
                  color: selectedTransmission === "BOTH" ? "white" : "black",
                }}
                onClick={() => setSelectedTransmission("BOTH")}
              >
                Both
              </button> */}
            </div>
          </div>
          {unfilled ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Please Select the compulsory Details{" "}
            </p>
          ) : (
            ""
          )}
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <NavLink to={`/clientPersonalDetails`} style={{ width: "auto" }}>
              <BiArrowBack className="backArrow" />
            </NavLink>
            {/* <NavLink className="next_button" to="/preferenceForLessons"> */}

            <button
              style={{ margin: "0", padding: "1rem" }}
              onClick={handleSubmit}
            >
              Next
            </button>

            {/* </NavLink> */}
          </div>
        </>
      </div>
    </div>
  );
};

export default Experience;
