import React from "react";

const TermsAndCondition = () => {
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      <h2 className="boldText">Terms of Service</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "1rem 0",
          textAlign: "justify",
        }}
      >
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            1. Terms
          </h3>
          <p>
            By accessing the website at{" "}
            <a href="https://www.bookdrive.io">https://www.bookdrive.io</a>, you
            are agreeing to be bound by these terms of service, all applicable
            laws and regulations, and agree that you are responsible for
            compliance with any applicable local laws. If you do not agree with
            any of these terms, you are prohibited from using or accessing this
            site. The materials contained in this website are protected by
            applicable copyright and trademark law.
          </p>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            2. Use License
          </h3>
          <div
            style={{
              margin: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <p>
              1. Permission is granted to temporarily download one copy of the
              materials (information or software) on Bookdrive's website for
              personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license, you may not: Modify or copy the materials.
            </p>
            <p>
              2. Use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial).
            </p>
            <p>
              3. Attempt to decompile or reverse engineer any software contained
              on Bookdrive's website; remove any copyright or other proprietary
              notations from the materials.
            </p>
            <p>
              4. Transfer the materials to another person or "mirror" the
              materials on any other server.
            </p>
            <p>
              5. This license shall automatically terminate if you violate any
              of these restrictions and may be terminated by bookdrive at any
              time. Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </p>
          </div>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            3. Disclaimer
          </h3>
          <div
            style={{
              margin: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <p>
              1. The materials on Bookdrive website are provided on an 'as is'
              basis. Bookdrive makes no warranties, expressed or implied, and
              hereby disclaims and negates all other warranties including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </p>
            <p>
              2. Further, Bookdrive does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use
              of the materials on its website or otherwise relating to such
              materials or on any sites linked to this site.
            </p>
          </div>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            4. Limitations
          </h3>
          <p>
            In no event shall Bookdrive or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on Bookdrive website, even if
            Bookdrive or a Bookdrive authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            5. Accuracy of materials
          </h3>
          <p>
            The materials appearing on bookdrive's website could include
            technical, typographical, or photographic errors. Bookdriv does not
            warrant that any of the materials on its website are accurate,
            complete or current. bookdrive may make changes to the materials
            contained on its website at any time without notice. However
            bookdrive does not make any commitment to update the materials.
          </p>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            6. Links
          </h3>

          <p>
            Bookdrive has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by bookdrive of the
            site. Use of any such linked website is at the user's own risk.
          </p>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            7. Modification
          </h3>
          <p>
            Bookdrive may revise these terms of service for its website at any
            time without notice. By using this website you are agreeing to be
            bound by the then current version of these terms of service.
          </p>
        </div>
        <div>
          <h3 className="boldText" style={{ paddingBottom: "10px" }}>
            8. Governing
          </h3>
          <p>
            Law These terms and conditions are governed by and construed in
            accordance with the laws of Victoria and you irrevocably submit to
            the exclusive jurisdiction of the courts in that State or location.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
