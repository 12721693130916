import React from "react";
import progress25 from "./../../../assets/img/100-4.png";
import { NavLink } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FaApplePay, FaGooglePay } from "react-icons/fa";

const PersonalDetails = () => {
  return (
    <div className="signup_outer_container">
      <div className="signup_inner_container">
        <div className="heading">
          <img src={progress25} alt="" />
          <h4>Payment Method</h4>
        </div>
        <p style={{ textAlign: "justify", paddingTop: "1rem" }}>
          Bookdrive can confirm booking for £40. Our bookdrive instructors will
          be in touch to discuss the start date and schedule in the lessons. The
          remaining balance will be paid once your schedule is in place.
        </p>
        <form>
          <div className="inputField">
            <p>
              <b>Card Number</b>
            </p>
            <input
              type="text"
              className="inputField_input"
              placeholder="**** **** **** 0000"
            />
          </div>

          <div className="inputField">
            <p>
              <b>Exp.Date</b>
            </p>
            <input
              type="date"
              className="inputField_input"
              placeholder="MM/YYYY"
            />
          </div>
          <div className="inputField">
            <p>CVV</p>
            <input
              type="number"
              className="inputField_input"
              placeholder="***"
            />
          </div>
          <h4 style={{ padding: "1rem", textAlign: "center" }}>OR</h4>
          <div className="paywith">
            <button>
              <FaGooglePay size={50} />
            </button>
            <button>
              <FaApplePay size={50} />
            </button>
          </div>
          <div className="fpButtons" style={{ paddingTop: "1rem" }}>
            <NavLink to="/preferenceForLessons">
              <BiArrowBack className="backArrow" />
            </NavLink>
            <NavLink className="next_button" to="/myLessons">
              <button className="button" type="submit">
                Finish
              </button>
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetails;
