import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/Navbar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardContent, Modal, Typography } from "@mui/material";

const Benefits = () => {
  const [benefitList, setBenefitList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const openModal = (card) => {
    setSelectedId(card);
  };

  const closeModal = () => {
    setSelectedId(null);
  };

  const isPdf = (url) => url.toLowerCase().endsWith(".pdf");

  const renderImage = () => {
    const url = selectedId?.documentImageUrl;

    if (url) {
      if (isPdf(url)) {
        return (
          <iframe
            scrolling="no"
            src={url}
            title={Math.random()}
            className="benefits_iframe"
          />
        );
      } else {
        return (
          <img src={url} alt="Document" className="benefits_iframe_image" />
        );
      }
    }

    return null;
  };

  const renderImage2 = (benefit) => {
    const url = benefit;

    if (url) {
      if (isPdf(url)) {
        return (
          <iframe
            scrolling="no"
            src={url}
            title={Math.random()}
            className="benefits_iframe"
          />
        );
      } else {
        return (
          <img src={url} alt="Document" className="benefits_iframe_image2" />
        );
      }
    }

    return null;
  };

  const convertToHyperlinks = (text) => {
    const regex = /(https\:[^\s]+)/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const url = `${part}`;
        return (
          <a key={index} href={url} target="_blank">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/benefit`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setBenefitList(response.data.data.benefitList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const truncate = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  return (
    <>
      <Navbar />
      <div className="clientdashboard_outer_container">
        <div className="clientdashboard_inner_container">
          <div className="benefits_page">
            {isLoading ? (
              <CircularProgress />
            ) : (
              benefitList?.map((card, index) => (
                <Card
                  key={index}
                  className="benefit_block"
                  onClick={() => openModal(card)}
                >
                  <CardContent>
                    {/* <iframe
                      src={card?.documentImageUrl}
                      title={Math.random()}
                      style={{
                        width: "100%",
                        height: "150px",
                        overflowY: "hidden",
                      }}
                    /> */}

                    {renderImage2(card?.documentImageUrl)}
                    <Typography
                      variant="p"
                      component="p"
                      style={{ marginTop: "1rem" }}
                    >
                      {card?.header}
                    </Typography>

                    <Typography style={{ textAlign: "justify" }}>
                      <small> {truncate(card?.text, 100)}</small>
                    </Typography>

                    <p
                      onClick={() => openModal(card)}
                      style={{ cursor: "pointer", color: "#6FA5EF" }}
                    >
                      Learn More{" "}
                    </p>
                  </CardContent>
                </Card>
              ))
            )}

            <Modal
              open={Boolean(selectedId)}
              onClose={closeModal}
              aria-labelledby="card-modal-title"
              aria-describedby="card-modal-description"
            >
              <div className="card-modal benefit_modal">
                {renderImage()}
                <h2 id="card-modal-title" style={{ marginTop: "1rem" }}>
                  {selectedId?.header}
                </h2>
                <p id="card-modal-description" style={{ marginTop: ".5rem" }}>
                  {selectedId?.text
                    ? convertToHyperlinks(selectedId?.text)
                    : "No notes available"}
                </p>
                <button
                  style={{
                    marginTop: "1rem",
                    textAlign: "right",
                    backgroundColor: "#6FA5EF",
                    border: "none",
                    padding: ".5rem 2rem",
                    borderRadius: "5px",
                    float: "right",
                    width: "max-content",
                  }}
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
