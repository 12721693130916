import React, { useState, useEffect, useRef } from "react";
import NavbarAdmin from "../../layouts/AdminNavbar";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { FaStripe } from "react-icons/fa";
import CreateIcon from "@mui/icons-material/Create";
import PhoneInput from "react-phone-number-input";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddressField from "../../layouts/AddressField";
import { CircularProgress } from "@mui/material";

const AdminProfile = () => {
  const storedUserId = localStorage.getItem("userId");
  const storedUser = localStorage.getItem("userData2");
  const userSubRole = localStorage.getItem("userSubRole");
  const [drivingSchoolData, setDrivingSchoolData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [editedValues, setEditedValues] = useState({
    businessName: "",
    email: "",
    name: "",
    contactNumber: "",
    address: "",
    autoCharges: Array(4).fill(0),
    charges: Array(4).fill(0),
  });

  const storedUser2 = JSON.parse(storedUser);

  console.log(JSON.stringify(storedUser2, null, 2));

  const [reload, setReload] = useState(0);

  const handleSaveClick = () => {
    console.log("mamama", editedValues);
    const autoChargesString = editedValues.autoCharges.join(",");
    const chargesString = editedValues.charges.join(",");

    if (userSubRole === "ADMIN") {
      let data = JSON.stringify({
        userId: storedUser2?.userId,
        name: editedValues?.name,
        businessName: null,
        profilePic: null,
        contactNumber: editedValues?.contactNumber,
        address: editedValues?.address,
        vat: null,
        autoCharges: autoChargesString,
        charges: chargesString,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setIsEditing(false);
          setReload(Math.random());
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let data = JSON.stringify({
        id: editedValues?.id,
        name: null,
        businessName: editedValues?.businessName,
        profilePic: null,
        contactNumber: editedValues?.contactNumber,
        address: editedValues?.address,
        vat: null,
        autoCharges: autoChargesString,
        charges: chargesString,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setIsEditing(false);
          setReload(Math.random());
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const inputRef = useRef(null);

  const handleCancelClick = () => {
    setReload(Math.random());
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleTableCellEdit = (index, key) => (e) => {
    const { value } = e.target;

    // Regular expression to match a number with up to 2 decimal places
    const regex = /^\d*\.?\d{0,2}$/;

    // Check if the entered value matches the regex
    if (regex.test(value) || value === "") {
      setEditedValues((prevValues) => {
        const updatedArray = Array.isArray(prevValues[key])
          ? [...prevValues[key]]
          : Array(4).fill(0);
        updatedArray[index] = value;
        return { ...prevValues, [key]: updatedArray };
      });
    }
  };

  useEffect(() => {
    if (userSubRole === "ADMIN") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?userId=${storedUser2?.userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setDrivingSchoolData(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?drivingSchoolId=${storedUserId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setDrivingSchoolData(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [storedUserId, reload]);

  useEffect(() => {
    setEditedValues({ ...drivingSchoolData[0] });
  }, [drivingSchoolData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          canvas.toBlob((blob) => {
            setSelectedImage(new File([blob], "cropped_image.png"));
          }, "image/png");
        };

        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const uploadImage = async () => {
      setIsLoading(true);
      if (userSubRole === "ADMIN") {
        const formData = new FormData();

        if (selectedImage) {
          formData.append("file", selectedImage);
        }

        formData.append("userId", storedUser2?.userId);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/profileImage`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setIsLoading(false);
          console.log(response.data);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      } else {
        const formData = new FormData();

        if (selectedImage) {
          formData.append("file", selectedImage);
        }

        formData.append("id", storedUserId);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/profileImage`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setIsLoading(false);
          console.log(response.data);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    };

    if (selectedImage || storedUserId) {
      uploadImage();
    }
  }, [selectedImage, storedUserId]);

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />

        <div style={{ width: "100%", margin: "1rem 2rem" }}>
          <div className="clientdashboard_inner_container userProfile">
            <h1>My Profile</h1>

            <div className="changeProfilePicture">
              <label htmlFor="fileInput" className="circle">
                <img
                  className="image"
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : drivingSchoolData[0]?.profilePic
                  }
                  alt="profile"
                  style={{ height: "175px", width: "175px" }}
                />
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {isLoading ? (
                  <CircularProgress
                    className="camera-button"
                    style={{ bottom: "0px", right: "0px" }}
                    color="success"
                    size={20}
                  />
                ) : (
                  <CameraAltIcon
                    className="camera-button"
                    style={{ bottom: "0px", right: "0px" }}
                  />
                )}
              </label>
            </div>

            <div className="editProfile">
              {isEditing ? (
                <input
                  type="file"
                  accept="image/*"
                  // onChange={handleImageChange}
                  style={{ display: "none" }}
                  ref={inputRef}
                />
              ) : (
                <CreateIcon
                  style={{
                    float: "right",
                    backgroundColor: "#D9D9D9",
                    padding: ".5rem",
                    color: "black",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </div>

            <div className="userInputField">
              <p>
                <b>Name</b>
              </p>
              {userSubRole === "ADMIN" ? (
                <input
                  type="text"
                  disabled={!isEditing}
                  value={
                    isEditing ? editedValues.name : drivingSchoolData[0]?.name
                  }
                  onChange={handleInputChange}
                  name="name"
                />
              ) : (
                <input
                  type="text"
                  disabled={!isEditing}
                  value={
                    isEditing ? editedValues.name : drivingSchoolData[0]?.name
                  }
                  onChange={handleInputChange}
                  name="name"
                />
              )}
            </div>

            <div className="userInputField">
              <p>
                <b>Buisness Name</b>
              </p>
              {userSubRole === "ADMIN" ? (
                <input
                  type="text"
                  disabled
                  value={
                    isEditing
                      ? editedValues.businessName
                      : drivingSchoolData[0]?.businessName
                  }
                  onChange={handleInputChange}
                  name="businessName"
                />
              ) : (
                <input
                  type="text"
                  disabled={!isEditing}
                  value={
                    isEditing
                      ? editedValues.businessName
                      : drivingSchoolData[0]?.businessName
                  }
                  onChange={handleInputChange}
                  name="businessName"
                />
              )}
            </div>

            <div className="userInputField ">
              <p>
                <b>Email</b>
              </p>

              <input type="text" value={drivingSchoolData[0]?.email} disabled />
            </div>
            <div className="userInputField">
              <p>
                <b>Phone Number</b>
              </p>
              {isEditing ? (
                <div className="phoneCountry">
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="GB"
                    limitMaxLength
                    value={editedValues.contactNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "contactNumber", value },
                      })
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              ) : (
                <input
                  type="text"
                  value={drivingSchoolData[0]?.contactNumber}
                  disabled={!isEditing}
                  onChange={handleInputChange}
                  name="contactNumber"
                />
              )}
            </div>

            <div className="userInputField">
              <p>
                <b>VAT</b>
              </p>

              <input
                type="text"
                disabled
                value={
                  drivingSchoolData[0]?.vat
                    ? drivingSchoolData[0]?.vat
                    : "Not Applicable"
                }
                name="vat"
              />
            </div>
            <div className="userInputField">
              <p>
                <b>Address</b>
              </p>
              {/* <input
                type="text"
                value={drivingSchoolData[0]?.address ?? "Select an Address"}
                disabled
              /> */}

              {isEditing ? (
                <AddressField
                  address={editedValues.address}
                  setNewAddress={(value) =>
                    handleInputChange({ target: { name: "address", value } })
                  }
                  postalCode={editedValues.postalCode}
                  setPostalCode={(value) =>
                    handleInputChange({ target: { name: "postalCode", value } })
                  }
                  newAddress={editedValues.address}
                />
              ) : (
                <input
                  type="text"
                  value={drivingSchoolData[0]?.address}
                  disabled={!isEditing}
                  onChange={handleInputChange}
                  name="address"
                />
              )}
            </div>

            <div className="userInputField">
              <p>
                <b>Payment Method</b>
              </p>
              <div className="paymentInfo">
                <p style={{ marginRight: ".5rem", color: "#8990AF" }}>
                  Powered by
                </p>
                <FaStripe size={"3rem"} style={{ color: "#8990AF" }} />
              </div>
            </div>
            <div className="adminCredits">
              <table className="adminChargesTable_drivingSchoolProfile">
                <thead>
                  <tr>
                    <th>My Rate</th>
                    <th>Auto (£)</th>
                    <th>Manual (£)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>1 Hour</p>
                    </td>
                    <td>
                      {/* {console.log(editedValues.autoCharges[0])} */}
                      <input
                        type="number"
                        disabled={!isEditing}
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        value={
                          editedValues?.autoCharges &&
                          editedValues.autoCharges[0] !== undefined
                            ? editedValues.autoCharges[0]
                            : "0"
                        }
                        onChange={handleTableCellEdit(0, "autoCharges")}
                      />
                      {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[0]} */}
                    </td>
                    <td>
                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.charges &&
                          editedValues.charges[0] !== undefined
                            ? editedValues.charges[0]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(0, "charges")}
                      />
                      {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[0]} */}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>5 Hours</p>
                    </td>
                    <td>
                      {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[1]} */}

                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.autoCharges &&
                          editedValues.autoCharges[1] !== undefined
                            ? editedValues.autoCharges[1]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(1, "autoCharges")}
                      />
                    </td>
                    <td>
                      {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[1]} */}
                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.charges &&
                          editedValues.charges[1] !== undefined
                            ? editedValues.charges[1]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(1, "charges")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>10 Hours</p>
                    </td>
                    <td>
                      {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[2]} */}
                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.autoCharges &&
                          editedValues.autoCharges[2] !== undefined
                            ? editedValues.autoCharges[2]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(2, "autoCharges")}
                      />
                    </td>
                    <td>
                      {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[2]} */}
                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.charges &&
                          editedValues.charges[2] !== undefined
                            ? editedValues.charges[2]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(2, "charges")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>20 Hours</p>
                    </td>
                    <td>
                      {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[3]} */}
                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.autoCharges &&
                          editedValues.autoCharges[3] !== undefined
                            ? editedValues.autoCharges[3]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(3, "autoCharges")}
                      />
                    </td>
                    <td>
                      {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[3]} */}
                      <input
                        type="number"
                        disabled={!isEditing}
                        value={
                          editedValues?.charges &&
                          editedValues.charges[3] !== undefined
                            ? editedValues.charges[3]
                            : "0"
                        }
                        style={{
                          boxSizing: "border-box", // Ensure width includes padding and border
                          width: "100%",
                          border: "none",
                          borderBottom: isEditing
                            ? "2px solid #d9d9d9"
                            : "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        onChange={handleTableCellEdit(3, "charges")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {isEditing ? (
              <>
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                  }}
                >
                  <button onClick={handleSaveClick}>Save</button>
                  <button onClick={handleCancelClick}>Cancel</button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProfile;
