import React, { useContext } from "react";
// import FailedCross from "./../assets/img/fail.gif";
import bookdriveLogo from "./assets/img/BookDrive_logo-invert.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "./layouts/UseContext";

const NextPage = () => {
  const navigate = useNavigate();
  const { logoutUser } = useContext(UserContext);
  const { logout } = useAuth0();
  return (
    <div
      className="login_outer_container"
      style={{ backgroundColor: "#242a46" }}
    >
      <img className="bookdrive_Logo" src={bookdriveLogo} alt="" />
      <div className="login_inner_container">
        <div
          style={{
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
          }}
        >
          <h1 className="boldText">Sorry!</h1>
          <br />
          <h2>You are Logged in with different account.</h2>
          <br />
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <button
              onClick={() => {
                logoutUser();
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
            >
              Login New
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextPage;
