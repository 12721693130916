import React, { useContext, useEffect, useState } from "react";

import NavbarAdmin from "../../../layouts/AdminNavbar";
import axios from "axios";
import Box from "@mui/material/Box";
import "react-multi-carousel/lib/styles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { NavLink } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Client } from "./NotificationDetail";
import { Instructor } from "./NotificationDetail";
import { Payment } from "./NotificationDetail";
import { Booking } from "./NotificationDetail";
import { Cancellation } from "./NotificationDetail";
import { DrivingSchool } from "./NotificationDetail";

const Notification = () => {
  const storedUserId = localStorage.getItem("userId");
  const storedUserRole = localStorage.getItem("userRole");
  const [notification, setNotification] = useState([]);
  const [clearModal, setClearModal] = useState(false);

  const softDeleteNotification = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification/SoftDelete/clientID/${storedUserId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setNotification([]);
        setClearModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const notifications = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification/drivingSchool?createdForId=${storedUserId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
        setNotification(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    notifications();
  }, []);
  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />

        <div className="admin_Notification">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          >
            <h2>Notification</h2>
            <p
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => setClearModal(true)}
            >
              Clear All
            </p>
          </div>

          <div className="notificationAdmin">
            <div className="notification_block">
              <div
                style={{
                  backgroundColor: "#6FA5EF",
                  padding: "10px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                Clients
              </div>
              <small></small>
              {notification?.student
                ?.map((row) => ({ ...row, updatedAt: new Date(row.updatedAt) }))
                .sort((a, b) => b.updatedAt - a.updatedAt)
                .slice(0, 2)
                .map((row, index) => (
                  <div key={index} className="notificationData">
                    {row?.title}
                  </div>
                ))}
              <hr style={{ margin: "0.5rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    <Client notification={notification} />
                  </small>
                  <p className="boldText"></p>
                </div>
              </div>
            </div>
            <div className="notification_block">
              <div
                style={{
                  backgroundColor: "#6FA5EF",
                  padding: "10px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                Instructors
              </div>
              <small></small>
              {notification?.instructor
                ?.map((row) => ({ ...row, updatedAt: new Date(row.updatedAt) }))
                .sort((a, b) => b.updatedAt - a.updatedAt)
                .slice(0, 2)
                .map((row, index) => (
                  <div key={index} className="notificationData">
                    {row?.title}
                  </div>
                ))}
              <hr style={{ margin: "0.5rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    <Instructor notification={notification} />
                  </small>
                  <p className="boldText"></p>
                </div>
              </div>
            </div>
            <div className="notification_block">
              <div
                style={{
                  backgroundColor: "#6FA5EF",
                  padding: "10px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                Payments
              </div>
              <small></small>
              {notification?.payment
                ?.map((row) => ({ ...row, updatedAt: new Date(row.updatedAt) }))
                .sort((a, b) => b.updatedAt - a.updatedAt)
                .slice(0, 2)
                .map((row, index) => (
                  <div key={index} className="notificationData">
                    {row?.title}
                  </div>
                ))}
              <hr style={{ margin: "0.5rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    <Payment notification={notification} />
                  </small>
                  <p className="boldText"></p>
                </div>
              </div>
            </div>
            <div className="notification_block">
              <div
                style={{
                  backgroundColor: "#6FA5EF",
                  padding: "10px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                Bookings
              </div>
              <small></small>
              {notification?.booking
                ?.map((row) => ({ ...row, updatedAt: new Date(row.updatedAt) }))
                .sort((a, b) => b.updatedAt - a.updatedAt)
                .slice(0, 2)
                .map((row, index) => (
                  <div key={index} className="notificationData">
                    {row?.title}
                  </div>
                ))}
              <hr style={{ margin: "0.5rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    <Booking notification={notification} />
                  </small>
                  <p className="boldText"></p>
                </div>
              </div>
            </div>
            <div className="notification_block">
              <div
                style={{
                  backgroundColor: "#6FA5EF",
                  padding: "10px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                Cancellation
              </div>
              <small></small>
              {notification?.cancellation
                ?.map((row) => ({ ...row, updatedAt: new Date(row.updatedAt) }))
                .sort((a, b) => b.updatedAt - a.updatedAt)
                .slice(0, 2)
                .map((row, index) => (
                  <div key={index} className="notificationData">
                    {row?.title}
                  </div>
                ))}
              <hr style={{ margin: "0.5rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    <Cancellation notification={notification} />
                  </small>
                  <p className="boldText"></p>
                </div>
              </div>
            </div>
            {storedUserRole === "BOOK_DRIVE" ? (
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Driving School
                </div>
                <small></small>
                {notification?.drivingSchool
                  ?.map((row) => ({
                    ...row,
                    updatedAt: new Date(row.updatedAt),
                  }))
                  .sort((a, b) => b.updatedAt - a.updatedAt)
                  .slice(0, 2)
                  .map((row, index) => (
                    <div key={index} className="notificationData">
                      {row?.title}
                    </div>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
                <div className="row1">
                  <div className="row1_left">
                    <small
                      style={{
                        fontSize: "14px",
                        fontWeight: "600px",
                        fontFamily: "BookDriveFont",
                        lineHeight: "20px",
                      }}
                    >
                      <DrivingSchool notification={notification} />
                    </small>
                    <p className="boldText"></p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal
          open={clearModal}
          onClose={() => setClearModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal2">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              Are you sure?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div>
                <div className="notification_block">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <button
                      style={{ color: "black" }}
                      onClick={() => setClearModal(false)}
                    >
                      Exit
                    </button>

                    <button
                      style={{ color: "white", backgroundColor: "#2A335F" }}
                      onClick={() => softDeleteNotification()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Notification;
