import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import noProfileImage from "../../../assets/img/noProfileImage.png";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Space, Select } from "antd";
import DemoIns from "./../../../assets/img/demo_image.png";
import { UserContext } from "../../../layouts/UseContext";
import noProfilePicture from "../../../assets/img/noProfileImage.png";
import { CircularProgress } from "@mui/material";
import { Tooltip } from "antd";
import InfoIcon from "@mui/icons-material/Info";
import { ToastContainer, toast } from "react-toastify";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export const Active = ({ searchQuery, setRefresh, refresh, instId }) => {
  const storedUserId = localStorage.getItem("userId");
  const [studentList, setStudentList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteInst, setSelectedDeleteInst] = useState([]);

  const handleSuspendClick = (stdId) => {
    let data = JSON?.stringify({
      id: stdId,
      userRole: "STUDENT",
      active: false,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setRefresh(Math.random());
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteInst = (itemId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${itemId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setDeleteModal(false);
        setRefresh(Math.random());
        toast.success("Student Deleted!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error! Try Again Later!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleWaitlist = (stdId) => {
    let data = JSON.stringify({
      drivingSchoolId: storedUserId,
      id: stdId,
      userRole: "STUDENT",
      waiting: true,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/moveWaitList`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setRefresh(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDropdownItems = (row) => [
    {
      key: "1",
      label: (
        <NavLink to={`/admin/studentProfile/${row?.profile?.studentId}`}>
          Details
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleWaitlist(row?.profile?.studentId)}>
          Move to Waitlist
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={() => handleSuspendClick(row?.profile?.studentId)}>
          Suspend
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          style={{
            backgroundColor: "red",
            color: "white",
            width: "100%",
            padding: "0.5rem 0",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onClick={() => {
            setSelectedDeleteInst(row?.profile);
            setDeleteModal(true);
          }}
        >
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    console.log("=============", instId);
    if (instId !== null) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/mappedStudentByStatus?pageNumber=0&pageSize=50&searchString=&key=active&instructorId=${instId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStudentList(response?.data?.data?.student);
          setFilteredList(response?.data?.data?.student);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student?pageNumber=0&pageSize=9999&key=active&drivingId=${storedUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStudentList(response?.data?.data?.student);
          setFilteredList(response?.data?.data?.student);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [storedUserId, refresh, instId]);

  useEffect(() => {
    console.log("ierasasdas", searchQuery);
    let lowercaseValue = searchQuery?.toLowerCase() || "";

    const filtered = studentList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.profile.firstName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.phnNumber?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.email?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, studentList]);

  return isLoading === true ? (
    <div
      style={{
        textAlign: "center",
        marginTop: "10rem",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div style={{ overflowX: "auto" }}>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "20px", borderColor: "black" }}
      >
        <Table
          sx={{ minWidth: 100, overflowX: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="tableHeading" align="center">
                Name
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Contacts
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Transmission
              </TableCell>
              <TableCell className="tableHeading" align="center">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p>Credits</p>
                  <Tooltip title="Sum of credit across all instructors, Book Drive and Driving School (If any)">
                    <InfoIcon fontSize="15" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell
                className="tableHeading"
                align="center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p>Completed</p>
                  <Tooltip title="Completed lessons / Total lessons">
                    <InfoIcon fontSize="15" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Progress
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Registered
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList?.map((li, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <NavLink
                    to={`/admin/studentProfile/${li?.profile?.studentId}`}
                  >
                    <div className="instructor_info_1">
                      <img
                        style={{ borderRadius: "50%" }}
                        src={li?.profile?.profilePicture ?? noProfilePicture}
                        alt="Image"
                      />
                      <div className="license_name">
                        <p> {li?.profile?.firstName}</p>
                        <p> {li?.profile?.name}</p>
                      </div>
                    </div>
                  </NavLink>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    <p> {li?.profile?.phnNumber}</p>
                    <p>
                      {li?.profile?.email && li?.profile?.email.length > 20
                        ? `${li?.profile?.email.substring(0, 20)}...`
                        : li?.profile?.email}
                    </p>
                    {/* <p> {li?.profile?.email}</p> */}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="contact_info" align="center">
                    <p> {li?.profile?.transmissionTypePreference}</p>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="contact_info" align="center">
                    <p> {li?.profile?.creditAvilable}</p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">{li?.profile?.lessonPaid}</div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    {li?.profile?.drivingPerformance ?? "0.0"}/10
                  </div>
                </TableCell>
                <TableCell align="center">
                  {li?.profile?.register === true ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  <Space direction="vertical">
                    <Space wrap>
                      <Dropdown
                        menu={{
                          items: getDropdownItems(li),
                        }}
                        placement="bottomRight"
                      >
                        <BsThreeDotsVertical style={{ cursor: "pointer" }} />
                      </Dropdown>
                    </Space>
                  </Space>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Delete Student
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div
              style={{
                marginBottom: "1rem",
                textAlign: "center",
                margin: "0 auto",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* {JSON.stringify(selectedDeleteInst, null, 2)} */}
              {selectedDeleteInst && typeof selectedDeleteInst === "object" && (
                <>
                  <div
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      style={{
                        aspectRatio: 1,
                        width: "5rem",
                        borderRadius: "50%",
                      }}
                      src={selectedDeleteInst?.profilePicture ?? noProfileImage}
                    />
                    <div style={{ textAlign: "start" }}>
                      <p>
                        Name: {selectedDeleteInst?.firstName}{" "}
                        {selectedDeleteInst?.lastName}
                      </p>
                      <p>Email: {selectedDeleteInst?.email}</p>
                      <p>
                        Status:{" "}
                        {selectedDeleteInst?.isActive === false
                          ? "Suspend"
                          : "Active"}
                      </p>
                      <p>
                        Registered:{" "}
                        {selectedDeleteInst?.register === false ? "No" : "Yes"}
                      </p>
                    </div>
                  </div>
                  <h3 style={{ marginTop: "1rem" }}>
                    Are you sure you want to delete this Student?
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <button
                      style={{ color: "black" }}
                      onClick={() => setDeleteModal(false)}
                    >
                      Exit
                    </button>

                    <button
                      style={{ color: "white", backgroundColor: "#2A335F" }}
                      onClick={() =>
                        handleDeleteInst(selectedDeleteInst?.studentId)
                      }
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export const Inactive = ({ searchQuery, setRefresh, refresh, instId }) => {
  const [studentList, setStudentList] = useState([]);
  const storedUserId = localStorage.getItem("userId");

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteInst, setSelectedDeleteInst] = useState([]);

  const [filteredList, setFilteredList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteInst = (itemId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${itemId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setDeleteModal(false);
        setRefresh(Math.random());
        toast.success("Student Deleted!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error! Try Again Later!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const handleWaitlist = (stdId) => {
    let data = JSON.stringify({
      id: stdId,
      userRole: "STUDENT",
      active: true,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        let data = JSON.stringify({
          drivingSchoolId: storedUserId,
          id: stdId,
          userRole: "STUDENT",
          waiting: true,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_URL}/api/student/moveWaitList`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            setRefresh(Math.random());
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleActiveClick = (stdId) => {
    let data = JSON.stringify({
      id: stdId,
      userRole: "STUDENT",
      active: true,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setRefresh(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDropdownItems = (row) => [
    {
      key: "1",
      label: (
        <div onClick={() => handleWaitlist(row?.profile?.studentId)}>
          Move to Waitlist
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleActiveClick(row?.profile?.studentId)}>
          Move To Active
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          style={{
            backgroundColor: "red",
            color: "white",
            width: "100%",
            padding: "0.5rem 0",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onClick={() => {
            setSelectedDeleteInst(row?.profile);
            setDeleteModal(true);
          }}
        >
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log("ierasasdas", searchQuery);
    let lowercaseValue = searchQuery?.toLowerCase() || "";

    const filtered = studentList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.profile.firstName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.phnNumber?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.email?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, studentList]);

  useEffect(() => {
    setIsLoading(true);

    if (instId !== null) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/mappedStudentByStatus?pageNumber=0&pageSize=50&searchString=&key=inActive&instructorId=${instId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStudentList(response?.data?.data?.student);
          setFilteredList(response?.data?.data?.student);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student?pageNumber=0&pageSize=9999&key=inActive&drivingId=${storedUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStudentList(response?.data?.data?.student);
          setFilteredList(response?.data?.data?.student);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [storedUserId, refresh, instId]);

  return isLoading === true ? (
    <div
      style={{
        textAlign: "center",
        marginTop: "10rem",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div>
      <TableContainer component={Paper} sx={{ borderRadius: "20px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeading" align="center">
                Name
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Contacts
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Transmission
              </TableCell>
              <TableCell className="tableHeading" align="center">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p>Credits</p>
                  <Tooltip title="Sum of credit across all instructors, Book Drive and Driving School (If any)">
                    <InfoIcon fontSize="15" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell
                align="center"
                className="tableHeading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p>Completed</p>
                  <Tooltip title="Completed lessons / Total lessons">
                    <InfoIcon fontSize="15" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Progress
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Registered
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList.map((li, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <div className="instructor_info_1">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={li?.profile?.profilePicture ?? noProfilePicture}
                      alt="Image"
                    />
                    <div className="license_name">
                      <p> {li?.profile?.firstName}</p>
                      <p> {li?.profile?.name}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    <p> {li?.profile?.phnNumber}</p>
                    <p>
                      {" "}
                      {li?.profile?.email && li?.profile?.email.length > 20
                        ? `${li?.profile?.email.substring(0, 20)}...`
                        : li?.profile?.email}
                    </p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    <p> {li?.profile?.transmissionTypePreference}</p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    <p> {li?.profile?.creditAvilable}</p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">{li?.profile?.lessonPaid}</div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    {li?.profile?.drivingPerformance ?? "0.0"}/10
                  </div>
                </TableCell>
                <TableCell align="center">
                  {li?.profile?.register === true ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  <Space direction="vertical">
                    <Space wrap>
                      <Dropdown
                        menu={{
                          items: getDropdownItems(li),
                        }}
                        placement="bottomRight"
                      >
                        <BsThreeDotsVertical style={{ cursor: "pointer" }} />
                      </Dropdown>
                    </Space>
                  </Space>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Delete Student
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div
              style={{
                marginBottom: "1rem",
                textAlign: "center",
                margin: "0 auto",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* {JSON.stringify(selectedDeleteInst, null, 2)} */}
              {selectedDeleteInst && typeof selectedDeleteInst === "object" && (
                <>
                  <div
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      style={{
                        aspectRatio: 1,
                        width: "5rem",
                        borderRadius: "50%",
                      }}
                      src={selectedDeleteInst?.profilePicture ?? noProfileImage}
                    />
                    <div style={{ textAlign: "start" }}>
                      <p>
                        Name: {selectedDeleteInst?.firstName}{" "}
                        {selectedDeleteInst?.lastName}
                      </p>
                      <p>Email: {selectedDeleteInst?.email}</p>
                      <p>
                        Status:{" "}
                        {selectedDeleteInst?.isActive === false
                          ? "Suspend"
                          : "Active"}
                      </p>
                      <p>
                        Registered:{" "}
                        {selectedDeleteInst?.register === false ? "No" : "Yes"}
                      </p>
                    </div>
                  </div>
                  <h3 style={{ marginTop: "1rem" }}>
                    Are you sure you want to delete this Student?
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <button
                      style={{ color: "black" }}
                      onClick={() => setDeleteModal(false)}
                    >
                      Exit
                    </button>

                    <button
                      style={{ color: "white", backgroundColor: "#2A335F" }}
                      onClick={() =>
                        handleDeleteInst(selectedDeleteInst?.studentId)
                      }
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export const Waitlist = ({ searchQuery, setRefresh, refresh, instId }) => {
  const [studentList, setStudentList] = useState([]);
  const storedUserId = localStorage.getItem("userId");
  const [filteredList, setFilteredList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteInst, setSelectedDeleteInst] = useState([]);

  const handleDeleteInst = (itemId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${itemId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setDeleteModal(false);
        setRefresh(Math.random());
        toast.success("Student Deleted!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error! Try Again Later!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleActiveClick = (stdId) => {
    let data = JSON.stringify({
      drivingSchoolId: storedUserId,
      id: stdId,
      userRole: "STUDENT",
      waiting: false,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/moveWaitList`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setRefresh(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSuspendClick = (stdId) => {
    let data = JSON.stringify({
      id: stdId,
      userRole: "STUDENT",
      active: false,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setRefresh(Math.random());
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    if (instId !== null) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/mappedStudentByStatus?pageNumber=0&pageSize=50&searchString=&key=waiting&instructorId=${instId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStudentList(response?.data?.data?.student);
          setFilteredList(response?.data?.data?.student);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student?pageNumber=0&pageSize=9999&key=waiting&drivingId=${storedUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStudentList(response?.data?.data?.student);
          setFilteredList(response?.data?.data?.student);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [storedUserId, refresh, instId]);

  const getDropdownItems = (row) => [
    {
      key: "1",
      label: (
        <div onClick={() => handleActiveClick(row?.profile?.studentId)}>
          Move to Active
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleSuspendClick(row?.profile?.studentId)}>
          Suspend
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          style={{
            backgroundColor: "red",
            color: "white",
            width: "100%",
            padding: "0.5rem 0",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onClick={() => {
            setSelectedDeleteInst(row?.profile);
            setDeleteModal(true);
          }}
        >
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log("ierasasdas", searchQuery);
    let lowercaseValue = searchQuery?.toLowerCase() || "";

    const filtered = studentList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.profile.firstName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.phnNumber?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.email?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, studentList]);

  return isLoading === true ? (
    <div
      style={{
        textAlign: "center",
        marginTop: "10rem",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div>
      <TableContainer component={Paper} sx={{ borderRadius: "20px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeading" align="center">
                Name
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Contacts
              </TableCell>
              <TableCell className="tableHeading" align="center">
                Transmission
              </TableCell>
              <TableCell className="tableHeading" align="center">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p>Credits</p>
                  <Tooltip title="Sum of credit across all instructors, Book Drive and Driving School (If any)">
                    <InfoIcon fontSize="15" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell
                align="center"
                className="tableHeading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p>Completed</p>
                  <Tooltip title="Completed lessons / Total lessons">
                    <InfoIcon fontSize="15" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell align="center" className="tableHeading">
                Progress
              </TableCell>
              <TableCell align="center" className="tableHeading">
                Registered
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList.map((li, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <NavLink
                    to={`/admin/studentProfile/${li?.profile?.studentId}`}
                  >
                    <div className="instructor_info_1">
                      <img
                        style={{ borderRadius: "50%" }}
                        src={li?.profile?.profilePicture ?? noProfilePicture}
                        alt="Image"
                      />
                      <div className="license_name">
                        <p> {li?.profile?.firstName}</p>
                        <p> {li?.profile?.name}</p>
                      </div>
                    </div>
                  </NavLink>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    <p> {li?.profile?.phnNumber}</p>
                    <p>
                      {li?.profile?.email && li?.profile?.email.length > 20
                        ? `${li?.profile?.email.substring(0, 20)}...`
                        : li?.profile?.email}
                    </p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info" align="center">
                    <p> {li?.profile?.transmissionTypePreference}</p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info" align="center">
                    <p> {li?.profile?.creditAvilable}</p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">{li?.profile?.lessonPaid}</div>
                </TableCell>
                <TableCell align="center">
                  <div className="contact_info">
                    {li?.profile?.drivingPerformance ?? "0.0"}/10
                  </div>
                </TableCell>
                <TableCell align="center">
                  {li?.profile?.register === true ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  <Space direction="vertical">
                    <Space wrap>
                      <Dropdown
                        menu={{
                          items: getDropdownItems(li),
                        }}
                        placement="bottomRight"
                      >
                        <BsThreeDotsVertical style={{ cursor: "pointer" }} />
                      </Dropdown>
                    </Space>
                  </Space>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Delete Student
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div
              style={{
                marginBottom: "1rem",
                textAlign: "center",
                margin: "0 auto",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* {JSON.stringify(selectedDeleteInst, null, 2)} */}
              {selectedDeleteInst && typeof selectedDeleteInst === "object" && (
                <>
                  <div
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      style={{
                        aspectRatio: 1,
                        width: "5rem",
                        borderRadius: "50%",
                      }}
                      src={selectedDeleteInst?.profilePicture ?? noProfileImage}
                    />
                    <div style={{ textAlign: "start" }}>
                      <p>
                        Name: {selectedDeleteInst?.firstName}{" "}
                        {selectedDeleteInst?.lastName}
                      </p>
                      <p>Email: {selectedDeleteInst?.email}</p>
                      <p>
                        Status:{" "}
                        {selectedDeleteInst?.isActive === false
                          ? "Suspend"
                          : "Active"}
                      </p>
                      <p>
                        Registered:{" "}
                        {selectedDeleteInst?.register === false ? "No" : "Yes"}
                      </p>
                    </div>
                  </div>
                  <h3 style={{ marginTop: "1rem" }}>
                    Are you sure you want to delete this Student?
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <button
                      style={{ color: "black" }}
                      onClick={() => setDeleteModal(false)}
                    >
                      Exit
                    </button>

                    <button
                      style={{ color: "white", backgroundColor: "#2A335F" }}
                      onClick={() =>
                        handleDeleteInst(selectedDeleteInst?.studentId)
                      }
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export const Passed = ({ searchQuery, setRefresh, refresh, instId }) => {
  const [studentList, setStudentList] = useState([]);
  const storedUserId = localStorage.getItem("userId");
  const [filteredList, setFilteredList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (instId !== null) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/mappedStudentByStatus?pageNumber=0&pageSize=50&searchString=&key=result&instructorId=${instId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response?.data?.data);
          setStudentList(response?.data?.data?.lesson);
          setFilteredList(response?.data?.data?.lesson);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student?pageNumber=0&pageSize=9999&key=result&drivingId=${storedUserId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response?.data?.data?.lesson);
          setStudentList(response?.data?.data?.lesson);
          setFilteredList(response?.data?.data?.lesson);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [storedUserId, refresh, instId]);

  function capitalizeWords(inputString) {
    return inputString?.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  useEffect(() => {
    console.log("ierasasdas", searchQuery);
    let lowercaseValue = searchQuery?.toLowerCase() || "";

    const filtered = studentList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.clientName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.instructorName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.email?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, studentList]);
  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableHeading" align="center">
              Client Name
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Instructor Name
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Lesson Name
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Date
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Time
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Duration
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Result
            </TableCell>
            <TableCell className="tableHeading" align="center">
              Score
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredList?.map((row, index) => (
            <>
              <TableRow
                key={index}
                style={{
                  borderLeft:
                    row.studentResult === "PASS"
                      ? "10px solid green"
                      : row.studentResult === "FAIL"
                      ? "10px solid red"
                      : "10px solid grey",
                }}
              >
                <TableCell component="th" scope="row">
                  <p> {row?.clientName}</p>
                </TableCell>
                <TableCell align="center">
                  <p> {row?.instructorName}</p>
                </TableCell>
                <TableCell align="center">
                  <p> {capitalizeWords(row?.lessonName)}</p>
                </TableCell>
                <TableCell align="center">
                  <p> {row?.date}</p>
                </TableCell>
                <TableCell align="center">
                  <p> {row?.fromTime}</p>
                </TableCell>
                <TableCell align="center">
                  <p> {row?.durationOfClass} mins</p>
                </TableCell>
                <TableCell align="center">
                  <p> {row?.studentResult ?? "NA"}</p>
                </TableCell>
                <TableCell align="center">
                  <p> {row?.markScored ?? "0"}</p>
                </TableCell>
              </TableRow>
              <TableRow
                style={{
                  backgroundColor: "#e2eaf1",
                  borderLeft:
                    row.studentResult === "PASS"
                      ? "10px solid green"
                      : row.studentResult === "FAIL"
                      ? "10px solid red"
                      : "10px solid grey",
                }}
              >
                <TableCell
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  colSpan={9}
                >
                  <p>
                    <i>Notes: </i>
                    {row?.notes ?? "No notes available"}
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={9}></TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
