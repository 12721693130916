import React, { useContext, useEffect, useState } from "react";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProfileTabs from "./ProfileTabs";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useFetchStudentCancelled,
  useFetchStudentCompleted,
  useFetchStudentUpcoming,
} from "../../../layouts/effects";
import CircularProgress from "@mui/material/CircularProgress";
import { MdEdit } from "react-icons/md";
import AddressField from "../../../layouts/AddressField";
import PhoneInput from "react-phone-number-input";
import noProfilePicture from "../../../assets/img/noProfileImage.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

export const StudentProfile = () => {
  const [studentData, setstudentData] = useState("");
  const { studentid } = useParams();

  const { user } = useAuth0();
  const userId = studentData?.profile?.studentId;
  const [reload, setReload] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [upcomingData, setUpcomingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [cancelledData, setCancelledData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [editedData, setEditedData] = useState({
    email: studentData?.profile?.email,
    address:
      studentData?.address && studentData?.address[0]?.address !== undefined
        ? studentData?.address[0]?.address
        : "",
    mobileNumber: studentData?.profile?.phnNumber,
    transmissionType: studentData?.profile?.transmissionTypePreference,
  });

  useEffect(() => {
    if (user && userId) {
      let data = JSON.stringify({
        studentId: userId,
        instructorId: user?.instId_metadata ?? user?.app_metadata?.instId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/requestConnectedInstructor`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user, userId]);

  useFetchStudentUpcoming(userId, setUpcomingData, setIsLoading, reload);
  useFetchStudentCompleted(userId, setCompletedData, setIsLoading, reload);
  useFetchStudentCancelled(userId, setCancelledData, setIsLoading, reload);

  useEffect(() => {
    if (studentid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${studentid}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setstudentData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [studentid, reload]);

  const editClick = () => {
    setDisable(!disable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const data = {
      id: userId,
      ...editedData,
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/updateDetails`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDisable(!disable);
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const newTime = new Date();
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          canvas.toBlob((blob) => {
            setSelectedImage(new File([blob], `cropped_image${newTime}.png`));
          }, "image/png");
        };

        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const uploadImage = async () => {
      setIsLoading(true);
      const formData = new FormData();

      if (selectedImage) {
        formData.append("file", selectedImage);
      }

      formData.append("studentId", userId);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/student/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setIsLoading(false);
        setReload(Math.random());
        console.log(response.data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    if (selectedImage || userId) {
      uploadImage();
    }
  }, [selectedImage, userId]);

  console.log("))))))))))", editedData);
  return (
    <div>
      <div className="admin_outer_container">
        <NavbarAdmin />
        <div className="studentProfile">
          <h1>Client Profile</h1>

          <div className="leftSide">
            <div className="instructor_info">
              <div style={{ display: "flex" }}>
                <div className="changeProfilePicture">
                  <label htmlFor="fileInput" className="circle">
                    <img
                      className="image"
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : studentData?.profile?.profilePicture ??
                            noProfilePicture
                      }
                      alt="profile"
                      style={{ height: "175px", width: "175px" }}
                    />
                    <input
                      id="fileInput"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    {isLoading ? (
                      <CircularProgress
                        className="camera-button"
                        style={{ bottom: "0px", right: "0px" }}
                        color="success"
                        size={20}
                      />
                    ) : (
                      <CameraAltIcon
                        className="camera-button"
                        style={{ bottom: "0px", right: "0px" }}
                      />
                    )}
                  </label>
                </div>
                <div
                  className="instructor_content"
                  style={{ padding: "10px 40px", width: "100%" }}
                >
                  <h3 className="boldText">
                    {studentData?.profile?.firstName}{" "}
                    {studentData?.profile?.lastName}{" "}
                    <MdEdit color="#1976d2" onClick={editClick} />
                  </h3>

                  <div style={{ display: "flex", gap: "5rem" }}>
                    <div className="userInputFieldClient">
                      Email <br />
                      <input
                        value={studentData?.profile?.email}
                        name="email"
                        disabled
                        style={{ width: "100%" }}
                        // placeholder={studentData?.profile?.email}
                      />
                    </div>
                    <div className="userInputFieldClient">
                      Phone Number <br />
                      {disable == true ? (
                        <input
                          onChange={handleChange}
                          disabled={disable}
                          value={editedData?.phnNumber}
                          name="mobileNumber"
                          placeholder={studentData?.profile?.phnNumber}
                        />
                      ) : (
                        <PhoneInput
                          placeholder="Enter phone number"
                          defaultCountry="GB"
                          limitMaxLength
                          value={studentData?.profile?.phnNumber}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "mobileNumber", value },
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "5rem" }}>
                    <div className="userInputFieldClient">
                      Address <br />
                      {disable == true ? (
                        <input
                          onChange={handleChange}
                          disabled={disable}
                          value={editedData?.address}
                          name="address"
                          placeholder={
                            studentData?.address &&
                            studentData?.address[0]?.address !== undefined
                              ? studentData?.address[0]?.address
                              : ""
                          }
                        />
                      ) : (
                        <AddressField
                          address={
                            studentData?.address &&
                            studentData?.address[0]?.address !== undefined
                              ? studentData?.address[0]?.address
                              : ""
                          }
                          setNewAddress={(value) =>
                            handleChange({
                              target: { name: "address", value },
                            })
                          }
                          postalCode={editedData.postalCode}
                          setPostalCode={(value) =>
                            handleChange({
                              target: { name: "postalCode", value },
                            })
                          }
                          newAddress={
                            studentData?.address &&
                            studentData?.address[0]?.address !== undefined
                              ? studentData?.address[0]?.address
                              : ""
                          }
                        />
                      )}
                    </div>
                    {disable == true ? (
                      <div style={{ display: "flex" }}>
                        <div className="userInputFieldClient">
                          Transmission Type <br />
                          <input
                            onChange={handleChange}
                            disabled={disable}
                            value={editedData?.transmissionType}
                            name="transmissionTypePreference"
                            placeholder={
                              studentData?.profile?.transmissionTypePreference
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="inputField" style={{ marginTop: "1rem" }}>
                        Transmission Type
                        <div
                          style={{
                            display: "flex",
                            alignContent: "center",
                            gap: "1rem",
                            margin: ".5rem 0",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              gap: ".5rem",
                              marginTop: "1rem",
                            }}
                          >
                            <input
                              type="radio"
                              name="transmissionType"
                              value="MANUAL"
                              checked={
                                (editedData?.transmissionType === undefined &&
                                  studentData?.profile
                                    ?.transmissionTypePreference ===
                                    "MANUAL") ||
                                editedData?.transmissionType === "MANUAL"
                              }
                              onChange={handleChange}
                              required
                            />{" "}
                            <span style={{ marginRight: "10px" }}>Manual</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              gap: ".5rem",
                              marginTop: "1rem",
                            }}
                          >
                            <input
                              type="radio"
                              name="transmissionType"
                              value="AUTOMATIC"
                              checked={
                                (editedData?.transmissionType === undefined &&
                                  studentData?.profile
                                    ?.transmissionTypePreference ===
                                    "AUTOMATIC") ||
                                editedData?.transmissionType === "AUTOMATIC"
                              }
                              onChange={handleChange}
                              required
                            />{" "}
                            <span style={{ marginRight: "10px" }}>Auto</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {disable == true ? (
                    ""
                  ) : (
                    <div className="buttons">
                      <button
                        style={{
                          backgroundColor: "#242a46",
                          color: "white",
                          width: "10%",
                          float: "right",
                        }}
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div className="dashboard_tabs">
              <ProfileTabs
                upcoming={upcomingData}
                completed={completedData}
                cancelled={cancelledData}
                setReload={setReload}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
