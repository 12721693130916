import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import { FaSearchPlus } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Card, CardContent, Modal, Typography } from "@mui/material";
import { RiArrowGoBackFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const UploadBenefits = () => {
  const [benefitList, setBenefitList] = useState([]);
  const [title, setTitle] = useState("");
  const [endDate, setEndDate] = useState("");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectDeleteId, setSelectDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const openModal = (card) => {
    console.log("============", card);
    setSelectedId(card);
  };

  const closeModal = () => {
    setSelectedId(null);
  };

  const isPdf = (url) => url.toLowerCase().endsWith(".pdf");

  const renderImage = () => {
    const url = selectedId?.documentImageUrl;

    if (url) {
      if (isPdf(url)) {
        return (
          <iframe
            scrolling="no"
            src={url}
            title={Math.random()}
            className="benefits_iframe"
          />
        );
      } else {
        return (
          <img src={url} alt="Document" className="benefits_iframe_image" />
        );
      }
    }

    return null;
  };

  const convertToHyperlinks = (text) => {
    const regex = /(https\:[^\s]+)/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const url = `http://${part}`;
        return (
          <a key={index} href={url} target="_blank">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const deleteButton = (fileId) => {
    console.log("delete");
    setDeleteIsLoading(true);
    let data = JSON.stringify([fileId]);

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/benefit/delete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDeleteModal(false);
        setDeleteIsLoading(false);
        setSelectDeleteId("");
        setReload(Math.random());
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const allowedFormats = ["application/pdf", "image/png", "image/jpeg"];
      if (allowedFormats.includes(selectedFile.type)) {
        setFile(selectedFile);
      } else {
        setFile(null);
        alert("Please choose a file in PDF, PNG, or JPEG format.");
      }
    }
  };

  const handleUploadButton = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("heder", title);
    formData.append("offerEndDate", endDate);
    formData.append("file", file);
    formData.append("text", description);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/benefit/uploadBenefit`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFile(null);
      setEndDate("");
      setDescription("");
      setTitle("");
      setReload(Math.random());
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/benefit`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setBenefitList(response.data.data.benefitList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reload]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />
        <div style={{ width: "100%", margin: "3rem" }}>
          <h1>Upload Benefits</h1>

          <div className="newBenefits">
            <div className="newBenefits_row1">
              <div className="title" style={{ width: "100%" }}>
                <h4>Title</h4>
                <input
                  type="text"
                  placeholder="Add Title"
                  className="inputField"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="endDate" style={{ width: "100%" }}>
                <h4>End Date</h4>
                <input
                  type="date"
                  className="inputField"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <div className="uploadFile" style={{ width: "100%" }}>
                <h4>Add File</h4>
                <label htmlFor="fileInput" className="customFileInput">
                  {file ? file.name : "Choose File"}
                </label>
                <input
                  type="file"
                  id="fileInput"
                  className="inputField1"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="newBenefits_row2">
              <div className="description">
                <h4>Add Description</h4>
                <textarea
                  rows="5"
                  placeholder="Add Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="newBenefits_row3" style={{ textAlign: "right" }}>
              <button
                style={{
                  margin: "0",
                  padding: "1rem 3rem",
                  width: "10rem",
                }}
                onClick={handleUploadButton}
              >
                {isLoading ? (
                  <CircularProgress size={"15px"} style={{ color: "white" }} />
                ) : (
                  <p>Upload</p>
                )}
              </button>
            </div>
          </div>

          <div className="allbenefits">
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "20px", borderColor: "black" }}
            >
              <Table
                sx={{ minWidth: 100, overflowX: "scroll" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHeading">Title</TableCell>
                    <TableCell className="tableHeading">
                      Published Date
                    </TableCell>
                    <TableCell className="tableHeading">End Date</TableCell>
                    <TableCell className="tableHeading">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {benefitList?.map((li, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <p> {li?.header}</p>
                        </TableCell>
                        <TableCell>
                          <p> {formatDate(li?.createdAt)}</p>
                        </TableCell>
                        <TableCell>{formatDate(li?.offerEndDate)}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <FaSearchPlus
                              size={20}
                              style={{ cursor: "pointer" }}
                              onClick={() => openModal(li)}
                            />
                            <RxCross1
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // deleteButton(record?.id);
                                setDeleteModal(true);
                                setSelectDeleteId(li?.id);
                              }}
                              size={20}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        style={{
                          backgroundColor: "#e2eaf1",
                        }}
                      >
                        <TableCell
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          colSpan={9}
                        >
                          <p>
                            <i>Description: </i>
                            {li?.text
                              ? convertToHyperlinks(li?.text)
                              : "No notes available"}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={9}></TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <Modal
          open={Boolean(selectedId)}
          onClose={closeModal}
          aria-labelledby="card-modal-title"
          aria-describedby="card-modal-description"
        >
          <div className="card-modal benefit_modal">
            {/* <iframe
              scrolling="no"
              src={selectedId?.documentImageUrl}
              title={Math.random()}
              className="benefits_iframe"
            /> */}
            {renderImage()}
            <h2 id="card-modal-title">{selectedId?.header}</h2>
            <p id="card-modal-description" style={{ marginTop: ".5rem" }}>
              {selectedId?.text
                ? convertToHyperlinks(selectedId?.text)
                : "No notes available"}
            </p>
            <button
              style={{
                marginTop: "1rem",
                textAlign: "right",
                backgroundColor: "#6FA5EF",
                border: "none",
                padding: ".5rem 2rem",
                borderRadius: "5px",
                float: "right",
                width: "max-content",
              }}
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </Modal>

        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal2">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              Delete Benefit?
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                margin: "1rem 0",
                textAlign: "center",
              }}
            >
              <p>Are you sure to delete Benfit?</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "2rem",
                gap: "2rem",
              }}
            >
              <div
                className="calender_modal_button"
                onClick={() => setDeleteModal(false)}
                style={{ color: "#2A335F" }}
              >
                <RiArrowGoBackFill size={30} />
                <p>Exit</p>
              </div>

              <div
                className="calender_modal_button"
                onClick={() => deleteButton(selectDeleteId)}
                style={{ color: "red" }}
              >
                <MdDelete size={30} />
                {deleteIsLoading ? (
                  <CircularProgress size={"15px"} style={{ color: "white" }} />
                ) : (
                  <p>Delete</p>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default UploadBenefits;
