// UserProvider.js

import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Retrieve the userId from localStorage on component mount
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const setUser = (userId) => {
    setUserId(userId);
    // Store the userId in localStorage
    localStorage.setItem("userId", userId);
  };

  const logoutUser = () => {
    setUserId(null);
    // Clear the userId from localStorage
    localStorage.removeItem("userId");
  };

  return (
    <UserContext.Provider value={{ userId, setUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};
