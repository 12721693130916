import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../../../layouts/UseContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CiEdit } from "react-icons/ci";
import { FcCheckmark } from "react-icons/fc";

const AddCredits = ({ instructorId }) => {
  const [open, setOpen] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [allList, setAllList] = useState(studentList);
  const [reload, setReload1] = useState(false);
  const [edit, setEdit] = useState(true);
  const storedUserId = localStorage.getItem("userId");
  let strID = instructorId;

  console.log("55555", instructorId);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const studentData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/activeConnectedClients/instructor/${strID}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setStudentList(response?.data?.data);
        setAllList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  useEffect(() => {
    studentData();
  }, [reload, instructorId]);

  const handleSubmit = () => {
    setEdit(true);
    let payloadArr = [...allList];
    let defaultArr = [...studentList];
    let payData = [];

    for (let i = 0; i < payloadArr.length; i++) {
      for (let j = 0; j < defaultArr.length; j++) {
        if (payloadArr[i].id == defaultArr[j].id) {
          console.log(payloadArr[i].credits, defaultArr[j].credits);
          if (payloadArr[i].credits == defaultArr[j].credits) {
            let data = {
              studentId: defaultArr[j].id,
              creditToAdd: payloadArr[i].credits,
            };
            payData.push(data);
          }
        }
      }
    }

    let data = JSON.stringify({
      instructorId: strID,
      studentCreditPairList: payData,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructorStudent/addCredit`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        // handleClose();
        // window.location.reload();
        setReload1(Math.random());
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  const searchName = (e) => {
    const obj = studentList?.filter((li) =>
      li?.name.toLowerCase().includes(e.target.value)
    );
    setAllList(obj);
  };

  const editClick = () => {
    setEdit(false);
  };

  const handleChange = (e, li) => {
    let allListarr = [...allList];
    let value = e.target.value;
    for (let i = 0; i < allListarr.length; i++) {
      if (li?.id == allListarr[i].id) {
        allListarr[i].credits = value;
      }
    }
    setAllList(allListarr);
  };

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>Edit Credits</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Edit Credits
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ marginBottom: "1rem" }}>
              <div>
                <div className="inputField">
                  <div className="inputField_input">
                    <input type="text" onChange={searchName} />
                  </div>
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Credits</TableCell>
                    <TableCell>
                      {edit ? (
                        <CiEdit
                          style={{ cursor: "pointer", marginTop: "0.5rem" }}
                          size={20}
                          onClick={editClick}
                        />
                      ) : (
                        <FcCheckmark
                          style={{ cursor: "pointer", marginTop: "0.5rem" }}
                          size={20}
                          onClick={handleSubmit}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allList == [] || allList == undefined ? (
                    <div style={{ padding: "1rem" }}>
                      <b> No active client !! </b>
                    </div>
                  ) : (
                    allList?.map((li, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <div className="instructor_info_1">
                            <div className="license_name">
                              <p> {li?.name}</p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="contact_info">
                            <p> {li?.email}</p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="contact_info">
                            <input
                              disabled={edit}
                              className="inputCredit"
                              type="number"
                              value={li?.credits}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => handleChange(e, li)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCredits;
