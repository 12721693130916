import "react-multi-carousel/lib/styles.css";
import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import noprofilePicture from "../../../../assets/img/noProfileImage.png";
import Calendar from "../../../../layouts/Calender";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddressField from "../../../../layouts/AddressField";
import { UserContext } from "../../../../layouts/UseContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { RxCross2 } from "react-icons/rx";
import Tooltip from "@mui/material/Tooltip";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

const truncateDay = (string, n) => {
  return string?.length > n ? string.substr(0, n - 1) : string;
};

const truncate = (string, n) => {
  return string?.length > n ? string.substr(0, n - 1) + "..." : string;
};

const convertToTitleCase = (input) => {
  const words = input?.toLowerCase().split("_");
  const titleCaseWords = words?.map(
    (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
  );
  return titleCaseWords?.join(" ");
};

const formatDateToMonthDay = (dateString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [year, month, day] = dateString.split("-");
  const monthIndex = parseInt(month, 10) - 1;

  const formattedDate = `${months[monthIndex]} ${parseInt(day, 10)}`;
  return formattedDate;
};

export const InstructorLessonBookModal = ({
  openAvailability,
  setOpenAvailability,
  showDetailsHandle,
  dateSelected,
  setDateSelected,
  instructorData,
  instructorSlotData,
  preview,
  setPreview,
  instructorId,
  instrutorDuration,
  address,
  setAddress,
  setReload,
  studentData,
  toast,
}) => {
  // console.log("kakakakaka", dateSelected);
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);

  const [postalCode, setPostalCode] = React.useState("");
  const [selectedSlot, setSelectedSlot] = React.useState("");
  const [lessonType, setLessonType] = React.useState("");
  const [isComplete, setIsComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let daySelected;
  const date = new Date(dateSelected);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayIndex = date.getDay();
  daySelected = days[dayIndex];

  function addMinutesToTime(timeString, minutesToAdd) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;

    const totalMinutesWithAddition = totalMinutes + minutesToAdd;

    const newHours = Math.floor(totalMinutesWithAddition / 60);
    const newMinutes = totalMinutesWithAddition % 60;
    const formattedResult = `${String(newHours).padStart(2, "0")}:${String(
      newMinutes
    ).padStart(2, "0")}`;

    return formattedResult;
  }

  const startTime = selectedSlot;
  const minutesToAdd = instrutorDuration;
  const result = addMinutesToTime(startTime, minutesToAdd);

  const [hours, minutes] = selectedSlot.split(":").map(Number);
  const scheduleInMinutes = hours * 60 + minutes;
  const endTimeInMinutes = scheduleInMinutes + instrutorDuration;
  const totalDurationInMinutes = endTimeInMinutes - scheduleInMinutes;
  const creditCost = totalDurationInMinutes / 60;
  const bookingAmount = instructorData?.profile?.charges[0] * creditCost;

  React.useEffect(() => {
    if (
      selectedSlot?.trim() !== "" &&
      address?.trim() !== "" &&
      lessonType?.trim() !== ""
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [selectedSlot, address, lessonType]);

  const handleCloseButton = () => {
    setPreview(false);
    setOpenAvailability(false);
    setLessonType("");
    setSelectedSlot("");
  };

  const handleBookLesson = () => {
    setLoading(true);
    let data = JSON.stringify({
      studentId: userId,
      instructorId: instructorId,
      date: dateSelected,
      day: daySelected.toUpperCase(),
      timeSlab: `${selectedSlot}-${result}`,
      carType: carInfo,
      credits: creditCost,
      pickupAddress: address,
      postalCode: postalCode,
      lessonType: lessonType,
      amount: bookingAmount,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/bookLesson`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log();
        if (response?.data?.success === false) {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          setReload(Math.random());
          handleCloseButton();
        }
        setLoading(false);
        setReload(Math.random());
        handleCloseButton();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const studentTransmissionType =
    studentData?.profile?.transmissionTypePreference === "MANUAL"
      ? "MANUAL"
      : "AUTOMATIC" ?? "";

  let carInfo;

  if (instructorData?.vehicles && instructorData.vehicles.length > 0) {
    const matchingVehicle = instructorData.vehicles.find(
      (vehicle) => vehicle.transmissinType === studentTransmissionType
    );

    carInfo = matchingVehicle
      ? matchingVehicle.transmissinType
      : studentTransmissionType;
  } else {
    carInfo = "Instructor's Car";
  }

  // const carInfo =
  //   instructorData?.vehicles && instructorData.vehicles.length > 0
  //     ? (() => {
  //         const matchingVehicle = instructorData.vehicles.find(
  //           (vehicle) =>
  //             vehicle.transmissinType ===
  //             (studentTransmissionType === "MANUAL" ? "MANUAL" : "AUTOMATIC")
  //         );

  //         if (matchingVehicle) {
  //           const vehicleInfoString = `${matchingVehicle.transmissinType} | ${
  //             matchingVehicle.carModel ?? ""
  //           } ${matchingVehicle.vehicleMake ?? ""}, ${
  //             matchingVehicle.carYear ?? ""
  //           }`;

  //           return vehicleInfoString;
  //         } else {
  //           return "Instructor's Car";
  //         }
  //       })()
  //     : "Instructor's Car";

  // console.log("carinfo", carInfo);

  // console.log(studentTransmissionType);
  // console.log(instructorData);

  return (
    <Modal
      open={openAvailability}
      onClose={() => handleCloseButton()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal2">
        <Typography
          id="modal-modal-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 className="boldText">Availability</h3>
          <RxCross2
            style={{ cursor: "pointer" }}
            onClick={() => handleCloseButton()}
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 4 }}>
          <div
            className="instructor_information "
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                marginRight: "1rem",
                borderRadius: "50%",
              }}
              src={instructorData?.profile?.profilePicture ?? noprofilePicture}
              alt="instructor Profile Picture"
              srcset=""
            />

            <div className="instructor_info displayFlexColumn">
              <p>
                <b>{`${instructorData?.profile?.firstName} ${
                  instructorData?.profile?.lastName ?? ""
                } `}</b>
              </p>
              <small className="text-secondary">{carInfo}</small>
            </div>
          </div>

          {!preview ? (
            <>
              <div>
                <Calendar
                  showDetailsHandle={showDetailsHandle}
                  setDateSelected={setDateSelected}
                  dateSelected={dateSelected}
                />
                <br />
              </div>
              <div>
                <p className="boldText">
                  Select Start Time <span style={{ color: "red" }}>*</span>
                </p>
                <div className="availableTimeSlots">
                  {instructorSlotData === "" ||
                  instructorSlotData === null ||
                  instructorSlotData === undefined ||
                  instructorSlotData.length === 0 ? (
                    <button
                      disabled
                      style={{
                        color: "black",
                        backgroundColor: "#D9D9D9",
                        cursor: "no-drop",
                      }}
                    >
                      Instructor Not Available
                    </button>
                  ) : (
                    <Swiper
                      slidesPerView="5"
                      spaceBetween={10}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {instructorSlotData?.map((i) => (
                        <SwiperSlide style={{ textAlign: "center" }}>
                          <button
                            className="timeSlot"
                            style={{
                              padding: ".5rem",
                              backgroundColor:
                                selectedSlot === `${i}` ? "#6FA5EF" : "white",
                              width: "max-content",
                              color:
                                selectedSlot === `${i}` ? "white" : "white",
                            }}
                            onClick={(e) => setSelectedSlot(`${i}`)}
                          >
                            <p style={{ fontWeight: "500" }}>{i}</p>
                          </button>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              </div>
              <div
                className="inputField_input"
                style={{
                  margin: "15px 0",
                  height: "max-content",
                }}
              >
                <p className="boldText">
                  Pickup Address <span style={{ color: "red" }}>*</span>
                </p>
                <AddressField
                  style={{ width: "100%" }}
                  setNewAddress={setAddress}
                  postalCode={postalCode}
                  setPostalCode={setPostalCode}
                  newAddress={address}
                />
              </div>

              <div className="classType">
                <p className="boldText">
                  Lesson Type <span style={{ color: "red" }}>*</span>
                </p>
                <div className="options">
                  <div className="option">
                    <input
                      type="radio"
                      name="classtype"
                      value="LESSON"
                      checked={lessonType === "LESSON"}
                      onChange={(e) => setLessonType(e.target.value)}
                    />
                    <label>Lesson</label>
                  </div>
                  <div className="option">
                    <input
                      type="radio"
                      name="classtype"
                      value="MOCK_TEST"
                      checked={lessonType === "MOCK_TEST"}
                      onChange={(e) => setLessonType(e.target.value)}
                    />
                    <label>Mock Test</label>
                  </div>
                  <div className="option">
                    <input
                      type="radio"
                      name="classtype"
                      value="DRIVING_TEST"
                      checked={lessonType === "DRIVING_TEST"}
                      onChange={(e) => setLessonType(e.target.value)}
                    />
                    <label>Driving Test</label>
                  </div>
                </div>
              </div>
              <button
                // className="bookSlot"
                onClick={() => setPreview(true)}
                style={{
                  backgroundColor: !isComplete ? "#D9D9D9" : "#6FA5EF",
                  marginTop: "1rem",
                  cursor: !isComplete ? "no-drop" : "pointer",
                }}
                disabled={!isComplete}
              >
                {!isComplete ? "Select Details" : "Continue"}
              </button>
            </>
          ) : (
            <>
              <div className="timePreview" style={{ padding: "1rem" }}>
                <p className="boldText">Date & Time</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    margin: ".5rem 0",
                  }}
                >
                  <CalendarMonthIcon style={{ color: "#8990AF" }} />
                  <p className="boldText">
                    {truncateDay(daySelected, 4)},{" "}
                    {formatDateToMonthDay(dateSelected)}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    margin: ".5rem 0",
                  }}
                >
                  <AccessTimeIcon style={{ color: "#8990AF" }} />
                  <p className="boldText">{selectedSlot ?? "-"}</p>
                </div>
              </div>
              <div className="pickupPreview" style={{ padding: "0 1rem" }}>
                <p className="boldText">Pickup Address</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: ".5rem",
                  }}
                >
                  <LocationOnIcon style={{ color: "#8990AF" }} />
                  {address ? (
                    <Tooltip title={address} placement="bottom">
                      <p className="boldText"> {truncate(address, 30)}</p>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </div>
                <div style={{ padding: "1rem 0" }}>
                  <p className="boldText">Lesson Credits</p>
                  <p className="boldText">{Number(creditCost?.toFixed(2))}</p>
                </div>
              </div>
              <div
                style={{
                  // padding: "1rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <button
                  // className="bookSlot"
                  style={{
                    border: "1px solid #6FA5EF",
                    backgroundColor: "white",
                    width: "max-content",
                    marginTop: "0",
                    padding: "5px .5rem",
                  }}
                  onClick={() => setPreview(false)}
                >
                  <ArrowBackIcon style={{ color: "#6FA5EF" }} />
                </button>
                <button
                  className="boldText"
                  style={{
                    width: "100%",
                    backgroundColor: "#2A335F",
                    marginTop: "0",
                  }}
                  onClick={handleBookLesson}
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <CircularProgress
                        size={"15px"}
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : (
                    `
                  Book £${bookingAmount}
                  `
                  )}
                </button>
              </div>
            </>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};

export const PurchaseLessonModal = ({
  openPurchase,
  setOpenPurchase,
  instructorData,
  setCreditAmountSelected,
  creditAmountSelected,
  credits,
  setCredits,
  handleCredit,
  isBookLoading,
  studentData,
}) => {
  const studentTransmissionType =
    studentData?.profile?.transmissionTypePreference === "MANUAL"
      ? "MANUAL"
      : "AUTOMATIC";
  const chargesData =
    studentTransmissionType === "MANUAL"
      ? instructorData?.profile?.charges
      : instructorData?.profile?.autoCharges ?? 0;

  return (
    <Modal
      open={openPurchase}
      onClose={() => setOpenPurchase(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal2">
        <div className="modal-header">
          <h2>Purchase Lessons</h2>
        </div>
        <hr style={{ margin: "15px 0" }} />
        <div className="modal-body">
          <small style={{ paddingTop: "15px" }}>
            {" "}
            <i>*{convertToTitleCase(studentTransmissionType)} rate applied</i>
          </small>{" "}
          {chargesData && chargesData.length > 0 ? (
            <>
              <h1
                className="modal-title pricing-modal-title"
                style={{
                  fontSize: "50px",
                  textAlign: "center",
                  marginTop: ".5rem",
                }}
              >
                £{chargesData[0]}
                <small class="text-body-secondary fw-light">/h</small>
              </h1>
              <small>Quick Credit Purchase?</small>
              <div className="buttonsCredit">
                <button
                  className="btn creditButton"
                  onClick={() => {
                    setCreditAmountSelected(chargesData[0]);
                    setCredits(1);
                  }}
                >
                  1
                </button>
                <button
                  className="btn creditButton"
                  onClick={() => {
                    setCreditAmountSelected(chargesData[1]);
                    setCredits(5);
                  }}
                >
                  5
                </button>
                <button
                  className="btn creditButton"
                  onClick={() => {
                    setCreditAmountSelected(chargesData[2]);
                    setCredits(10);
                  }}
                >
                  10
                </button>
                <Tooltip title="Favourite Choice" placement="bottom">
                  <button
                    className="btn creditButton special"
                    onClick={() => {
                      setCreditAmountSelected(chargesData[3]);
                      setCredits(20);
                    }}
                  >
                    20
                  </button>
                </Tooltip>
                <button
                  className="btn creditButton "
                  onClick={() => {
                    setCreditAmountSelected(
                      creditAmountSelected + chargesData[0]
                    );
                    setCredits(credits + 1);
                  }}
                >
                  +1
                </button>
              </div>
              <div style={{ textAlign: "center" }}>
                <small>Credits: {credits}</small>
              </div>
              <div style={{ display: "flex", marginTop: "1rem", gap: "5px" }}>
                <button
                  className="creditButton2"
                  onClick={() => {
                    setCreditAmountSelected(0);
                    setCredits(0);
                  }}
                  style={{ color: "black", cursor: "pointer" }}
                >
                  Reset
                </button>
                {creditAmountSelected === 0 ? (
                  <button
                    className=" creditButton2"
                    style={{ backgroundColor: "#2A335F" }}
                  >
                    Select Credit
                  </button>
                ) : (
                  <button
                    className=" creditButton2"
                    style={{ backgroundColor: "#2A335F", cursor: "pointer" }}
                    onClick={() => handleCredit()}
                  >
                    {isBookLoading ? (
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <CircularProgress
                          size={"15px"}
                          style={{ color: "white" }}
                        />
                      </div>
                    ) : (
                      `Book : £${creditAmountSelected}`
                    )}
                  </button>
                )}
              </div>
            </>
          ) : (
            <p>Charges data is not available.</p>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export const PurchaseInstructorLessonModal = ({
  openPurchase,
  setOpenPurchase,
  adminData,
  setCreditAmountSelected,
  creditAmountSelected,
  credits,
  setCredits,
  handleCredit,
  isBookLoading,
  studentData,
}) => {
  console.log("pur chase ", adminData);
  const studentTransmissionType =
    studentData?.profile?.transmissionTypePreference === "MANUAL"
      ? "MANUAL"
      : "AUTOMATIC";
  const chargesData =
    studentTransmissionType === "MANUAL"
      ? adminData?.charges
      : adminData?.autoCharges ?? 0;

  return (
    <Modal
      open={openPurchase}
      onClose={() => setOpenPurchase(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal2">
        <div className="modal-header">
          <h2>Purchase Lessons</h2>
        </div>
        <hr style={{ margin: "15px 0" }} />
        <div className="modal-body">
          <small style={{ paddingTop: "15px" }}>
            {" "}
            <i>*{convertToTitleCase(studentTransmissionType)} rate applied</i>
          </small>{" "}
          {chargesData && chargesData.length > 0 ? (
            <>
              <h1
                className="modal-title pricing-modal-title"
                style={{
                  fontSize: "50px",
                  textAlign: "center",
                  marginTop: ".5rem",
                }}
              >
                £{chargesData[0]}
                <small class="text-body-secondary fw-light">/h</small>
              </h1>
              <small>Quick Credit Purchase?</small>
              <div className="buttonsCredit">
                <button
                  className="btn creditButton"
                  onClick={() => {
                    setCreditAmountSelected(chargesData[0]);
                    setCredits(1);
                  }}
                >
                  1
                </button>
                <button
                  className="btn creditButton"
                  onClick={() => {
                    setCreditAmountSelected(chargesData[1]);
                    setCredits(5);
                  }}
                >
                  5
                </button>
                <button
                  className="btn creditButton"
                  onClick={() => {
                    setCreditAmountSelected(chargesData[2]);
                    setCredits(10);
                  }}
                >
                  10
                </button>
                <Tooltip title="Favourite Choice" placement="bottom">
                  <button
                    className="btn creditButton special"
                    onClick={() => {
                      setCreditAmountSelected(chargesData[3]);
                      setCredits(20);
                    }}
                  >
                    20
                  </button>
                </Tooltip>
                <button
                  className="btn creditButton "
                  onClick={() => {
                    setCreditAmountSelected(
                      creditAmountSelected + chargesData[0]
                    );
                    setCredits(credits + 1);
                  }}
                >
                  +1
                </button>
              </div>
              <div style={{ textAlign: "center" }}>
                <small>Credits: {credits}</small>
              </div>
              <div style={{ display: "flex", marginTop: "1rem", gap: "5px" }}>
                <button
                  className="creditButton2"
                  onClick={() => {
                    setCreditAmountSelected(0);
                    setCredits(0);
                  }}
                  style={{ color: "black", cursor: "pointer" }}
                >
                  Reset
                </button>
                {creditAmountSelected === 0 ? (
                  <button
                    className=" creditButton2"
                    style={{ backgroundColor: "#2A335F" }}
                  >
                    Select Credit
                  </button>
                ) : (
                  <button
                    className=" creditButton2"
                    style={{ backgroundColor: "#2A335F", cursor: "pointer" }}
                    onClick={() => handleCredit()}
                  >
                    {isBookLoading ? (
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <CircularProgress
                          size={"15px"}
                          style={{ color: "white" }}
                        />
                      </div>
                    ) : (
                      `Book : £${creditAmountSelected}`
                    )}
                  </button>
                )}
              </div>
            </>
          ) : (
            <p>Charges data is not available.</p>
          )}
        </div>
      </Box>
    </Modal>
  );
};
