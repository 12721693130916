import React, { useEffect, useState } from "react";
import axios from "axios";
import noData from "../../../assets/img/noData.png";
import noCarDetail from "./../../../assets/img/audiQ7.jpg";
import { NavLink } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import noProfileImage from "../../../assets/img/noProfileImage.png";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { BsSearch } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { UserContext } from "../../../layouts/UseContext";
import { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SwiperSlide, Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { TbManualGearbox } from "react-icons/tb";
import { Navigation } from "swiper/modules";
import { message } from "antd";

import { addDays, format } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { MdCancel } from "react-icons/md";
import { RiArrowGoBackFill } from "react-icons/ri";

const truncate = (string, n) => {
  return string?.length > n ? string.substr(0, n - 1) + "..." : string;
};

const truncateDay = (string, n) => {
  return string?.length > n ? string.substr(0, n - 1) : string;
};

const isMoreThan72Hours = (cancelData, setHours72) => {
  const lessonDateTime = new Date(Number(cancelData?.lessonDate));

  const combinedDateTimeString = `${
    lessonDateTime.toISOString().split("T")[0]
  } ${cancelData?.fromTime}`;

  const selectedDateTime = new Date(combinedDateTimeString);
  const currentDateTime = new Date();

  const timeDifference = selectedDateTime - currentDateTime;

  const hoursDifference = timeDifference / (1000 * 60 * 60);

  return setHours72(hoursDifference > 72);
};

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    if (totalPages <= 2) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // Show current page and the next page
    return currentPage === totalPages
      ? [currentPage - 1, currentPage]
      : [currentPage, currentPage + 1];
  };

  return (
    <div className="pagination-container">
      <ul className="pagination">
        {currentPage > 1 && (
          <li>
            <button onClick={() => paginate(currentPage - 1)}>{"<"}</button>
          </li>
        )}

        {getPageNumbers().map((number) => (
          <li key={number}>
            <button onClick={() => paginate(number)}>{number}</button>
          </li>
        ))}

        {currentPage < totalPages && (
          <li>
            <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
          </li>
        )}
      </ul>
    </div>
  );
};

const monthAbbreviations = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

const convertToTitleCase = (input) => {
  const words = input?.toLowerCase().split("_");
  const titleCaseWords = words?.map(
    (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
  );
  return titleCaseWords?.join(" ");
};

export const NoDataComponent = (props) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <img src={noData} alt="" />
      {props.content ? (
        <p>{props.content}</p>
      ) : (
        <p>You don't have any {props.tabName} lessons</p>
      )}
    </div>
  );
};

// export const NoDataComponentInstructorProfile = (props) => {
//   return (
//     <div style={{ width: "100%", textAlign: "center" }}>
//       <img src={noData} alt="" />
//       <p>You have no {props.tabName} lessons. Book Now! </p>
//     </div>
//   );
// };

const ITEMS_PER_PAGE = 5;

export const UpcomingActivityBlock = ({ data, setReload }) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelData, setCancelData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cancelModal, setCancelModal] = useState(false);
  const [hour72, setHours72] = useState(false);
  const [addCharges, setAddCharges] = useState(false);

  const handleRadioChange = (event) => {
    setAddCharges(event.target.value === "true");
  };

  useEffect(() => {
    const filtered = data?.filter((classes) => {
      const lessonNameLower = classes?.lessonName?.toLowerCase();
      const lessonTypeLower = classes?.lessonType?.toLowerCase();
      const firstNameLower = classes?.firstName?.toLowerCase();
      const lastNameLower = classes?.lastName?.toLowerCase();
      const fullNameLower =
        `${classes?.firstName} ${classes?.lastName}`?.toLowerCase();
      // const searchLower = search?.toLowerCase();

      // return (
      //   search.trim() === "" ||
      //   lessonNameLower?.includes(searchLower) ||
      //   lessonTypeLower?.includes(searchLower) ||
      //   firstNameLower?.includes(searchLower) ||
      //   lastNameLower?.includes(searchLower) ||
      //   fullNameLower?.includes(searchLower)
      // );
    });
    // ?.slice(indexOfFirstItem, indexOfLastItem);

    // setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when the search changes
  }, [data]);

  const indexOfLastItemTotal = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemTotal = indexOfLastItemTotal - ITEMS_PER_PAGE;
  const currentDataTotal = data?.slice(
    indexOfFirstItemTotal,
    indexOfLastItemTotal
  );

  // Pagination logic for filtered data when a search is performed
  // const indexOfLastItemFiltered = currentPage * ITEMS_PER_PAGE;
  // const indexOfFirstItemFiltered = indexOfLastItemFiltered - ITEMS_PER_PAGE;
  // const currentDataFiltered = filteredData?.slice(
  //   indexOfFirstItemFiltered,
  //   indexOfLastItemFiltered
  // );

  // const currentData =
  //   search.trim() === "" ? currentDataTotal : currentDataFiltered;

  // const showPagination =
  //   search.trim() === ""
  //     ? data?.length > ITEMS_PER_PAGE
  //     : filteredData?.length > ITEMS_PER_PAGE;

  const showPagination = data?.length > ITEMS_PER_PAGE;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCancelLesson = (cancelData, addCharges) => {
    console.log("asdsadasd2", cancelData);
    if (!cancelData) {
      console.error("Cancellation data is missing.");
      return;
    }
    setIsCancelling(true);

    let data = JSON.stringify({
      lessonId: cancelData?.lessonId,
      userRole: "DRIVING_SCHOOL",
      lessonStatus: "CANCELLED",
      refund: addCharges,
      notes: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/cancelLesson`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsCancelling(false);
        setCancelModal(false);
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
        setIsCancelling(false);
      });
  };

  const handleCancelOpen = (cancelData) => {
    isMoreThan72Hours(cancelData, setHours72);
    console.log(hour72);
    setCancelModal(true);
    setCancelData(cancelData);
  };

  return (
    <div>
      {currentDataTotal?.map((classes, index) => (
        <div
          className="upcomingActivityBlock"
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "#781971"
            }
              `,
          }}
          key={index}
        >
          <tr style={{ alignItems: "center" }}>
            <td style={{ width: "15%" }}>
              <div className="lectureName_container displayFlexColumn">
                <Tooltip title={classes?.notes} placement="bottom">
                  <p className="boldText">
                    {convertToTitleCase(classes?.lessonName) ??
                      convertToTitleCase(classes?.lessonType)}
                  </p>
                </Tooltip>

                <small>
                  {new Date(classes?.lessonDate)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    .replace(
                      new RegExp(
                        Object.keys(monthAbbreviations).join("|"),
                        "gi"
                      ),
                      (matched) => monthAbbreviations[matched]
                    )}{" "}
                  | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                </small>
              </div>
            </td>
            <td
              style={{
                width: "15%",
                justifyContent: "center",
              }}
            >
              {classes?.status === "REQUESTED" ? (
                <small
                  style={{
                    fontSize: "10px",
                    color: "red",
                  }}
                >
                  <i>Approval Pending</i>
                </small>
              ) : (
                ""
              )}
            </td>
            <td style={{ width: "30%" }}>
              <div className="instructor_information">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    classes?.profilePicture === null ||
                    classes?.profilePicture === ""
                      ? noProfileImage
                      : classes?.profilePicture
                  }
                  alt=""
                  srcset=""
                />
                <div className="instructor_info displayFlexColumn">
                  <p className="boldText">
                    {`${classes?.firstName} ${
                      classes?.lastName === null ? "" : classes?.lastName
                    } `}
                  </p>
                  <small>{classes?.carType ?? ""}</small>
                </div>
              </div>
            </td>
            <td style={{ width: "20%" }}>
              <div className="lectureTiming displayFlexColumn">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20) ?? "No Address"}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td style={{ width: "10%" }}>
              <div className="lectureCost displayFlexColumn">
                <h4 className="boldText">
                  £ {Number(classes?.amount?.toFixed(2))}
                </h4>
                <small>
                  {classes?.lessonCredit
                    ? `Credit: ${classes?.lessonCredit.toFixed(2)}`
                    : ``}
                </small>
              </div>
            </td>
            <td style={{ width: "15%", justifyContent: "end" }}>
              <div className="cancelLecture">
                <button onClick={() => handleCancelOpen(classes)}>
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}

      <Modal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Cancel lesson
          </Typography>
          {hour72 === false ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <p>Do You Want to add charges for this class?</p>
                <div
                  style={{ display: "flex", gap: "3rem", alignItems: "center" }}
                >
                  <label>
                    <input
                      type="radio"
                      name="addCharges"
                      value="false"
                      checked={addCharges === false}
                      onChange={handleRadioChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="addCharges"
                      value="true"
                      checked={addCharges === true}
                      onChange={handleRadioChange}
                    />
                    No
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "2rem",
                }}
              >
                <div
                  className="calender_modal_button"
                  onClick={() => setCancelModal(false)}
                  style={{ color: "#2A335F" }}
                >
                  <RiArrowGoBackFill size={30} />
                  <p>Exit</p>
                </div>
                <div
                  className="calender_modal_button"
                  onClick={() => handleCancelLesson(cancelData, addCharges)}
                  style={{ color: "#ffc61a" }}
                >
                  <MdCancel size={30} />
                  <p>Cancel</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <p>Are you sure you want to cancel the lesson?</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "2rem",
                }}
              >
                <div
                  className="calender_modal_button"
                  onClick={() => setCancelModal(false)}
                  style={{ color: "#2A335F" }}
                >
                  <RiArrowGoBackFill size={30} />
                  <p>Exit</p>
                </div>
                <div
                  className="calender_modal_button"
                  onClick={() => handleCancelLesson(cancelData, true)}
                  style={{ color: "#ffc61a" }}
                >
                  <MdCancel size={30} />
                  <p>Cancel</p>
                </div>
              </div>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={isCancelling}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal2"
          style={{ backgroundColor: "transparent", textAlign: "center" }}
        >
          <CircularProgress size={50} color="primary" value={100} />
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export const CompletedActivityBlock = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    // Update the filtered data whenever the search term changes
    const filtered = data?.filter((classes) => {
      const lessonNameLower = classes?.lessonName?.toLowerCase();
      const lessonTypeLower = classes?.lessonType?.toLowerCase();
      const firstNameLower = classes?.firstName?.toLowerCase();
      const lastNameLower = classes?.lastName?.toLowerCase();
      const fullNameLower =
        `${classes?.firstName} ${classes?.lastName}`.toLowerCase();
      // const searchLower = search?.toLowerCase();

      // return (
      //   search.trim() === "" ||
      //   lessonNameLower?.includes(searchLower) ||
      //   lessonTypeLower?.includes(searchLower) ||
      //   firstNameLower?.includes(searchLower) ||
      //   lastNameLower?.includes(searchLower) ||
      //   fullNameLower?.includes(searchLower)
      // );
    });
    // ?.slice(indexOfFirstItem, indexOfLastItem);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when the search changes
  }, [data]);

  const indexOfLastItemTotal = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemTotal = indexOfLastItemTotal - ITEMS_PER_PAGE;
  const currentDataTotal = data?.slice(
    indexOfFirstItemTotal,
    indexOfLastItemTotal
  );

  // Pagination logic for filtered data when a search is performed
  // const indexOfLastItemFiltered = currentPage * ITEMS_PER_PAGE;
  // const indexOfFirstItemFiltered = indexOfLastItemFiltered - ITEMS_PER_PAGE;
  // const currentDataFiltered = filteredData?.slice(
  //   indexOfFirstItemFiltered,
  //   indexOfLastItemFiltered
  // );

  // const currentData =
  //   search.trim() === "" ? currentDataTotal : currentDataFiltered;

  // const showPagination =
  //   search.trim() === ""
  //     ? data?.length > ITEMS_PER_PAGE
  //     : filteredData?.length > ITEMS_PER_PAGE;
  const showPagination = data?.length > ITEMS_PER_PAGE;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {currentDataTotal?.map((classes, index) => (
        <div
          key={index}
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "#FC9F5D"
            }
          `,
          }}
          className="completedActivityBlock "
        >
          <tr>
            <td style={{ width: "30%" }}>
              <div className="lectureResult">
                {classes?.lessonRefund === false ? (
                  <TiTick
                    style={{
                      background: "green",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    size={30}
                  />
                ) : (
                  <ImCross
                    style={{
                      padding: "5px",
                      background: "red",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    size={20}
                  />
                )}
                <div className="lectureName_container displayFlexColumn ">
                  <p className="boldText">
                    {convertToTitleCase(classes?.lessonName) ??
                      convertToTitleCase(classes?.lessonType)}
                  </p>
                  <small>
                    {new Date(classes?.lessonDate)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                      })
                      .replace(
                        new RegExp(
                          Object.keys(monthAbbreviations).join("|"),
                          "gi"
                        ),
                        (matched) => monthAbbreviations[matched]
                      )}{" "}
                    | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                  </small>
                </div>
              </div>
            </td>

            <td style={{ width: "40%" }}>
              <div className="instructor_information">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    classes?.profilePicture === null ||
                    classes?.profilePicture === ""
                      ? noProfileImage
                      : classes?.profilePicture
                  }
                  alt=""
                  srcset=""
                />
                <div className="instructor_info displayFlexColumn">
                  <p className="boldText">
                    {`${classes?.firstName} ${classes?.lastName ?? ""}`}
                  </p>
                  <small>{classes?.carType}</small>
                </div>
              </div>
            </td>
            <td style={{ width: "20%" }}>
              <div className="lectureTiming displayFlexColumn  ">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20)}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">
                  {classes.markScored === null || classes.markScored === ""
                    ? "-"
                    : classes.markScored}
                </p>
                <small>Score</small>
              </div>
            </td>
          </tr>
        </div>
      ))}

      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export const CancelledActivityBlock = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    // Update the filtered data whenever the search term changes
    const filtered = data?.filter((classes) => {
      const lessonNameLower = classes?.lessonName?.toLowerCase();
      const lessonTypeLower = classes?.lessonType?.toLowerCase();
      const firstNameLower = classes?.firstName?.toLowerCase();
      const lastNameLower = classes?.lastName?.toLowerCase();
      const fullNameLower =
        `${classes?.firstName} ${classes?.lastName}`.toLowerCase();
      // const searchLower = search?.toLowerCase();

      // return (
      //   search.trim() === "" ||
      //   lessonNameLower?.includes(searchLower) ||
      //   lessonTypeLower?.includes(searchLower) ||
      //   firstNameLower?.includes(searchLower) ||
      //   lastNameLower?.includes(searchLower) ||
      //   fullNameLower?.includes(searchLower)
      // );
    });
    // ?.slice(indexOfFirstItem, indexOfLastItem);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when the search changes
  }, [data]);

  const indexOfLastItemTotal = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemTotal = indexOfLastItemTotal - ITEMS_PER_PAGE;
  const currentDataTotal = data?.slice(
    indexOfFirstItemTotal,
    indexOfLastItemTotal
  );

  // Pagination logic for filtered data when a search is performed
  // const indexOfLastItemFiltered = currentPage * ITEMS_PER_PAGE;
  // const indexOfFirstItemFiltered = indexOfLastItemFiltered - ITEMS_PER_PAGE;
  // const currentDataFiltered = filteredData?.slice(
  //   indexOfFirstItemFiltered,
  //   indexOfLastItemFiltered
  // );

  // const currentData =
  //   search.trim() === "" ? currentDataTotal : currentDataFiltered;

  // const showPagination =
  //   search.trim() === ""
  //     ? data?.length > ITEMS_PER_PAGE
  //     : filteredData?.length > ITEMS_PER_PAGE;
  const showPagination = data?.length > ITEMS_PER_PAGE;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {currentDataTotal.map((classes, index) => (
        <div
          className="cancelledActivityBlock"
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "red"
            }
      `,
          }}
          key={index}
        >
          <tr style={{ alignItems: "center" }}>
            <td style={{ width: "20%" }}>
              <div className="lectureName_container displayFlexColumn ">
                <p className="boldText">
                  {convertToTitleCase(classes?.lessonName) ??
                    convertToTitleCase(classes?.lessonType)}
                </p>
                <small>
                  {new Date(classes?.lessonDate)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    .replace(
                      new RegExp(
                        Object.keys(monthAbbreviations).join("|"),
                        "gi"
                      ),
                      (matched) => monthAbbreviations[matched]
                    )}{" "}
                  | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                </small>
              </div>
            </td>
            <td style={{ width: "30%" }}>
              <div className="instructor_information">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    classes?.profilePicture === null ||
                    classes?.profilePicture === ""
                      ? noProfileImage
                      : classes?.profilePicture
                  }
                  alt=""
                  srcset=""
                />
                <div className="instructor_info displayFlexColumn">
                  <p className="boldText">
                    {`${classes?.firstName} ${classes?.lastName ?? ""} `}
                  </p>
                  <small>{classes?.carType ?? ""}</small>
                </div>
              </div>
            </td>
            <td style={{ width: "30%" }}>
              <div className="lectureTiming displayFlexColumn  ">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 30)}
                  </Tooltip>
                </small>
              </div>
            </td>

            <td style={{ width: "10%" }}>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">£{classes?.amount?.toFixed(2)}</p>
                <small>
                  {classes?.lessonCredit
                    ? `Credit: ${classes?.lessonCredit?.toFixed(2)}`
                    : ``}
                </small>
              </div>
            </td>
            <td style={{ width: "20%", justifyContent: "end" }}>
              <div className="cancelLecture ">
                <button
                  disabled
                  style={{
                    backgroundColor:
                      classes.lessonRefund === true ? "#C1E1C1" : "#ff6961",
                    color: "black",
                    cursor: "no-drop",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  {classes.lessonRefund === true
                    ? "Credit Refunded"
                    : "Credit Charged"}
                </button>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};
