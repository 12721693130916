import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../../../layouts/Navbar";
import { UserContext } from "../../../../layouts/UseContext";
import {
  useFetchStudentCancelled,
  useFetchStudentCompleted,
  useFetchStudentUpcoming,
} from "../../../../layouts/effects";
import MyLessonsTabs from "./MyLessonsTabs";
import SearchBar from "../SearchBar";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const MyLessons = () => {
  const { user } = useAuth0();
  const { userId } = useContext(UserContext);
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [upcomingData, setUpcomingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [cancelledData, setCancelledData] = useState([]);

  useEffect(() => {
    if (!userId) {
      setIsLoading(true);
    } else {
      return;
    }
  }, [userId, user]);

  console.log("mein hu auth0 user ka data", user);

  useEffect(() => {
    if (user && userId) {
      let data = JSON.stringify({
        studentId: userId,
        instructorId: user?.instId_metadata ?? user?.app_metadata?.instId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/requestConnectedInstructor`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log("hogya connect");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user, userId]);

  useFetchStudentUpcoming(userId, setUpcomingData, setIsLoading, reload);
  useFetchStudentCompleted(userId, setCompletedData, setIsLoading, reload);
  useFetchStudentCancelled(userId, setCancelledData, setIsLoading, reload);

  return (
    <>
      <Navbar />
      <div className="clientdashboard_outer_container">
        <div className="clientdashboard_inner_container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "15rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
          ) : (
            <div>
              <SearchBar setSearchQuery={setSearchQuery} />

              <div className="dashboard_tabs">
                <MyLessonsTabs
                  searchQuery={searchQuery}
                  upcoming={upcomingData}
                  completed={completedData}
                  cancelled={cancelledData}
                  setReload={setReload}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyLessons;
