import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "././../../../layouts/UseContext";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Navbar from "../../../layouts/Navbar";

const Notifications = () => {
  const { userId } = useContext(UserContext);
  const calculateTime = (updatedAt) => {
    const currentDate = new Date();
    const backendDate = new Date(updatedAt);
    const currentTime = currentDate.getTime();
    const backendTime = backendDate.getTime();
    const timeDifference = currentTime - backendTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let formattedTimeDifference = "";

    if (days > 0) {
      formattedTimeDifference = `${days} days`;
    } else if (hours > 0) {
      formattedTimeDifference = `${hours} hours`;
    } else if (minutes > 0) {
      formattedTimeDifference = `${minutes} mins`;
    } else {
      formattedTimeDifference = `${seconds} secs`;
    }

    return formattedTimeDifference;
  };
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification?createdForId=${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        let sorted = response.data.sort((a, b) => a.updatedAt - b.updatedAt);
        setNotificationList(sorted);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  const [notificationList, setNotificationList] = useState([]);

  const softDeleteNotification = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/notification/SoftDelete/clientID/${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setNotificationList([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sortedNotifications = [...notificationList].sort((a, b) => {
    // Assuming createdAt is in ISO date string format, you can use Date.parse
    const dateA = Date.parse(a.createdAt);
    const dateB = Date.parse(b.createdAt);
    return dateB - dateA; // Sort in descending order
  });

  return (
    <>
      <Navbar />
      <div className="clientdashboard_outer_container">
        <div
          className="clientdashboard_inner_container"
          style={{ padding: "0" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          >
            <h2>Notification</h2>
            <p
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => softDeleteNotification()}
            >
              Clear All
            </p>
          </div>
          {sortedNotifications?.map((i, index) => (
            <MenuItem
              key={index}
              className="navbarNotification"
              style={{ width: "auto", whiteSpace: "normal" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  paddingRight: "10px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="notificationContent" style={{ width: "100%" }}>
                  <Accordion>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        display: "flex !important",
                        justifyContent: "space-between !important",
                        alignItems: "center !important",
                        gap: "20px !important",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {i?.title} <KeyboardArrowDownIcon />
                      </Typography>
                      <Typography
                        style={{ float: "right", textWrap: "nowrap" }}
                      >
                        {calculateTime(i?.createdAt)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        style={{ width: "100%", wordWrap: "break-word" }}
                      >
                        {i?.message}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </MenuItem>
          ))}
        </div>
      </div>
    </>
  );
};

export default Notifications;
