import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../../../layouts/UseContext";
import bookdriveLogo from "../../../assets/img/BookDrive_logo-invert.jpg";

const NotaStudent = () => {
  const { logout } = useAuth0();
  const { logoutUser } = useContext(UserContext);
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    logoutUser();
  };
  return (
    <div
      className="login_outer_container"
      style={{ backgroundColor: "#242a46" }}
    >
      <img className="bookdrive_Logo" src={bookdriveLogo} alt="" />
      <div className="login_inner_container">
        <div
          style={{
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
          }}
        >
          <h1>
            You are not a Student, Kindly Download our mobile app to continue as
            Instructor.
          </h1>
          <button onClick={handleLogout}>Ok</button>
        </div>
      </div>
    </div>
  );
};

export default NotaStudent;
