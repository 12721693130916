import React from "react";
import logo from "../../../assets/img/BookDrive_logo-invert.jpg";
import { useAuth0 } from "@auth0/auth0-react";

const EmailVerify = () => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="login_outer_container">
      <img className="bookdrive_Logo" src={logo} alt="" />
      <div
        className="login_inner_container"
        style={{ width: "60%", textAlign: "center" }}
      >
        <h1 className="emailVerifyContent">
          We noticed that you haven't verified your account yet. To access all
          the features of our service, please verify your account by clicking
          the link sent to your registered email.
        </h1>
        {/* <NavLink to={"/"}> */}
        <button className="goToDashboardButton" onClick={handleLogout}>
          Go back to Login
        </button>
        {/* </NavLink> */}
      </div>
    </div>
  );
};

export default EmailVerify;
