import React, { useState, useContext, useEffect } from "react";
import Navbar from "../../../../layouts/Navbar";
import noprofilePicture from "../../../../assets/img/noProfileImage.png";
import car from "../../../../assets/img/car1.png";
import { GiGearStickPattern } from "react-icons/gi";
import { UserContext } from "../../../../layouts/UseContext";
import { useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import {
  useFetchInstructorCancelled,
  useFetchInstructorCompleted,
  useFetchInstructorData,
  useFetchInstructorTimeSlot,
  useFetchInstructorUpcoming,
} from "../../../../layouts/effects";
import { format } from "date-fns";
import InstructorStudentClasses from "./InstructorStudentClasses";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import {
  InstructorLessonBookModal,
  PurchaseLessonModal,
} from "./InstructorLessonBookModal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SuccessTick from "./../../../../assets/img/success.gif";
import FailedCross from "./../../../../assets/img/fail.gif";
import { useNavigate } from "react-router-dom";
import { FaApplePay, FaGooglePay, FaStripe } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const convertToTitleCase = (input) => {
  const words = input?.toLowerCase().split("_");
  const titleCaseWords = words?.map(
    (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
  );
  return titleCaseWords?.join(" ");
};

const formatDate2 = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = dateString?.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]);
  const day = parseInt(dateParts[2]);

  const formattedDate = `${day} ${months[month - 1]} ${year}`;

  return formattedDate;
};

const InstructorProfile = () => {
  const [creditAmountSelected, setCreditAmountSelected] = useState(0);
  const [credits, setCredits] = useState(0);
  const { userId } = useContext(UserContext);
  const [stuInsUpcomingData, setStuInsUpcomingData] = useState([]);
  const [stuInsCompletedData, setStuInsCompletedData] = useState([]);
  const [stuInsCancelledData, setStuInsCancelledData] = useState([]);
  const [instructorData, setInstructorData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [instructorSlotData, setInstructorSlotData] = useState([]);
  const [stuInsCredit, setStuInsCredit] = useState(0);
  const { instructorId } = useParams();
  const [dateSelected, setDateSelected] = useState(formatDate(new Date()));
  const [preview, setPreview] = useState(false);
  const [isBookLoading, setIsBookLoading] = useState(false);
  const [reload, setReload] = useState(0);
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openPurchase, setOpenPurchase] = useState(false);
  const [openAvailability, setOpenAvailability] = useState(false);
  const location = useLocation();
  const queryParamsStatus = new URLSearchParams(location.search);
  const status = queryParamsStatus.get("status");

  const queryParamsSessionId = new URLSearchParams(location.search);
  const sessionId = queryParamsSessionId.get("session_id");

  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setStudentData(response?.data?.data);
          setAddress(response?.data?.data?.address[0]?.address);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (status === "success") {
      let data = JSON.stringify({
        stripeSessionId: sessionId,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/updateTransaction`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setOpenSuccess(true);
          setReload(Math.random());
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "failed") {
      setOpenFailure(true);
    } else {
      setOpenFailure(false);
      setOpenSuccess(false);
    }
  }, [status]);

  useFetchInstructorData(instructorId, setInstructorData);

  useFetchInstructorUpcoming(
    userId,
    instructorId,
    setStuInsUpcomingData,
    setStuInsCredit,
    reload
  );
  useFetchInstructorCompleted(
    userId,
    instructorId,
    setStuInsCompletedData,
    reload
  );
  useFetchInstructorCancelled(
    userId,
    instructorId,
    setStuInsCancelledData,
    reload
  );
  useFetchInstructorTimeSlot(
    instructorId,
    instructorSlotData,
    setInstructorSlotData,
    dateSelected,
    reload
  );

  let instructorDuration = instructorData?.profile?.standardDuration;

  const handleCredit = () => {
    setIsBookLoading(true);

    let data = JSON.stringify({
      instructorId: instructorId,
      studentId: userId,
      amount: creditAmountSelected,
      credits: credits,
      userRoleName: "INSTRUCTOR",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/initiateTransaction`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        let paymentUrl = response.data.urlToRedirect;
        setIsBookLoading(false);
        window.location.href = paymentUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };

  const isCreditBelowFive = stuInsCredit > 0 && stuInsCredit <= 5;
  const isCreditBelowZero = stuInsCredit <= 0;

  const handleOk = () => {
    setOpenFailure(false);
    setOpenSuccess(false);
    navigate(`/instructorProfile/${instructorId}`);
  };

  return (
    <div>
      <Navbar />
      <div className="clientdashboard_inner_container">
        <div className="instructor_profile">
          <div className="first_row">
            <div className="left_box1">
              <div className="left_box_row1">
                <div className="profile">
                  <img
                    src={
                      instructorData?.profile?.profilePicture ??
                      noprofilePicture
                    }
                    alt="Instructor Image"
                    style={{ marginRight: "1rem", borderRadius: "10px" }}
                  />
                  <div className="instructor_info">
                    <h2 className="boldText">
                      {`${instructorData?.profile?.firstName ?? ""} ${
                        instructorData?.profile?.lastName ?? ""
                      }`}
                    </h2>
                    <small className="boldText">
                      {instructorData?.profile?.pdiCode ??
                        instructorData?.profile?.adiCode}
                    </small>
                    <br />
                    <small className="boldText">
                      Exp.:{" "}
                      {instructorData?.profile?.badgeExpiry === undefined ||
                      instructorData?.profile?.badgeExpiry === null ||
                      instructorData?.profile?.badgeExpiry === ""
                        ? "-"
                        : formatDate2(instructorData?.profile?.badgeExpiry)}
                    </small>
                    <br />
                    <small className="boldText">
                      {instructorData?.profile?.address ?? ""}
                    </small>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    onClick={() => setOpenPurchase(true)}
                    style={{
                      backgroundColor: "#2A335F",
                      color: "white",
                      borderRadius: "10px",
                      padding: "1rem 1.5rem",
                      margin: "0",
                      fontSize: "15px",
                    }}
                    className="boldText"
                  >
                    Purchase Lessons
                  </button>
                  <small
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FaApplePay size={40} />
                    <FaGooglePay size={40} />
                    <FaStripe size={40} />
                  </small>
                </div>
                <PurchaseLessonModal
                  openPurchase={openPurchase}
                  setOpenPurchase={setOpenPurchase}
                  instructorData={instructorData}
                  setCreditAmountSelected={setCreditAmountSelected}
                  creditAmountSelected={creditAmountSelected}
                  setCredits={setCredits}
                  credits={credits}
                  handleCredit={handleCredit}
                  isBookLoading={isBookLoading}
                  studentData={studentData}
                />
              </div>
              <hr
                style={{
                  backgroundColor: "#D9D9D9",
                }}
              />
              <div className="left_box_row2">
                <div className="instructor_info">
                  <small>ADI code</small>
                  <p className="boldText">
                    {instructorData?.profile?.adiCode ?? "-"}
                  </p>
                </div>
                <div className="instructor_info">
                  <small>CPD code</small>
                  <p className="boldText">-</p>
                </div>
                <div className="instructor_info">
                  <small>Driving School</small>
                  <p className="boldText">-</p>
                </div>
                <div className="instructor_info">
                  <small>Charged</small>
                  <p className="boldText">-</p>
                </div>
              </div>
            </div>
            <div className="right_box1">
              <h2>Available Lessons</h2>
              <h1
                style={{
                  color: isCreditBelowZero
                    ? "red"
                    : isCreditBelowFive
                    ? "#ffbf00"
                    : "white",
                }}
              >
                {stuInsCredit === null ? "0" : Number(stuInsCredit?.toFixed(2))}
              </h1>
              {stuInsCredit === null ||
              stuInsCredit === 0 ||
              stuInsCredit < 0 ? (
                <button
                  className="neomorphic-btn "
                  style={{
                    margin: "0",
                    borderRadius: "5px",
                    padding: ".5rem 1rem",
                    fontSize: "15px",
                  }}
                  onClick={() => setOpenPurchase(true)}
                >
                  Purchase Lessons
                </button>
              ) : (
                <button
                  className="neomorphic-btn "
                  style={{
                    margin: "0",
                    borderRadius: "5px",
                    padding: ".5rem 1rem",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    setDateSelected(format(new Date(), "yyyy-MM-dd"));
                    setOpenAvailability(true);
                  }}
                >
                  Book Lesson
                </button>
              )}

              <InstructorLessonBookModal
                openAvailability={openAvailability}
                setOpenAvailability={setOpenAvailability}
                showDetailsHandle={showDetailsHandle}
                setDateSelected={setDateSelected}
                instructorData={instructorData}
                instructorSlotData={instructorSlotData}
                setPreview={setPreview}
                studentData={studentData}
                preview={preview}
                dateSelected={dateSelected}
                instructorId={instructorId}
                instrutorDuration={instructorDuration}
                setAddress={setAddress}
                address={address}
                setReload={setReload}
                toast={toast}
              />
            </div>
          </div>
          <div className="second_row">
            <div className="left_Box2">
              <InstructorStudentClasses
                upcoming={stuInsUpcomingData}
                completed={stuInsCompletedData}
                cancelled={stuInsCancelledData}
                setOpenAvailability={setOpenAvailability}
                setDateSelected={setDateSelected}
                instructorId={instructorId}
                setReload={setReload}
                stuInsCredit={stuInsCredit}
                setOpenPurchase={setOpenPurchase}
              />
            </div>
            <div className="right_box2">
              <h3>
                <b>Cars</b>
              </h3>
              {instructorData?.vehicles?.length !== 0
                ? instructorData?.vehicles?.map((i) => (
                    <div className="secondBox_car">
                      <img
                        style={{
                          width: "100px",
                          height: "70px",
                          borderRadius: "10px",
                        }}
                        src={
                          i?.vehicleImage === null || i?.vehicleImage === ""
                            ? car
                            : i?.vehicleImage
                        }
                      />
                      <div className="carInfo">
                        <p>
                          <b>{i?.carModel}</b>
                        </p>
                        <small> {i?.registrationNumber}</small>
                        <p>
                          <GiGearStickPattern />{" "}
                          {convertToTitleCase(i?.transmissinType)}
                        </p>
                      </div>
                    </div>
                  ))
                : "No Cars"}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      <Modal
        open={openSuccess}
        onClose={() => handleOk()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={SuccessTick} alt="" />
            <h1>Success.</h1>
            <h2>Thank you for your purchase!</h2>

            <button
              style={{
                width: "max-content",
                padding: ".5rem 2rem ",
                borderRadius: "10px",
                marginTop: "2rem",
                backgroundColor: "#6FA5EF",
                border: "1px solid gray",
              }}
              onClick={() => handleOk()}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openFailure}
        onClose={() => handleOk()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={FailedCross} alt="" />
            <h1>Failed</h1>
            <h2>Failed to purchase Credits. Try Again! </h2>
            <button
              onClick={() => handleOk()}
              style={{
                width: "max-content",
                padding: ".5rem 2rem ",
                borderRadius: "10px",
                marginTop: "2rem",
                backgroundColor: "#6FA5EF",
                border: "1px solid gray",
              }}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default InstructorProfile;
