import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import stripeImg from "./../../../assets/img/stripeConnect.png";
import { NavLink } from "react-router-dom";
import logo from "./../../../assets/img/BookDrive_logo-invert.jpg";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessTick from "../../../assets/img/success.gif";

const StripeConnect = () => {
  const drivingID = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [drivingSchoolData, setDrivingSchoolData] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClose = () => {
    navigate("/admin/dashboard");
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?drivingSchoolId=${drivingID}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data[0].stripeAccountId));
        setDrivingSchoolData(response?.data);

        if (
          (response.data[0].stripeAccountId !== "" ||
            response.data[0].stripeAccountId !== null ||
            response.data[0].stripeAccountId !== undefined) &&
          response.data[0].isStripeOnboardingComplete === true
        ) {
          navigate("/admin/dashboard");
        }

        if (
          (response.data[0].stripeAccountId !== "" ||
            response.data[0].stripeAccountId !== null ||
            response.data[0].stripeAccountId !== undefined) &&
          (response.data[0].isStripeOnboardingComplete === false ||
            response.data[0].isStripeOnboardingComplete === undefined ||
            response.data[0].isStripeOnboardingComplete === null)
        ) {
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/isOnboarded?userId=${drivingID}&userRoleName=DRIVING_SCHOOL`,
            headers: {},
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.data[0] === false) {
                setOpen(true);
                setIsLoading(false);
              }
              if (response.data.data[0] === true) {
                let data = JSON.stringify({
                  id: drivingID,
                  name: null,
                  businessName: null,
                  profilePic: null,
                  contactNumber: null,
                  address: null,
                  vat: null,
                  isStripeOnboardingComplete: true,
                });

                let config = {
                  method: "put",
                  maxBodyLength: Infinity,
                  url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    setOpenSuccess(true);
                    console.log(response?.message);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [drivingID]);

  const handleOk = () => {
    navigate("/admin/dashboard");
    setIsLoading(true);
  };

  const completeStripe = () => {
    setIsLinkLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/completeOnboarding?userId=${drivingID}&userRoleName=DRIVING_SCHOOL`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        let paymentUrl = response?.data?.data[0];
        window.location.href = paymentUrl;
      })
      .catch((error) => {
        console.log(error);
        setIsLinkLoading(false);
      });
  };

  return (
    <>
      <div className="addBook_outer_container">
        <img className="bookdrive_Logo" src={logo} alt="" />

        <div style={{ width: "100%", textAlign: "center" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <img src={stripeImg} alt="" />
              <p>
                In order to start earning, you need to connect your Stripe
                account.
              </p>
              <NavLink to="/admin/stripeConnection">
                <button
                  style={{
                    backgroundColor: "#6fa5ef",
                    margin: "1rem auto",
                    padding: "1rem 2rem",
                    color: "white",
                    borderRadius: "10px",
                    border: "none",
                    width: "max-content",
                    textAlign: "center",
                    alignItems: "center",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  Connect
                </button>
              </NavLink>
              <NavLink to="/admin/dashboard">
                <p style={{ color: "white" }}>May Be Later</p>
              </NavLink>
            </>
          )}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal2">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              Pending onboarding?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Please Complete your stripe onboarding.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <button style={{ color: "black" }} onClick={handleClose}>
                Cancel
              </button>

              {isLinkLoading ? (
                <button style={{ color: "white", backgroundColor: "#2A335F" }}>
                  <CircularProgress size={10} />
                </button>
              ) : (
                <button
                  style={{ color: "white", backgroundColor: "#2A335F" }}
                  onClick={completeStripe}
                >
                  Complete
                </button>
              )}
            </div>
          </Box>
        </Modal>

        <Modal
          open={openSuccess}
          onClose={handleOk}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal2">
            <div style={{ width: "100%", textAlign: "center" }}>
              <img src={SuccessTick} alt="" />
              <h1>Success.</h1>
              <h2>Stripe Connection Successful!</h2>

              <button
                style={{
                  width: "max-content",
                  padding: ".5rem 2rem ",
                  borderRadius: "10px",
                  marginTop: "2rem",
                  backgroundColor: "#6FA5EF",
                  border: "1px solid gray",
                }}
                onClick={() => handleOk()}
              >
                Go To Dashboard
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default StripeConnect;
