import React from "react";
import FailedCross from "./../assets/img/fail.gif";
import bookdriveLogo from "./../assets/img/BookDrive_logo-invert.jpg";

const UnderMaintenance = () => {
  return (
    <div
      className="login_outer_container"
      style={{ backgroundColor: "#242a46" }}
    >
      <img className="bookdrive_Logo" src={bookdriveLogo} alt="" />
      <div className="login_inner_container">
        <div
          style={{
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
          }}
        >
          <h1 className="boldText">Sorry!</h1>
          <br />
          <h2>404! Page Not Found.</h2>
          <br />
          <h2>Please return back to Dashboard.</h2>
          <br/>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
