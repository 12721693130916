import React, { useState, useContext, useEffect } from "react";
import progress75 from "./../../../assets/img/75-3.png";
import { BiArrowBack } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../layouts/UseContext";

const PreferenceForLessons = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState("");

  const { userId } = useContext(UserContext);
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState("");

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setStudentData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId]);

  const handleDayClick = (day) => {
    // Toggle the selected state of the day
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleTimeChange = (e, type) => {
    const value = e.target.value;

    // Update start and end time based on the input type
    if (type === "start") {
      setStartTime(value);
    } else if (type === "end") {
      setEndTime(value);

      // Check if end time is before start time
      if (
        new Date(`2023-01-01 ${value}`) < new Date(`2023-01-01 ${startTime}`)
      ) {
        setError("End time cannot be before start time");
      } else {
        setError("");
      }
    }
  };

  const handleSendData = () => {
    // Prepare the data string to be sent to the API

    const isDaySelected = selectedDays.length > 0;

    // Check if both start and end times are provided
    const isStartAndEndTimeSelected = startTime && endTime;

    // Check and set errors based on the conditions
    if (!isDaySelected && !isStartAndEndTimeSelected) {
      setError(
        "Please select at least one day and provide both start and end times."
      );
    } else if (!isDaySelected) {
      setError("Please select at least one day.");
    } else if (!isStartAndEndTimeSelected) {
      setError("Please provide both start and end times.");
    } else {
      setError("");

      const selectedDaysString = selectedDays.join(", ");
      const timeRangeString = `${startTime} - ${endTime}`;
      const dataString = `${selectedDaysString} - ${timeRangeString}`;
      console.log("asdasd", dataString);

      let data = JSON.stringify({
        id: userId,
        theoryPassedOn: "",
        address: "",
        preferenceLesson: dataString,
        personalDetails: true,
        transmissionType: studentData?.profile?.transmissionTypePreference,
        experience: true,
        preferenceForLesson: true,
        paymentMethod: false,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/signupDetails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // navigate(`/payment`);
          navigate(`/myLessons`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="signup_outer_container">
      <div className="signup_inner_container">
        <div className="heading">
          <img src={progress75} alt="" />
          <h5>Preference For Lessons</h5>
        </div>
        <div className="days_lesson">
          {daysOfWeek.map((day) => (
            <button
              key={day}
              onClick={() => handleDayClick(day)}
              style={{
                color: selectedDays.includes(day) ? "white" : "#6FA5EF",
                backgroundColor: selectedDays.includes(day)
                  ? "#6FA5EF"
                  : "white",
              }}
            >
              {day}
            </button>
          ))}
        </div>
        <div className="timePickers">
          <input
            type="time"
            value={startTime}
            step="60"
            onChange={(e) => handleTimeChange(e, "start")}
          />
          <input
            type="time"
            value={endTime}
            step="60"
            onChange={(e) => handleTimeChange(e, "end")}
          />
        </div>
        {error && (
          <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
        )}

        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <NavLink to={`/clientExperience`} style={{ width: "auto" }}>
            <BiArrowBack className="backArrow" />
          </NavLink>

          <button
            className="button"
            onClick={handleSendData}
            disabled={!!error}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceForLessons;
