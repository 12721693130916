import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState, useContext } from "react";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { UserContext } from "../../../layouts/UseContext";
import AddressField from "../../../layouts/AddressField";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddInstructors = ({ setRefresh }) => {
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [transmission, setTransmission] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const { userId } = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      drivingSchoolId: userId,
      name: name,
      email: email,
      transmissionType: transmission,
      address: address,
      mobileNumber: phoneNumber,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/createUnregisterStudent`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.success);
        if (response?.data?.success == false) {
          toast.error("Student already exists with this Email ID!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success("Student added!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setName("");
          setEmail("");
          setAddress("");
          setPostalCode("");
          setTransmission("");
          setRefresh(Math.random());

          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  return (
    <div style={{ textAlign: "end" }}>
      <button onClick={handleOpen}>+Add Student</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Add Student
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <p>Name</p>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="newLesson_row3_block2">
                <p>Email Address</p>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <p>Contact Number</p>
                <div className="inputField_input phoneField">
                  <PhoneInput
                    international
                    defaultCountry="GB"
                    value={phoneNumber}
                    limitMaxLength
                    style={{ outline: "none" }}
                    onChange={setPhoneNumber}
                    required
                  />
                </div>
              </div>
              <div className="newLesson_row3_block2">
                <p>Address</p>
                <AddressField
                  style={{ width: "100%" }}
                  setNewAddress={setAddress}
                  postalCode={postalCode}
                  setPostalCode={setPostalCode}
                  newAddress={address}
                />
              </div>
            </div>
            <div className="newLesson_row3">
              <div className="newLesson_row3_block1">
                <p>Transmission Type</p>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "1rem",
                    margin: ".5rem 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <input
                      type="radio"
                      name="transmission"
                      value="MANUAL"
                      onChange={(e) => setTransmission(e.target.value)}
                      required
                    />{" "}
                    <span style={{ marginRight: "10px" }}>Manual</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <input
                      type="radio"
                      name="transmission"
                      value="AUTOMATIC"
                      onChange={(e) => setTransmission(e.target.value)}
                      required
                    />{" "}
                    <span style={{ marginRight: "10px" }}>Auto</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <button
                type="submit"
                style={{ color: "white", backgroundColor: "#2A335F" }}
              >
                Invite To Book Drive
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AddInstructors;
