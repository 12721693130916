import React, { useEffect, useState } from "react";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import { AiOutlinePlus } from "react-icons/ai";
import { BsDownload, BsSearch } from "react-icons/bs";
import { RiArrowGoBackFill, RiDeleteBin6Line } from "react-icons/ri";
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
import { RxCross1 } from "react-icons/rx";
import { Table, Space, Tag } from "antd";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Box, CircularProgress, Typography, Modal } from "@mui/material";
import { MdDelete } from "react-icons/md";

const Reports = () => {
  const storedUserId = localStorage.getItem("userId");
  const [reportsList, setReportsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [reload, setReload] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState("");
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/reports/list/${storedUserId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.reportsList);
        setReportsList(response?.data?.reportsList);
        setFilteredList(response?.data?.reportsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storedUserId, reload]);

  const deleteFiles = (fileId) => {
    setDeleteIsLoading(true);
    console.log("delete");
    let data = JSON.stringify([fileId]);

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/reports/delete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        setDeleteIsLoading(false);
        setDeleteModal(false);
        setSelectDeleteId("");
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("searchQuery", searchQuery);
    console.log(reportsList);
    let lowercaseValue = searchQuery?.toLowerCase() || "";

    const filtered = reportsList?.filter((report) => {
      return (
        lowercaseValue.trim() === "" ||
        report?.reportName?.toLowerCase()?.includes(lowercaseValue) ||
        report?.reportFor?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, reportsList]);

  const truncate = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const data = filteredList?.map((report, index) => ({
    key: index.toString(),
    checkbox: <input type="checkbox" key={`checkbox-${index}`} />,
    name: report?.reportName,
    ReportFor: truncate(report?.reportFor, 20),
    created: report?.createdDate
      ? new Date(report?.createdDate).toLocaleDateString()
      : "",
    dateRange: report.reportRangeEndDate
      ? `${new Date(
          report.reportRangeStartDate
        ).toLocaleDateString()} - ${new Date(
          report.reportRangeEndDate
        ).toLocaleDateString()}`
      : "",
    downloadOptions: report,
  }));

  const columns = [
    {
      title: "Report Type",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Report For",
      dataIndex: "ReportFor",
      key: "ReportFor",
      render: (text) => <p>{text ?? "NA"}</p>,
    },
    {
      title: "Created on",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Date Range",
      dataIndex: "dateRange",
      key: "dateRange",
    },
    {
      title: "Report Download",
      key: "downloadOptions",
      dataIndex: "downloadOptions",
      render: (_, record) => (
        <Space size={"middle"}>
          {record?.downloadOptions?.downloadPdf !== null ||
          record?.downloadOptions?.downloadPdf !== undefined ||
          record?.downloadOptions?.downloadPdf !== "" ? (
            <a href={record?.downloadOptions?.downloadPdf}>
              <GrDocumentPdf size={30} />
            </a>
          ) : (
            ""
          )}

          {record?.downloadOptions?.downloadExcel !== null ||
          record?.downloadOptions?.downloadExcel !== undefined ||
          record?.downloadOptions?.downloadExcel !== "" ? (
            <a href={record?.downloadOptions?.downloadExcel}>
              <GrDocumentExcel size={30} />
            </a>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <RxCross1
            style={{ cursor: "pointer" }}
            size={25}
            onClick={() => {
              setDeleteModal(true);
              setSelectDeleteId(record?.downloadOptions?.id);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />
        <div style={{ width: "100%", margin: "3rem" }}>
          <h1>Report</h1>
          <div
            style={{
              margin: "3rem auto",
            }}
          >
            <div className="searchBar">
              <BsSearch style={{ color: "#6FA5EF" }} />
              <input
                type="text"
                placeholder="Search...."
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ outline: "none" }}
                className=""
              />
            </div>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                gap: "2rem",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <NavLink to={"/admin/newReport"} className="reportOption">
                <button style={{ display: "flex", alignItems: "center" }}>
                  <AiOutlinePlus />
                  <p>New Report</p>
                </button>
              </NavLink>
              {/* <NavLink className="reportOption">
                <BsDownload />
                <p>Download</p>
              </NavLink>
              <NavLink className="reportOption">
                <RiDeleteBin6Line />
                <p>Delete</p>
              </NavLink>
              <NavLink className="reportOption">
                <BiSearchAlt />
                <p>Search</p>
              </NavLink> */}
            </div>

            <Table
              style={{
                marginTop: "2rem",
              }}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
        </div>
        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal2">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              Delete Report?
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                margin: "1rem 0",
                textAlign: "center",
              }}
            >
              <p>Are you sure to delete report?</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "2rem",
                gap: "2rem",
              }}
            >
              <div
                className="calender_modal_button"
                onClick={() => setDeleteModal(false)}
                style={{ color: "#2A335F" }}
              >
                <RiArrowGoBackFill size={30} />
                <p>Exit</p>
              </div>

              <div
                className="calender_modal_button"
                onClick={() => deleteFiles(selectDeleteId)}
                style={{ color: "red" }}
              >
                <MdDelete size={30} />
                {deleteIsLoading ? (
                  <CircularProgress size={"15px"} style={{ color: "white" }} />
                ) : (
                  <p>Delete</p>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Reports;
