import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import PieChartIcon from "@mui/icons-material/PieChart";
import SchoolIcon from "@mui/icons-material/School";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "./../assets/img/BookDrive_logo-invert.jpg";
import { NavLink } from "react-router-dom";
import { UserContext } from "./UseContext";
import { useAuth0 } from "@auth0/auth0-react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import axios from "axios";

const NavbarAdmin = () => {
  const { logoutUser, userId } = useContext(UserContext);
  const { user, loginWithRedirect, logout } = useAuth0();
  const storedUserId = localStorage.getItem("userId");
  const userSubRole = localStorage.getItem("userSubRole");
  const drawerWidth = 200;
  const [drivingSchoolData, setDrivingSchoolData] = useState([]);
  const userRole = localStorage.getItem("userRole");
  const storedUser = localStorage.getItem("userData2");
  const storedUser2 = JSON.parse(storedUser);
  console.log("&&&&", storedUser2);

  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsVisible, setSettingsVisible] = useState(false);

  console.log("drawer", drivingSchoolData);

  const handleToggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close dropdown
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    logoutUser();
  };

  const navOption = [
    {
      key: 1,
      label: "Dashboard",
      icon: <PieChartIcon style={{ color: "white" }} />,
      navigation: "/admin/dashboard",
    },
    {
      key: 2,
      label: "Driving School",
      icon: <SchoolIcon style={{ color: "white" }} />,
      navigation: "/admin/drivingSchool",
    },
    {
      key: 3,
      label: "Instructors",
      icon: <DirectionsCarIcon style={{ color: "white" }} />,
      navigation: "/admin/instructors",
    },
    {
      key: 4,
      label: "Students",
      icon: <GroupsIcon style={{ color: "white" }} />,
      navigation: "/admin/students",
    },
    {
      key: 5,
      label: "Calendar",
      icon: <CalendarMonthIcon style={{ color: "white" }} />,
      navigation: `/admin/calendar/${userId}`,
    },
    {
      key: 6,
      label: "Notification",
      icon: <NotificationsActiveIcon style={{ color: "white" }} />,
      navigation: "/admin/notification",
    },
    {
      key: 7,
      label: "Profile",
      icon: <PersonIcon style={{ color: "white" }} />,
      navigation: "/admin/profile",
    },
    {
      key: 8,
      label: "Reports",
      icon: <AssessmentIcon style={{ color: "white" }} />,
      navigation: "/admin/report",
    },
    {
      key: 9,
      label: "Settings",
      icon: <SettingsIcon style={{ color: "white" }} />,
      onClick: handleOpenDropdown,
    },
  ];

  useEffect(() => {
    if (userSubRole === "ADMIN") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?userId=${storedUser2?.userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setDrivingSchoolData(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/?drivingSchoolId=${storedUserId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setDrivingSchoolData(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [storedUserId]);

  const filteredNavOption = navOption.filter((item) => {
    // If the label is "Driving School" and the userRole is not "Book_Drive," exclude the item
    return !(item.label === "Driving School" && userRole !== "BOOK_DRIVE");
  });

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#242a46",
          color: "white",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <img
        src={logo}
        style={{
          width: "100%",
          display: "flex",
          padding: "1rem",
          boxSizing: "border-box",
        }}
        alt=""
      />
      <p style={{ textAlign: "center" }}>
        <i>{drivingSchoolData[0]?.businessName}</i>
      </p>
      <Divider />
      <List>
        {filteredNavOption.map((i) => (
          <ListItem key={i.key} disablePadding>
            {i.label === "Settings" ? (
              <div style={{ width: "100%" }}>
                <ListItemButton onClick={handleToggleSettings}>
                  <ListItemIcon>{i.icon}</ListItemIcon>
                  <ListItemText primary={i.label} style={{ color: "white" }} />
                  {settingsVisible ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={settingsVisible} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    style={{ paddingLeft: "20%" }}
                  >
                    {userRole === "BOOK_DRIVE" ? (
                      <NavLink to={"/admin/uploadBenefits"}>
                        <ListItemButton
                          style={{
                            color: "white",
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                          onClick={handleCloseDropdown}
                        >
                          <LoyaltyIcon style={{ color: "white" }} />
                          <ListItemText primary="Benefits" />
                        </ListItemButton>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    <NavLink to={"/admin/addUsers"}>
                      <ListItemButton
                        style={{
                          color: "white",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        onClick={handleCloseDropdown}
                      >
                        <PersonAddIcon style={{ color: "white" }} />
                        <ListItemText primary="Add Team" />
                      </ListItemButton>
                    </NavLink>
                    <ListItemButton
                      style={{
                        color: "white",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      onClick={handleLogout}
                    >
                      <LogoutIcon style={{ color: "white" }} />
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </div>
            ) : (
              // Normal ListItem for other tabs
              <NavLink to={i?.navigation}>
                <ListItemButton>
                  <ListItemIcon>{i.icon}</ListItemIcon>
                  <ListItemText primary={i.label} style={{ color: "white" }} />
                </ListItemButton>
              </NavLink>
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default NavbarAdmin;
