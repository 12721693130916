import React from "react";
import addCarImg from "./../../../assets/img/addCar.png";
import hatch from "./../../../assets/img/hatch.png";
import crossover from "./../../../assets/img/crossover.png";
import coupe from "./../../../assets/img/coupe.png";
import { TbPigMoney, TbTruckDelivery } from "react-icons/tb";
import { BsBook } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { IoMdDoneAll } from "react-icons/io";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import noProfileImage from "../../../assets/img/noProfileImage.png";

import { IoLocation } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import noCarPhoto from "../../../assets/img/car1.png";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";

export const AdminInstructorProfile = () => {
  const storedUserId = localStorage.getItem("userId");
  const [instructorData, setInstructorData] = useState("");
  const [instructorSecondaryData, setInstructorSecondaryData] = useState("");
  const [instructorCars, setInstructorCars] = useState([]);
  const { Instid } = useParams();
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [reload, setReload] = useState("");
  const [lessonsData, setLessonsData] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [addCarModal, setAddCarModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [addRegistrationNum, setAddRegistrationNum] = useState("");
  const [addMake, setAddMake] = useState("");
  const [addModel, setAddModel] = useState("");
  const [addYear, setAddYear] = useState("");
  const [addTransmission, setAddTransmission] = useState("");
  const [addInsurane, setAddInsurane] = useState("");
  const [addVehicleType, setAddVehicleType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCarImage, setSelectedCarImage] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    phoneNumber: instructorData?.profile?.phoneNumber,
    pdiCode: instructorData?.profile?.pdiCode,
    adiCode: instructorData?.profile?.adiCode,
    firstName: instructorData?.profile?.firstName,
    lastName: instructorData?.profile?.lastName,
    insuranceNumber: instructorData?.profile?.insuranceNumber,
    badgeExpiry: instructorData?.profile?.badgeExpiry,
  });

  const [editedValues, setEditedValues] = useState({
    autoCharges: [
      ...(instructorData?.profile?.autoCharges || Array(4).fill(0)),
    ],
    charges: [...(instructorData?.profile?.charges || Array(4).fill(0))],
  });

  useEffect(() => {
    setEditedValues({
      autoCharges: [
        ...(instructorData?.profile?.autoCharges || Array(4).fill(0)),
      ],
      charges: [...(instructorData?.profile?.charges || Array(4).fill(0))],
    });
  }, [instructorData]);

  const handleTableCellEdit = (index, type) => (e) => {
    const { value } = e.target;
    // setEditedValues((prevValues) => {
    //   const updatedValues = [...prevValues[type]];
    //   updatedValues[index] = value;
    //   return { ...prevValues, [type]: updatedValues };
    // });

    const regex = /^\d*\.?\d{0,2}$/;

    // Check if the entered value matches the regex
    if (regex.test(value) || value === "") {
      setEditedValues((prevValues) => {
        const updatedArray = Array.isArray(prevValues[type])
          ? [...prevValues[type]]
          : Array(4).fill(0);
        updatedArray[index] = value;
        return { ...prevValues, [type]: updatedArray };
      });
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          canvas.toBlob((blob) => {
            const croppedImage = new File([blob], "cropped_image.png");

            // Call the first API to upload the cropped image
            uploadImageAPI(croppedImage);
          }, "image/png");
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  const uploadImageAPI = (croppedImage) => {
    let data = new FormData();
    data.append("file", croppedImage);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/profileImage`,
      headers: data.get("headers"),
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          console.log(response.data.data[0]);
          updateProfileImageUrlAPI(response.data.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateProfileImageUrlAPI = (imageUrl) => {
    let data = JSON.stringify({
      id: Instid,
      profile: { profilePicture: imageUrl },
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsEditing(false);
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCarImageChange = (e) => {
    const file2 = e.target.files[0];

    if (file2) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;

          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size
          );

          canvas.toBlob((blob) => {
            const croppedImage = new File([blob], "cropped_image.png");

            // Call the first API to upload the cropped image
            uploadCarImageAPI(croppedImage);
          }, "image/png");
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file2);
    }
  };

  const uploadCarImageAPI = (croppedImage) => {
    let data = new FormData();
    data.append("file", croppedImage);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/vehicle/addVehicleImage`,
      headers: data.get("headers"),
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setSelectedCarImage(response?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    // Make API request to update data
    const isChargesData = editedValues !== undefined;

    // Make API request to update data
    const data = {
      id: Instid,
      ...(isChargesData
        ? {
            charges: editedValues.charges.join(","),
            autoCharges: editedValues.autoCharges.join(","),
          }
        : {}),
      ...editedData,
    };

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        console.log(JSON.stringify(response.data));
        setIsEditing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "fullName") {
      const [firstName, ...lastNameArray] = value.split(" ");
      const lastName = lastNameArray.join(" ");

      setEditedData((prevData) => ({
        ...prevData,
        firstName,
        lastName,
      }));
    } else {
      // For other fields, update the state normally
      setEditedData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (Instid) {
      let requestBody = {
        startDate: null,
        endDate: null,
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/dashboard/totalValue?instructorId=${Instid}&month=0&drivingSchoolId=`,
        headers: {},
        data: requestBody,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data.data, null, 2));
          setInstructorSecondaryData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [Instid, reload]);

  useEffect(() => {
    if (Instid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/${Instid}/date?date=${selectedDate}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setLessonsData(response?.data);
          console.log("sadasd", lessonsData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [Instid, selectedDate, reload]);

  useEffect(() => {
    // Format the selected date
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formatted = new Intl.DateTimeFormat("en-US", options).format(
      new Date(selectedDate)
    );
    setFormattedDate(formatted);
  }, [selectedDate]);

  const handlePrevDay = () => {
    const currentDate = new Date(selectedDate);
    currentDate?.setDate(currentDate?.getDate() - 1);
    setSelectedDate(currentDate?.toISOString().split("T")[0]);
  };

  const handleNextDay = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setDate(currentDate?.getDate() + 1);
    setSelectedDate(currentDate?.toISOString().split("T")[0]);
  };

  const handleSuspend = () => {
    setIsLoading(true);
    let data = JSON.stringify({
      id: Instid,
      userRole: "INSTRUCTOR",
      active: false,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        setIsLoading(false);
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleRadioChange = (event) => {
    setAddVehicleType(event.target.value);
  };

  const handleActivate = () => {
    setIsLoading(true);
    let data = JSON.stringify({
      id: Instid,
      userRole: "INSTRUCTOR",
      active: true,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (Instid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/instructor/${Instid}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data.data, null, 2));
          setInstructorData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [Instid, reload]);

  useEffect(() => {
    if (Instid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/vehicle/?instructorId=${Instid}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setInstructorCars(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [Instid, reload]);

  const formatTimeRange = (fromTime, toTime) => {
    const fromTimeFormatted = formatTime(fromTime);
    const toTimeFormatted = formatTime(toTime);
    return `${fromTimeFormatted} - ${toTimeFormatted}`;
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  };

  const addNewcar = (e) => {
    e.preventDefault();

    if (
      addMake === "" ||
      addModel === "" ||
      addYear === "" ||
      addRegistrationNum === "" ||
      addTransmission === "" ||
      addVehicleType === "" ||
      addInsurane === "" ||
      selectedCarImage === ""
    ) {
      toast.error("Please complete all fields.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else {
      let vehicleName = `${addModel} ${addMake}`;
      let data = JSON.stringify([
        {
          model: addModel,
          make: addMake,
          year: addYear,
          registrationNumber: addRegistrationNum,
          transmissionType: addTransmission,
          vehicleBodyType: addVehicleType,
          vehicleName,
          vehicleImage: selectedCarImage,
          insurance: addInsurane,
        },
      ]);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/vehicle/?instructorId=${Instid}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setReload(Math.random());
          setAddCarModal(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="admin_outer_container">
      <NavbarAdmin />
      <div className="instructorProfile">
        <div className="leftSide">
          <div className="instructor_info">
            <div className="row1">
              <div className="changeProfilePicture">
                <label htmlFor="fileInput" className="circle">
                  <img
                    className="image"
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : instructorData?.profile?.profilePicture ??
                          noProfileImage
                    }
                    alt="profile"
                    style={{ width: "175px", height: "175px" }}
                  />
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {isLoading ? (
                    <CircularProgress
                      className="camera-button"
                      style={{ bottom: "0px", right: "0px" }}
                      color="success"
                      size={20}
                    />
                  ) : (
                    <CameraAltIcon
                      className="camera-button"
                      style={{ bottom: "0px", right: "0px" }}
                    />
                  )}
                </label>
              </div>
              <div className="instructor_content">
                <h3 className="boldText">
                  {console.log("asdasdasdasd", instructorData)}
                  {isEditing ? (
                    <input
                      className="admin_instructor_edit_input"
                      type="text"
                      name="fullName"
                      placeholder={`${instructorData?.profile?.firstName} ${instructorData?.profile?.lastName}`}
                      onChange={handleInputChange}
                    />
                  ) : (
                    `${instructorData?.profile?.firstName}${" "}
                        ${instructorData?.profile?.lastName}` ?? 0
                  )}
                </h3>
                <div className="row">
                  <div className="rightside">
                    <p className="boldText">
                      {instructorData?.profile?.phoneNumber}
                    </p>
                    <span style={{ fontSize: "small" }}>
                      {instructorData?.profile?.email}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="rightside">
                    <p className="boldText">
                      {isEditing ? (
                        <input
                          type="text"
                          className="admin_instructor_edit_input"
                          name="insuranceNumber"
                          placeholder={instructorData?.profile?.insuranceNumber}
                          value={editedData?.insuranceNumber}
                          onChange={handleInputChange}
                        />
                      ) : (
                        instructorData?.profile?.insuranceNumber ?? 0
                      )}
                    </p>
                    <p style={{ fontSize: "small", marginTop: ".5rem" }}>
                      Exp.:{" "}
                      {isEditing ? (
                        <input
                          type="date"
                          name="badgeExpiry"
                          className="admin_instructor_edit_input"
                          placeholder={instructorData?.profile?.badgeExpiry}
                          value={editedData?.badgeExpiry}
                          onChange={handleInputChange}
                        />
                      ) : (
                        instructorData?.profile?.badgeExpiry ?? 0
                      )}
                      {/* {instructorData?.profile?.badgeExpiry} */}
                    </p>
                  </div>
                  <div className="buttons">
                    {instructorData?.profile?.isActive === true ? (
                      <button onClick={handleSuspend}>
                        {isLoading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          "Suspend"
                        )}
                      </button>
                    ) : (
                      <button onClick={handleActivate}>
                        {isLoading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          "Activate"
                        )}
                      </button>
                    )}

                    {isEditing ? (
                      <button onClick={handleSave}>
                        {isLoading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          "Save"
                        )}
                      </button>
                    ) : (
                      <button
                        style={{ backgroundColor: "#242a46", color: "white" }}
                        onClick={handleEdit}
                      >
                        Edit Profile
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ width: "100%", margin: "0" }} />
            <div className="moreInformation">
              <div>
                <small>ADI Code</small>
                <p>
                  {isEditing ? (
                    <input
                      type="text"
                      className="admin_instructor_edit_input"
                      name="adiCode"
                      placeholder={instructorData?.profile?.adiCode}
                      value={editedData.adiCode}
                      onChange={handleInputChange}
                    />
                  ) : (
                    instructorData?.profile?.adiCode
                  )}
                </p>
              </div>
              <div>
                <small>PDI Code</small>

                <p>
                  {isEditing ? (
                    <input
                      type="text"
                      className="admin_instructor_edit_input"
                      name="pdiCode"
                      placeholder={instructorData?.profile?.pdiCode}
                      value={editedData.pdiCode}
                      onChange={handleInputChange}
                    />
                  ) : (
                    instructorData?.profile?.pdiCode
                  )}
                </p>
              </div>
              <div>
                <small>CPD Code</small>
                <p>0</p>
              </div>
            </div>
          </div>
          <div className="instructorCredits">
            <table className="adminChargesTable_instructorProfile">
              <thead>
                <tr>
                  <th>My Rate</th>
                  <th>Auto (£)</th>
                  <th>Manual (£)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>1 Hour</p>
                  </td>
                  <td>
                    {/* {console.log(editedValues.autoCharges[0])} */}
                    <input
                      type="number"
                      disabled={!isEditing}
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      value={
                        editedValues?.autoCharges &&
                        editedValues.autoCharges[0] !== undefined
                          ? editedValues.autoCharges[0]
                          : "0"
                      }
                      onChange={handleTableCellEdit(0, "autoCharges")}
                    />
                    {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[0]} */}
                  </td>
                  <td>
                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.charges &&
                        editedValues.charges[0] !== undefined
                          ? editedValues.charges[0]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(0, "charges")}
                    />
                    {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[0]} */}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>5 Hours</p>
                  </td>
                  <td>
                    {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[1]} */}

                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.autoCharges &&
                        editedValues.autoCharges[1] !== undefined
                          ? editedValues.autoCharges[1]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(1, "autoCharges")}
                    />
                  </td>
                  <td>
                    {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[1]} */}
                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.charges &&
                        editedValues.charges[1] !== undefined
                          ? editedValues.charges[1]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(1, "charges")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>10 Hours</p>
                  </td>
                  <td>
                    {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[2]} */}
                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.autoCharges &&
                        editedValues.autoCharges[2] !== undefined
                          ? editedValues.autoCharges[2]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(2, "autoCharges")}
                    />
                  </td>
                  <td>
                    {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[2]} */}
                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.charges &&
                        editedValues.charges[2] !== undefined
                          ? editedValues.charges[2]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(2, "charges")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>20 Hours</p>
                  </td>
                  <td>
                    {/* {instructorData?.profile?.autoCharges === null
                      ? "0"
                      : instructorData?.profile?.autoCharges[3]} */}
                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.autoCharges &&
                        editedValues.autoCharges[3] !== undefined
                          ? editedValues.autoCharges[3]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(3, "autoCharges")}
                    />
                  </td>
                  <td>
                    {/* {instructorData?.profile?.charges === null
                      ? "0"
                      : instructorData?.profile?.charges[3]} */}
                    <input
                      type="number"
                      disabled={!isEditing}
                      value={
                        editedValues?.charges &&
                        editedValues.charges[3] !== undefined
                          ? editedValues.charges[3]
                          : "0"
                      }
                      style={{
                        border: "none",
                        borderBottom: isEditing ? "2px solid #d9d9d9" : "none",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={handleTableCellEdit(3, "charges")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <h1>Calendar</h1>
              <p>
                <NavLink to={`/inst/calendar/${Instid}`}>
                  <u>Open Calender</u>
                </NavLink>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="boldText">{formattedDate}</p>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <button
                  style={{ width: "max-content" }}
                  onClick={handlePrevDay}
                >
                  &lt;
                </button>
                <button
                  style={{ width: "max-content" }}
                  onClick={handleNextDay}
                >
                  &gt;
                </button>
              </div>
            </div>

            <div>
              {lessonsData?.success === false
                ? "No Lesson Scheduled"
                : lessonsData?.data?.map((item) => (
                    <div
                      key={item?.lessonId}
                      className="lessonBlockInst"
                      style={{
                        borderLeft: `10px solid ${
                          item?.lessonColor !== null
                            ? item?.lessonColor
                            : "#781971"
                        }
                        `,
                      }}
                    >
                      <div className="row1">
                        <p>{formatTimeRange(item?.fromTime, item?.toTime)}</p>
                        <p>{item?.durationOfClass}mins</p>
                      </div>
                      <div className="row2">
                        <div
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <IoLocation /> <p>{item?.addressStudent}</p>
                        </div>
                        <p>{item?.lessonName}</p>
                      </div>
                      <hr style={{ width: "100%", margin: ".5rem 0" }} />
                      <p className="boldText">{item?.clientName}</p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div className="rightSide">
          <div className="rightSide_row1">
            <p className="boldText">Pass Rate</p>
            <div className="row_1">
              <h1 className="boldText">
                {instructorSecondaryData?.passRate?.toFixed(2) ?? "0"}%
              </h1>
              <div className="rightSide">
                <div className="block">
                  <small>Pass</small>
                  <p className="boldText">
                    {instructorSecondaryData?.pass?.toFixed(0) ?? "0"}
                  </p>
                </div>
                <div className="block">
                  <small>Fail</small>
                  <p className="boldText">
                    {instructorSecondaryData?.fail?.toFixed(0) ?? "0"}
                  </p>
                </div>
                <div className="block">
                  <small>Cancelled</small>
                  <p className="boldText">
                    {instructorSecondaryData?.cancelled?.toFixed(0) ?? "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="rightSide_row2">
            <div className="blocks">
              <div className="block">
                <TbPigMoney size={30} />
                <p>Revenue</p>
                <h2>£ {instructorSecondaryData?.revenue ?? 0}</h2>
              </div>
              <div className="block">
                <BsBook size={30} />
                <p>Bookings</p>
                <h2>{instructorSecondaryData?.Bookings ?? 0}</h2>
              </div>
              <div className="block">
                <MdCancel size={30} />
                <p>Cancelled Bookings</p>
                <h2>{instructorSecondaryData?.cancelledBookings ?? 0}</h2>
              </div>
              <div className="block">
                <IoMdDoneAll size={30} />
                <p>Lessons Completed</p>
                <h2>{instructorSecondaryData?.lessonCompleted ?? 0}</h2>
              </div>
              <div className="block">
                <BiTime size={30} />
                <p>Waiting List</p>
                <h2>{instructorSecondaryData?.waitingList ?? 0}</h2>
              </div>
              <div className="block">
                <TbTruckDelivery size={30} />
                <p>Delivered hours</p>
                <h2>{instructorSecondaryData?.deliveredHours ?? 0}</h2>
              </div>
            </div>
          </div>
          <div className="rightSide_row3">
            <div className="row1">
              <h1>Cars</h1>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => setAddCarModal(true)}
              >
                +Add Car
              </p>
            </div>
            <div className="row2">
              {instructorCars?.map((i, index) =>
                i?.error == "Vehicle not found with the instructor id" ? (
                  <div
                    key={index}
                    className="carInfo"
                    style={{ width: "max-content" }}
                  >
                    Vehicle not found!!
                  </div>
                ) : (
                  <div key={index} className="carInfo">
                    <img
                      src={
                        i?.vehicleImage === "" ||
                        i?.vehicleImage === null ||
                        i?.vehicleImage === undefined
                          ? noCarPhoto
                          : i.vehicleImage
                      }
                      alt=""
                    />
                    <p className="boldText">
                      {i?.model} {i?.make}
                    </p>
                    <p>{i?.registrationNumber}</p>
                    {i?.transmissionType}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={addCarModal}
        onClose={() => setAddCarModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal modalWidth">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Add Car
          </Typography>
          <form onSubmit={addNewcar}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                margin: "1rem 0",
                textAlign: "center",
              }}
            >
              <div className="inputField" style={{ marginTop: "1rem" }}>
                <span>Body Type</span>
                <div style={{ margin: "1rem" }}>
                  <div className="addCar">
                    <label>
                      <input
                        type="radio"
                        name="test"
                        value="HATCHBACK"
                        checked={addVehicleType === "HATCHBACK"}
                        onChange={handleRadioChange}
                        className="hideRadio"
                      />
                      <img
                        src={hatch}
                        alt="Option 1"
                        className="hideRadioimg"
                      />
                      <p>Hatchback</p>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="test"
                        value="SEDAN"
                        checked={addVehicleType === "SEDAN"}
                        onChange={handleRadioChange}
                        className="hideRadio"
                      />
                      <img
                        src={coupe}
                        alt="Option 1"
                        className="hideRadioimg"
                      />
                      <p>Sedan</p>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="test"
                        value="CROSSOVER"
                        checked={addVehicleType === "CROSSOVER"}
                        onChange={handleRadioChange}
                        className="hideRadio"
                      />
                      <img
                        src={crossover}
                        alt="Option 1"
                        className="hideRadioimg"
                      />
                      <p>Crossover</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="addCar">
                <div className="addCarField">
                  <p>Registration Number</p>
                  <div className="addCarField_input">
                    <input
                      type="text"
                      onChange={(e) => setAddRegistrationNum(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="addCarField">
                  <p>Make</p>
                  <div className="addCarField_input">
                    <input
                      type="text"
                      required
                      onChange={(e) => setAddMake(e.target.value)}
                    />
                  </div>
                </div>
                <div className="addCarField">
                  <p>Model</p>
                  <div className="addCarField_input">
                    <input
                      type="text"
                      required
                      onChange={(e) => setAddModel(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="addCar">
                <div className="addCarField">
                  <p>Year</p>
                  <div className="addCarField_input">
                    <input
                      required
                      type="text"
                      onChange={(e) => setAddYear(e.target.value)}
                    />
                  </div>
                </div>
                <div className="addCarField">
                  <p>Insurance</p>
                  <div className="addCarField_input">
                    <input
                      type="text"
                      required
                      onChange={(e) => setAddInsurane(e.target.value)}
                    />
                  </div>
                </div>
                <div className="addCarField">
                  <p>Transmission Type</p>
                  <div
                    className="button_group2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "1rem 0",
                      gap: "10px",
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        background:
                          addTransmission === "MANUAL" ? "#6FA5EF" : "white",
                        color: addTransmission === "MANUAL" ? "white" : "black",
                      }}
                      onClick={() => setAddTransmission("MANUAL")}
                    >
                      Manual
                    </button>
                    <button
                      type="button"
                      style={{
                        background:
                          addTransmission === "AUTOMATIC" ? "#6FA5EF" : "white",
                        color:
                          addTransmission === "AUTOMATIC" ? "white" : "black",
                      }}
                      onClick={() => setAddTransmission("AUTOMATIC")}
                    >
                      Automatic
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "2rem",
                gap: "2rem",
              }}
            >
              <button
                className="calender_modal_button"
                onClick={() => setAddCarModal(false)}
                style={{ color: "#2A335F" }}
              >
                Exit
              </button>

              <button
                type="submit"
                style={{ color: "white", backgroundColor: "#6fa5ef" }}
              >
                Add
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};
