import React, { useContext, useState } from "react";
import logo from "./../../../assets/img/BookDrive_logo-invert.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../layouts/UseContext";
import CircularProgress from "@mui/material/CircularProgress";

const SignUp = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const drivingData = JSON.parse(localStorage.getItem("drivingSchool"));
  // const drivingID = localStorage.getItem("userId");

  const handleSubmit = () => {
    setIsLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/createStripeAccount?userId=${userId}&email=${email}&userRoleName=DRIVING_SCHOOL`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        let paymentUrl = response?.data?.data[0];
        window.location.href = paymentUrl;
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="addBook_outer_container">
      <img className="bookdrive_Logo" src={logo} alt="" />
      <div className="addBook_inner_container">
        <h1>Stripe Connection</h1>
        <h4>
          In order to start earning, you need to connect your Stripe account.
        </h4>
        <div>
          <div className="inputField">
            <p>Email Address</p>

            <div className="inputField_input">
              <input type="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div className="submitContent">
            {isLoading === true ? (
              <button>
                <CircularProgress size={10} />
              </button>
            ) : (
              <button onClick={() => handleSubmit()}>Connect Stripe</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
