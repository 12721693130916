import { useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  isBefore,
  parseISO,
} from "date-fns";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Calendar = ({ showDetailsHandle, setDateSelected, dateSelected }) => {
  const [currentMonth, setCurrentMonth] = useState(
    dateSelected ? parseISO(dateSelected) : new Date()
  );
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(
    dateSelected ? parseISO(dateSelected) : new Date()
  );

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    setDateSelected(dayStr);
    showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return (
      <div className="header row1 flex-middle">
        <div className="column">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            <ArrowBackIcon />
          </div>
        </div>
        <div className="column">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="column">
          <div className="icon" onClick={() => changeWeekHandle("next")}>
            <ArrowForwardIcon />
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row1">{days}</div>;
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    const today = new Date(); // Get the current date

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isTodayDate = isSameDay(day, today); // Check if the date is today
        const isPastDate = isBefore(day, today) && !isTodayDate; // Check if the date is before today (excluding today)

        days.push(
          <div
            className={`column cell ${
              isSameDay(day, selectedDate)
                ? "selected"
                : isTodayDate
                ? "today1" // Add a class for today's date
                : isPastDate
                ? "past-date" // Add a class for past dates
                : ""
            }`}
            key={day}
            onClick={() => {
              // Disable clicks on past dates
              if (!isPastDate) {
                const dayStr = format(cloneDay, "yyyy-MM-dd");
                onDateClickHandle(cloneDay, dayStr);
              }
            }}
          >
            <span className={`number ${isPastDate ? "past-date" : ""}`}>
              {formattedDate ?? ""}
            </span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row1" key={day}>
          {days ?? ""}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
