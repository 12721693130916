import React, { useContext, useEffect, useState } from "react";
import StudentTabs from "./StudentTabs";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import { BsSearch } from "react-icons/bs";
import AddStudent from "./AddStudent";
import { useLocation } from "react-router-dom";

const MyLessons = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const instId = searchParams.get("instId");

  const tab = searchParams.get("tab");

  console.log("++++++++++++++", tab);

  console.log(instId);

  const [searchQuery, setSearchQuery] = useState("");

  const [refresh, setRefresh] = useState(0);

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />
        <div style={{ width: "100%", margin: "1rem 2rem", overflow: "hidden" }}>
          <div className="adminInstructors_list_row1">
            <h1>Student</h1>
            <AddStudent setRefresh={setRefresh} />
          </div>

          <div className="searchBar">
            <BsSearch style={{ color: "#6FA5EF" }} />
            <input
              type="text"
              placeholder="Search...."
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ outline: "none" }}
            />
          </div>

          <div className="dashboard_tabs">
            <StudentTabs
              refresh={refresh}
              setRefresh={setRefresh}
              searchQuery={searchQuery}
              instId={instId}
              tab={tab}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyLessons;
