import React, { useEffect, useState } from "react";
import axios from "axios";
import { Select, Space } from "antd";
import { useParams } from "react-router-dom";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdDelete } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import {
  formatDate,
  formatTime,
  calculateToTime,
  getDayInCapitalLetters,
} from "../../../layouts/Functions";
import { BsCaretRightFill } from "react-icons/bs";
import { GiPlainCircle } from "react-icons/gi";
import {
  useFetchBookDriveCalendar,
  useFetchClientList,
  useFetchDSCalendar,
  useFetchInstBlocks,
  useFetchInstCalendar,
  useFetchInstructorData,
  useFetchInstructorList,
} from "../../../layouts/effects";
import { RiArrowGoBackFill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";

function Calendar() {
  const { Instid } = useParams();

  const storedUserRole = localStorage.getItem("userRole");
  const storedUserId = localStorage.getItem("userId");
  const [createLessonModal, setcreateLessonModal] = useState(false);
  const [editLessonModal, setEditLessonModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [calendarInstructorData, setCalendarInstructorData] = useState("");
  const [lessonDetailModal, setLessonDetailModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [reload, setReload] = useState(0);
  const [instructorList, setInstructorList] = useState([]);
  const [clientList, setClientList] = useState("");
  const [selectedEventData, setSelectedEventData] = useState("");
  const [hour72, setHour72] = useState(false);

  const [selectedInst, setSelectedInst] = useState(Instid);

  const [selectedClient, setSelectedClient] = useState("");
  const [lessonType, setLessonType] = useState("");
  const [duration, setDuration] = useState("");
  const [date, setDate] = useState(new Date()?.toISOString()?.split("T")[0]);
  const [time, setTime] = useState(formatTime(new Date()));
  const [notes, setNotes] = useState("");
  const [instructoName, setInstructorName] = useState("");

  const [editLessonType, setEditLessonType] = useState("");
  const [editDuration, setEditDuraion] = useState("");
  const [editTime, setEditTime] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editNote, setEditNotes] = useState("");
  const [editClientid, setEditClientId] = useState("");

  const [loading, setLoading] = useState(false);

  const [addCharges, setAddCharges] = useState(false);

  const handleRadioChange = (event) => {
    setAddCharges(event.target.value === "true");
  };

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [openConflictModal, setOpenConflictModal] = useState(false);
  const [openEditConflictModal, setOpenEditConflictModal] = useState(false);

  // useFetchInstructorData(selectedInst, setInstructorData);

  // console.log("list inst", selectedEventData);

  const isMoreThan72Hours = () => {
    const selectedDateTime = new Date(
      `${selectedEventData?.date} ${selectedEventData?.fromTime}`
    );
    const currentDateTime = new Date();
    const timeDifference = selectedDateTime - currentDateTime;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    return setHour72(hoursDifference > 72);
  };

  function formatDate1(inputDate) {
    const parts = inputDate?.split("-");
    if (parts?.length === 3) {
      const [year, month, day] = parts;
      return `${day}/${month}/${year}`;
    } else {
      // Handle invalid date format
      return "Invalid Date";
    }
  }
  // useEffect(() => {
  //   isMoreThan72Hours();
  //   console.log("77777777777", hour72);
  //   console.log("888888888888", selectedEventData);
  // }, [selectedEventData, hour72]);

  const handleDatesSet = (eventInfo) => {
    const newMonth = new Date(eventInfo.view.currentStart).getMonth() + 1;
    const newYear = new Date(eventInfo.view.currentStart).getFullYear();
    setCurrentYear(newYear);
    setCurrentMonth(newMonth);
  };

  const resetValues = () => {
    setSelectedClient("");
    setLessonType("");
    setDuration("");
    setTime(formatTime(new Date()));
    setDate(formatTime(new Date()));
    setNotes("");
  };

  function convertToHumanReadable(str) {
    const words = str.split("_");

    const humanReadable = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    return humanReadable.join(" ");
  }

  useEffect(() => {
    if (lessonType === "VACATION") {
      setSelectedClient("");
    } else if (lessonType === "MEETING") {
      setSelectedClient("");
    } else if (editLessonType === "VACATION") {
      setEditClientId("");
    } else if (editLessonType === "MEETING") {
      setEditClientId("");
    }
  }, [lessonType, editLessonType]);

  const bookConflictLesson = () => {
    setLoading(true);

    let newLessonName;

    if (newLessonName) {
      newLessonName = convertToHumanReadable(lessonType);
    } else {
      newLessonName = lessonType;
    }
    let newTime = `${time}:00`;

    let newId =
      lessonType === "VACATION" || lessonType === "MEETING"
        ? null
        : selectedClient;

    let data = JSON.stringify({
      student: {
        id: newId,
      },
      lessonSchedules: {
        day: getDayInCapitalLetters(date),
        date: date,
        fromTime: newTime,
        toTime: `${calculateToTime(newTime, duration)}:00`,
        durationOfClass: duration,
        lessonType: lessonType,
        status: "UPCOMING",
        lessonName: newLessonName,
        carType: "Instructor's Car",
        notes: notes,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/addLesson/instructor/${selectedInst}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setOpenConflictModal(false);

        setReload(Math.random());
        resetValues();
        setLoading(false);
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const bookLesson = (e) => {
    setLoading(true);
    e.preventDefault();

    // console.log("&&&&&", selectedClient, selectedInst, lessonType);
    if (selectedClient === "") {
      toast.error("Please Select Client.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    } else if (selectedInst === "") {
      toast.error("Please Select Instructor.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    } else if (lessonType === "") {
      toast.error("Please Select Lesson Type.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    } else {
      let newId =
        lessonType === "VACATION" || lessonType === "MEETING"
          ? null
          : selectedClient;

      let newLessonName;

      if (newLessonName) {
        newLessonName = convertToHumanReadable(lessonType);
      } else {
        newLessonName = lessonType;
      }
      let newTime = `${time}:00`;

      let data = JSON.stringify({
        student: {
          id: newId,
        },
        lessonSchedules: {
          day: getDayInCapitalLetters(date),
          date: date,
          fromTime: newTime,
          toTime: `${calculateToTime(newTime, duration)}:00`,
          durationOfClass: duration,
          lessonType: lessonType,
          status: "UPCOMING",
          lessonName: newLessonName,
          carType: "Instructor's Car",
          notes: notes,
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/checkAndAddLessonWithConflict/instructor/${selectedInst}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response);
          if (response?.data?.success === false) {
            setcreateLessonModal(false);
            setOpenConflictModal(true);
            setLoading(false);
          } else {
            setcreateLessonModal(false);
            setReload(Math.random());
            resetValues();
            setLoading(false);
            // console.log(JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const editLessonWithConflict = () => {
    setLoading(true);
    let newLessonName;

    if (newLessonName) {
      newLessonName = convertToHumanReadable(lessonType);
    } else {
      newLessonName = lessonType;
    }
    let newTime;
    if (editTime === selectedEventData?.fromTime) {
      newTime = editTime;
    } else {
      newTime = `${editTime}:00`;
    }

    // console.log("**************", newLessonName);
    let data = JSON.stringify({
      duration: editDuration,
      date: editDate,
      time: newTime,
      lessonType: editLessonType,
      lessonName: newLessonName,
      notes: editNote,
      newClientId: editClientid,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/editLessonWithConflict/${selectedEventData?.lessonId}/instructor/${selectedInst}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response?.data?.success === false) {
          setEditLessonModal(false);
          setLoading(false);
          setOpenEditConflictModal(true);
        } else {
          setLoading(false);
          setEditLessonModal(false);
          setReload(Math.random());
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const editLesson = () => {
    setLoading(true);
    let newLessonName;

    if (newLessonName) {
      newLessonName = convertToHumanReadable(lessonType);
    } else {
      newLessonName = lessonType;
    }
    let newTime;
    if (editTime === selectedEventData?.fromTime) {
      newTime = editTime;
    } else {
      newTime = `${editTime}:00`;
    }
    let data = JSON.stringify({
      duration: editDuration,
      date: editDate,
      time: newTime,
      lessonType: editLessonType,
      notes: editNote,
      lessonName: newLessonName,
      newClientId: editClientid,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/editLesson/${selectedEventId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setReload(Math.random());
        setLoading(false);
        setOpenEditConflictModal(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useFetchInstructorList(storedUserId, setInstructorList, storedUserRole);

  useFetchClientList(storedUserId, setClientList);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/byMonth/instructor/${selectedInst}/${currentYear}/${currentMonth}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCalendarInstructorData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedInst, currentYear, currentMonth, reload]);

  const events = Array.isArray(calendarInstructorData)
    ? calendarInstructorData.map((event) => {
        const startDateTime = `${event?.date}T${event?.fromTime}`;
        const eventStartDate = new Date(startDateTime);

        return {
          title: `${eventStartDate.getHours()}:${eventStartDate.getMinutes()} | ${
            event?.durationOfClass
          } | ${
            event?.clientName ?? event?.lessonName
              ? convertToHumanReadable(event?.lessonName)
              : event?.lessonName
          }`,
          start: eventStartDate?.toISOString(),
          backgroundColor1: event?.lessonColor,
          backgroundColor: "white",
          lessonId: event.lessonId,
          lessonStatus: event?.status,
          borderColor: "white",
        };
      })
    : [];

  const handleCancelLesson = (ParsedlessonId, addChargesData) => {
    setLoading(true);

    // console.log("5555555555", addChargesData);

    let data = JSON.stringify({
      lessonId: ParsedlessonId,
      userRole: "DRIVING_SCHOOL",
      lessonStatus: "CANCELLED",
      refund: addChargesData,
      notes: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/cancelLesson`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        setLoading(false);
        setCancelModal(false);
        setLessonDetailModal(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleEventClick = (info) => {
    const lesson = info?.extendedProps?.lessonId;
    setLoading(true);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/lessonData/${lesson}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const firstData = response;
        const inst = response?.data?.data[0]?.instructorId;

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_URL}/api/instructor/${inst}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response1) => {
            setLoading(false);
            setInstructorName(
              `${response1?.data?.data?.profile?.firstName} ${response1?.data?.data?.profile?.lastName}`
            );

            setSelectedEventId(firstData?.data?.data[0]?.lessonId);
            setSelectedEventData(firstData?.data?.data[0]);
            setLessonDetailModal(true);
            setEditDate(firstData?.data?.data[0]?.date);
            setEditTime(firstData?.data?.data[0]?.fromTime);
            setEditClientId(firstData?.data?.data[0]?.studentId);
            setEditLessonType(firstData?.data?.data[0]?.lessonType);
            setEditDuraion(firstData?.data?.data[0]?.durationOfClass);
            setEditNotes(firstData?.data?.data[0]?.notes);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDeleteLesson = (ParsedlessonId) => {
    setLoading(true);
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/delete/${ParsedlessonId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        setDeleteModal(false);
        setLessonDetailModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="admin_outer_container">
      <NavbarAdmin />
      <div style={{ width: "100%", margin: "1rem 2rem" }}>
        <div style={{ padding: "2rem" }}>
          <Fullcalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={"dayGridMonth"}
            headerToolbar={{
              end: "prev,next",
              start: "title",
              center: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            selectable
            events={events}
            dateClick={() => setcreateLessonModal(true)}
            contentHeight="auto"
            datesSet={handleDatesSet}
            dayMaxEventRows={5}
            eventContent={(arg) => {
              // console.log("asdasd", arg.event.extendedProps.backgroundColor1);
              const borderColor =
                arg.event.extendedProps.lessonStatus === "COMPLETED"
                  ? "green"
                  : arg.event.extendedProps.lessonStatus === "CANCELLED"
                  ? "red"
                  : "grey";

              return (
                <div
                  className="custom-event"
                  style={{
                    borderRadius: "5px",
                    // backgroundColor: `${arg.event.extendedProps.backgroundColor1}`,
                    border: `3px solid ${borderColor}`,
                    display: "flex",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: "black",
                    width: "100%",
                    padding: "4px",
                    alignItems: "center",
                  }}
                  onClick={() => handleEventClick(arg.event)}
                >
                  <span
                    style={{
                      color: `${arg.event.extendedProps.backgroundColor1}`,
                    }}
                  >
                    <GiPlainCircle size={10} />
                  </span>
                  {/* {arg?.event?.extendedProps?.time && (
                    <div className="fc-time">
                      {arg?.event?.extendedProps?.time}
                    </div>
                  )}{" "} */}
                  <div className="fc-title">{arg.event.title}</div>
                </div>
              );
            }}
            eventLimit={true}
            eventLimitText={(eventCount) => `+${eventCount} more`}
            eventClick={(info) => handleEventClick(info.event)}
          />
        </div>
      </div>

      {/* ------------------ Create lesson modal -------------------------- */}
      <>
        <Modal
          open={createLessonModal}
          onClose={() => {
            setcreateLessonModal(false);
            resetValues();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal2">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              New Lesson
            </Typography>
            <form onSubmit={bookLesson}>
              <div className="newLesson_row1">
                <div className="newLesson_row1_block1">
                  <p>Client</p>
                  <Select
                    disabled={
                      lessonType === "MEETING" || lessonType === "VACATION"
                        ? true
                        : false
                    }
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    required
                    placeholder="Select Client"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      margin: ".5rem 0",
                    }}
                    onChange={(selectedOption) => {
                      setSelectedClient(selectedOption);
                    }}
                    options={(clientList || []).map((client) => ({
                      value: client?.profile?.studentId,
                      label: client?.profile?.firstName,
                    }))}
                  />
                </div>

                <div className="newLesson_row1_block2">
                  <p>Instructor</p>
                  <Select
                    required
                    placeholder="Select Instructor"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      margin: ".5rem 0",
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    disabled
                    value={selectedInst}
                    options={
                      instructorList?.map((instructor) => ({
                        value: instructor?.profile?.instructorId,
                        label: `${instructor?.profile?.firstName} ${instructor?.profile?.lastName}`,
                      })) || []
                    }
                  />
                </div>
              </div>

              <div className="newLesson_row2">
                <div className="newLesson_row2_block1">
                  <p>Lesson Type</p>
                  <Select
                    required
                    placeholder="Select Lesson Type"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      margin: ".5rem 0",
                    }}
                    onChange={(selectedOption) => {
                      setLessonType(selectedOption);
                    }}
                    options={[
                      {
                        value: "MOCK_TEST",
                        label: "Mock Test",
                      },
                      {
                        value: "THEORY_TEST",
                        label: "Theory Test",
                      },
                      {
                        value: "DRIVING_TEST",
                        label: "Driving Test",
                      },
                      { value: "ASSESSMENT", label: "Assessment" },
                      { value: "EXTENDED_TEST", label: "Extended Test" },
                      { value: "LESSON", label: "Lesson" },
                      { value: "MEETING", label: "Meeting" },
                      { value: "VACATION", label: "Vacation" },
                    ]}
                  />
                </div>
                <div className="newLesson_row2_block2">
                  <p>Duration</p>
                  <input
                    required
                    type="number"
                    placeholder="Min: 30 Mins and Max: 720 mins"
                    value={duration}
                    onChange={(event) => setDuration(event.target.value)}
                  />
                </div>
              </div>
              <div className="newLesson_row3">
                <div className="newLesson_row3_block1">
                  <p>Date</p>
                  <input
                    required
                    type="date"
                    value={date}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(events) => setDate(events.target.value)}
                  />
                </div>
                <div className="newLesson_row3_block2">
                  <p>Time</p>
                  <input
                    required
                    type="time"
                    placeholder="time"
                    value={time}
                    onChange={(events) => setTime(events.target.value)}
                  />
                </div>
              </div>
              <div className="newLesson_row4">
                <p>Notes</p>
                <textarea
                  rows="5"
                  value={notes}
                  maxLength={50}
                  onChange={(event) => setNotes(event.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "1rem",
                }}
              >
                <button
                  style={{ color: "black" }}
                  onClick={() => {
                    setcreateLessonModal(false);
                    resetValues();
                  }}
                >
                  Exit
                </button>

                <button
                  style={{ color: "white", backgroundColor: "#2A335F" }}
                  type="submit"
                >
                  Book
                </button>
              </div>
            </form>
          </Box>
        </Modal>
        <ToastContainer />
      </>

      {/* ------------------ edit lesson modal -------------------------- */}
      <Modal
        open={editLessonModal}
        onClose={() => setEditLessonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Edit Lesson
          </Typography>
          <div className="newLesson_row1">
            <div className="newLesson_row1_block1">
              <p>Client</p>
              <Select
                disabled={
                  editLessonType === "MEETING" || editLessonType === "VACATION"
                    ? true
                    : false
                }
                required
                placeholder={selectedEventData?.clientName}
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: ".5rem 0",
                }}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(selectedOption) => {
                  setEditClientId(selectedOption);
                }}
                options={(clientList || []).map((client) => ({
                  value: client?.profile?.studentId,
                  label: client?.profile?.firstName,
                }))}
              />
            </div>
            <div className="newLesson_row1_block2">
              <p>Instructor</p>
              <Select
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: ".5rem 0",
                }}
                disabled
                placeholder={instructoName}
              />
            </div>
          </div>
          <div className="newLesson_row2">
            <div className="newLesson_row2_block1">
              <p>Lesson Type</p>
              <Select
                placeholder="Select Lesson Type"
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: ".5rem 0",
                }}
                defaultValue={editLessonType}
                onChange={(selectedOption) => {
                  setEditLessonType(selectedOption);
                }}
                options={[
                  {
                    value: "MOCK_TEST",
                    label: "Mock Test",
                  },
                  {
                    value: "THEORY_TEST",
                    label: "Theory Test",
                  },
                  {
                    value: "DRIVING_TEST",
                    label: "Driving Test",
                  },
                  { value: "ASSESSMENT", label: "Assessment" },
                  { value: "EXTENDED_TEST", label: "Extended Test" },
                  { value: "LESSON", label: "Lesson" },
                  { value: "MEETING", label: "Meeting" },
                  { value: "VACATION", label: "Vacation" },
                ]}
              />
            </div>
            <div className="newLesson_row2_block2">
              <p>Duration</p>
              <input
                type="number"
                value={editDuration}
                onChange={(event) => setDuration(event.target.value)}
              />
            </div>
          </div>
          <div className="newLesson_row3">
            <div className="newLesson_row3_block1">
              <p>Date</p>
              <input
                type="date"
                placeholder="date"
                value={editDate}
                min={new Date().toISOString().split("T")[0]}
                onChange={(events) => setEditDate(events.target.value)}
              />
            </div>
            <div className="newLesson_row3_block2">
              <p>Time</p>
              <input
                type="time"
                placeholder="time"
                value={editTime}
                onChange={(events) => setEditTime(events.target.value)}
              />
            </div>
          </div>

          <div className="newLesson_row4">
            <p>Notes</p>
            <textarea
              rows="5"
              value={editNote}
              maxLength={50}
              onChange={(event) => setEditNotes(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <button
              style={{ color: "black" }}
              onClick={() => setEditLessonModal(false)}
            >
              Exit
            </button>

            <button
              style={{ color: "white", backgroundColor: "#2A335F" }}
              onClick={editLessonWithConflict}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>

      {/* ------------------ Lesson detail modal -------------------------- */}
      <Modal
        open={lessonDetailModal}
        onClose={() => setLessonDetailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            {selectedEventData?.clientName}
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
          >
            <p>
              <span className="boldText">Status:</span>{" "}
              {selectedEventData?.status}
            </p>
            <p>
              <span className="boldText">Time: </span>
              {selectedEventData?.fromTime} - {selectedEventData?.toTime}
            </p>
            <p>
              <span className="boldText">Date:</span>{" "}
              {formatDate1(selectedEventData?.date)}
            </p>
            <p>
              <span className="boldText">Tutor:</span> {instructoName}
            </p>
            <p className="boldText"> Private Note: </p>
            <p>{selectedEventData?.notes}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "1rem",
              gap: "2rem",
            }}
          >
            {selectedEventData?.status === "COMPLETED" ||
            selectedEventData?.status === "CANCELLED" ? (
              <div
                className="calender_modal_button"
                onClick={() => setDeleteModal(true)}
                style={{ color: "red" }}
              >
                <MdDelete size={30} />
                <p>Delete</p>
              </div>
            ) : (
              ""
            )}

            {selectedEventData?.status === "UPCOMING" ||
            selectedEventData?.status === "REQUESTED" ? (
              <div
                className="calender_modal_button"
                onClick={() => setCancelModal(true)}
                style={{ color: "#ffc61a" }}
              >
                <MdCancel size={30} />
                <p>Cancel</p>
              </div>
            ) : (
              ""
            )}

            {selectedEventData?.status === "UPCOMING" ||
            selectedEventData?.status === "REQUESTED" ? (
              <div
                className="calender_modal_button"
                onClick={() => {
                  // console.log("selectedEventId", selectedEventId);
                  setLessonDetailModal(false);
                  setEditLessonModal(true);
                }}
                style={{ color: "#2A335F" }}
              >
                <MdEdit size={30} />
                <p>Edit</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>

      {/* ----------------------- Cancel Modal ------------------------- */}

      <Modal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Cancel lesson
          </Typography>
          {hour72 === false ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <p>Do You Want to add charges for this class?</p>
                <div
                  style={{ display: "flex", gap: "3rem", alignItems: "center" }}
                >
                  <label>
                    <input
                      type="radio"
                      name="addCharges"
                      value="false"
                      checked={addCharges === false}
                      onChange={handleRadioChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="addCharges"
                      value="true"
                      checked={addCharges === true}
                      onChange={handleRadioChange}
                    />
                    No
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "2rem",
                }}
              >
                <button
                  style={{ backgroundColor: "#2A335F" }}
                  onClick={() => {
                    setCancelModal(false);
                  }}
                >
                  Exit
                </button>

                <button
                  onClick={() => {
                    handleCancelLesson(selectedEventId, addCharges);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <p>Are you sure you want to cancel the lesson?</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "2rem",
                }}
              >
                <button
                  style={{ backgroundColor: "#2A335F" }}
                  onClick={() => {
                    setCancelModal(false);
                  }}
                >
                  Exit
                </button>

                <button
                  // style={{ color: "#ffc61a" }}
                  onClick={() => {
                    handleCancelLesson(selectedEventId, true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* ------------------------  Delete modal ------------------------ */}

      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Delete Lesson?
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              margin: "1rem 0",
              textAlign: "center",
            }}
          >
            <p>Are you sure to delete the lesson?</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "2rem",
              gap: "2rem",
            }}
          >
            <button
              style={{ backgroundColor: "#2A335F" }}
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Exit
            </button>

            <button
              // style={{ color: "red" }}
              onClick={() => {
                handleDeleteLesson(selectedEventId);
              }}
            >
              Yes
            </button>
            {/* <div
              className="calender_modal_button"
              onClick={() => setDeleteModal(false)}
              style={{ backgroundColor: "#2A335F" }}
            >
              <RiArrowGoBackFill size={30} />
              <p>Exit</p>
            </div>

            <div
              className="calender_modal_button"
              onClick={() => handleDeleteLesson(selectedEventId)}
              // style={{ color: "red" }}
            >
              <MdDelete size={30} />
              <p>Delete</p>
            </div> */}
          </div>
        </Box>
      </Modal>

      {/* ------------------------  Conflict modal ------------------------ */}

      <Modal
        open={openConflictModal}
        onClose={() => setOpenConflictModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Conflict Lesson?
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              margin: "1rem 0",
              textAlign: "center",
            }}
          >
            <p>
              Lesson already booked for this time , do you still wish to
              continue?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "2rem",
              gap: "2rem",
            }}
          >
            <button
              style={{ backgroundColor: "#2A335F" }}
              onClick={() => {
                setOpenConflictModal(false);
              }}
            >
              Exit
            </button>

            <button
              // style={{ color: "red" }}
              onClick={() => {
                bookConflictLesson();
              }}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>

      {/* ------------------------  Conflict Edit modal ------------------------ */}

      <Modal
        open={openEditConflictModal}
        onClose={() => setOpenEditConflictModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Conflict Lesson?
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              margin: "1rem 0",
              textAlign: "center",
            }}
          >
            <p>
              Lesson already booked for this time , do you still wish to
              continue?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "2rem",
              gap: "2rem",
            }}
          >
            <button
              style={{ backgroundColor: "#2A335F" }}
              onClick={() => {
                setOpenEditConflictModal(false);
              }}
            >
              Exit
            </button>

            <button
              // style={{ backgroundColor: "red" }}
              onClick={() => {
                editLesson();
              }}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>

      {/* ------------------------  loading modal ---------------------- */}

      <Modal
        open={loading}
        // onClose={() => setLessonDetailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal2"
          style={{ backgroundColor: "transparent", textAlign: "center" }}
        >
          <CircularProgress size={50} color="primary" value={100} />
        </Box>
      </Modal>
    </div>
  );
}

export default Calendar;
