import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const calculateTime = (updatedAt) => {
  const currentDate = new Date();
  const backendDate = new Date(updatedAt);
  const currentTime = currentDate.getTime();
  const backendTime = backendDate.getTime();
  const timeDifference = currentTime - backendTime;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let formattedTimeDifference = "";

  if (days > 0) {
    formattedTimeDifference = `${days} days`;
  } else if (hours > 0) {
    formattedTimeDifference = `${hours} hours`;
  } else if (minutes > 0) {
    formattedTimeDifference = `${minutes} mins`;
  } else {
    formattedTimeDifference = `${seconds} secs`;
  }

  return formattedTimeDifference;
};

export const Client = (notification) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>View All</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Clients
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    boxSizing: "border-box",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Clients
                </div>
                <small></small>
                {notification?.notification?.student
                  ?.sort((a, b) => b.createdAt - a.createdAt)
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      className="navbarNotification1"
                      style={{ width: "auto", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          paddingRight: "10px",
                          boxSizing: "border-box",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="notificationContent"
                          style={{ width: "100%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "flex !important",
                                justifyContent: "space-between !important",
                                alignItems: "center !important",
                                gap: "20px !important",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {row?.title} <KeyboardArrowDownIcon />
                              </Typography>
                              <Typography
                                style={{ float: "right", textWrap: "nowrap" }}
                              >
                                {calculateTime(row?.createdAt)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  padding: "0 15px",
                                  boxSizing: "border-box",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.message}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const Instructor = (notification) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>View All</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Instructors
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    boxSizing: "border-box",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Instructors
                </div>
                <small></small>
                {notification?.notification?.instructor
                  ?.sort((a, b) => b.createdAt - a.createdAt)
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      className="navbarNotification1"
                      style={{ width: "auto", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          boxSizing: "border-box",
                          paddingRight: "10px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="notificationContent"
                          style={{ width: "100%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "flex !important",
                                justifyContent: "space-between !important",
                                alignItems: "center !important",
                                gap: "20px !important",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {row?.title} <KeyboardArrowDownIcon />
                              </Typography>
                              <Typography
                                style={{ float: "right", textWrap: "nowrap" }}
                              >
                                {calculateTime(row?.createdAt)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  padding: "0 15px",
                                  boxSizing: "border-box",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.message}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const Payment = (notification) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>View All</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Payments
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Payments
                </div>
                <small></small>
                {notification?.notification?.payment
                  ?.sort((a, b) => b.createdAt - a.createdAt)
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      className="navbarNotification1"
                      style={{ width: "auto", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          paddingRight: "10px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="notificationContent"
                          style={{ width: "100%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "flex !important",
                                justifyContent: "space-between !important",
                                alignItems: "center !important",
                                gap: "20px !important",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {row?.title} <KeyboardArrowDownIcon />
                              </Typography>
                              <Typography
                                style={{ float: "right", textWrap: "nowrap" }}
                              >
                                {calculateTime(row?.createdAt)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  padding: "0 15px",
                                  boxSizing: "border-box",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.message}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const Booking = (notification) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  console.log("asd", notification?.notification?.student);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>View All</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Bookings
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Bookings
                </div>
                <small></small>

                {notification?.notification?.booking
                  ?.sort((a, b) => b.createdAt - a.createdAt)
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      className="navbarNotification1"
                      style={{ width: "auto", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          paddingRight: "10px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="notificationContent"
                          style={{ width: "100%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "flex !important",
                                justifyContent: "space-between !important",
                                alignItems: "center !important",
                                gap: "20px !important",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {row?.title} <KeyboardArrowDownIcon />
                              </Typography>
                              <Typography
                                style={{ float: "right", textWrap: "nowrap" }}
                              >
                                {calculateTime(row?.createdAt)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  paddingLeft: "15px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.message}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const Cancellation = (notification) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>View All</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Cancellation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Cancellation
                </div>
                <small></small>
                {notification?.notification?.cancellation
                  ?.sort((a, b) => b.createdAt - a.createdAt)
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      className="navbarNotification1"
                      style={{ width: "auto", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          paddingRight: "10px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="notificationContent"
                          style={{ width: "100%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "flex !important",
                                justifyContent: "space-between !important",
                                alignItems: "center !important",
                                gap: "20px !important",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {row?.title} <KeyboardArrowDownIcon />
                              </Typography>
                              <Typography
                                style={{ float: "right", textWrap: "nowrap" }}
                              >
                                {calculateTime(row?.createdAt)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  padding: "0 15px",
                                  boxSizing: "border-box",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.message}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export const DrivingSchool = (notification) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div style={{ textAlign: "end" }}>
      <span onClick={handleOpen}>View All</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Driving School
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div className="notification_block">
                <div
                  style={{
                    backgroundColor: "#6FA5EF",
                    padding: "10px",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Driving School
                </div>
                <small></small>
                {notification?.notification?.drivingSchool
                  ?.sort((a, b) => b.createdAt - a.createdAt)
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      className="navbarNotification1"
                      style={{ width: "auto", whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          boxSizing: "border-box",
                          paddingRight: "10px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="notificationContent"
                          style={{ width: "100%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "flex !important",
                                justifyContent: "space-between !important",
                                alignItems: "center !important",
                                gap: "20px !important",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {row?.title} <KeyboardArrowDownIcon />
                              </Typography>
                              <Typography
                                style={{ float: "right", textWrap: "nowrap" }}
                              >
                                {calculateTime(row?.createdAt)}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  padding: "0 15px",
                                  boxSizing: "border-box",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.message}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
