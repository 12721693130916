import React, { useEffect, useState } from "react";
import axios from "axios";
import noData from "../../../assets/img/noData.png";
import noCarDetail from "./../../../assets/img/audiQ7.jpg";
import { NavLink } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import noProfileImage from "../../../assets/img/noProfileImage.png";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { BsSearch } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { UserContext } from "../../../layouts/UseContext";
import { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SwiperSlide, Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { TbManualGearbox } from "react-icons/tb";
import { Navigation } from "swiper/modules";
import { message } from "antd";

import { addDays, format } from "date-fns";
import enUS from "date-fns/locale/en-US";

const truncate = (string, n) => {
  return string?.length > n ? string.substr(0, n - 1) + "..." : string;
};

const truncateDay = (string, n) => {
  return string?.length > n ? string.substr(0, n - 1) : string;
};

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    if (totalPages <= 2) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // Show current page and the next page
    return currentPage === totalPages
      ? [currentPage - 1, currentPage]
      : [currentPage, currentPage + 1];
  };

  return (
    <div className="pagination-container">
      <ul className="pagination">
        {currentPage > 1 && (
          <li>
            <button onClick={() => paginate(currentPage - 1)}>{"<"}</button>
          </li>
        )}

        {getPageNumbers().map((number) => (
          <li key={number}>
            <button onClick={() => paginate(number)}>{number}</button>
          </li>
        ))}

        {currentPage < totalPages && (
          <li>
            <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
          </li>
        )}
      </ul>
    </div>
  );
};

const monthAbbreviations = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

const convertToTitleCase = (input) => {
  const words = input?.toLowerCase().split("_");
  const titleCaseWords = words?.map(
    (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
  );
  return titleCaseWords?.join(" ");
};

export const NoDataComponent = (props) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <img src={noData} alt="" />
      {props.content ? (
        <p>{props.content}</p>
      ) : (
        <p>You don't have any {props.tabName} lessons</p>
      )}

      <NavLink to="/findInstructor">
        <button
          style={{
            backgroundColor: "#2A335F",
            margin: "1rem auto",
            padding: "1rem 2rem",
            color: "white",
            borderRadius: "10px",
            border: "none",
            width: "max-content",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            gap: "5px",
          }}
        >
          <BsSearch />
          Find Instructors
        </button>
      </NavLink>
    </div>
  );
};

export const NoDataComponentInstructorProfile = (props) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <img src={noData} alt="" />
      <p>You have no {props.tabName} lessons. Book Now! </p>
    </div>
  );
};

const ITEMS_PER_PAGE = 5;

export const UpcomingActivityBlock = ({ data, search, setReload }) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelData, setCancelData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    // Update the filtered data whenever the search term changes
    const filtered = data?.filter((classes) => {
      const lessonNameLower = classes?.lessonName?.toLowerCase();
      const lessonTypeLower = classes?.lessonType?.toLowerCase();
      const firstNameLower = classes?.firstName?.toLowerCase();
      const lastNameLower = classes?.lastName?.toLowerCase();
      const fullNameLower =
        `${classes?.firstName} ${classes?.lastName}`?.toLowerCase();
      const searchLower = search?.toLowerCase();

      return (
        search.trim() === "" ||
        lessonNameLower?.includes(searchLower) ||
        lessonTypeLower?.includes(searchLower) ||
        firstNameLower?.includes(searchLower) ||
        lastNameLower?.includes(searchLower) ||
        fullNameLower?.includes(searchLower)
      );
    });
    // ?.slice(indexOfFirstItem, indexOfLastItem);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when the search changes
  }, [data, search]);

  const indexOfLastItemTotal = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemTotal = indexOfLastItemTotal - ITEMS_PER_PAGE;
  const currentDataTotal = data?.slice(
    indexOfFirstItemTotal,
    indexOfLastItemTotal
  );

  // Pagination logic for filtered data when a search is performed
  const indexOfLastItemFiltered = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemFiltered = indexOfLastItemFiltered - ITEMS_PER_PAGE;
  const currentDataFiltered = filteredData?.slice(
    indexOfFirstItemFiltered,
    indexOfLastItemFiltered
  );

  const currentData =
    search.trim() === "" ? currentDataTotal : currentDataFiltered;

  const showPagination =
    search.trim() === ""
      ? data?.length > ITEMS_PER_PAGE
      : filteredData?.length > ITEMS_PER_PAGE;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCancel = (cancelData) => {
    if (!cancelData) {
      console.error("Cancellation data is missing.");
      return;
    }
    setIsCancelling(true);
    const { lessonId } = cancelData;

    let data = JSON.stringify({
      lessonId: lessonId,
      userRole: "STUDENT",
      lessonStatus: "CANCELLED",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/cancelLesson`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsCancelling(false);
        setOpenCancel(false);
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
        setIsCancelling(false);
      });
  };

  const [openCancel, setOpenCancel] = React.useState(false);

  const handleCancelOpen = (lessonId, instructorId, studentId) => {
    setOpenCancel(true);
    setCancelData({ lessonId, instructorId, studentId });
  };
  const handleCancelClose = () => setOpenCancel(false);

  return (
    <div>
      {currentData?.map((classes, index) => (
        <div
          className="upcomingActivityBlock"
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "#781971"
            }
              `,
          }}
          key={index}
        >
          <tr style={{ alignItems: "center" }}>
            <td style={{ width: "15%" }}>
              <div className="lectureName_container displayFlexColumn">
                <Tooltip title={classes?.notes} placement="bottom">
                  <p className="boldText">
                    {convertToTitleCase(classes?.lessonName) ??
                      convertToTitleCase(classes?.lessonType)}
                  </p>
                </Tooltip>

                <small>
                  {new Date(classes?.lessonDate)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    .replace(
                      new RegExp(
                        Object.keys(monthAbbreviations).join("|"),
                        "gi"
                      ),
                      (matched) => monthAbbreviations[matched]
                    )}{" "}
                  | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                </small>
              </div>
            </td>
            <td
              style={{
                width: "15%",
                justifyContent: "center",
              }}
            >
              {classes?.status === "REQUESTED" ? (
                <small
                  style={{
                    fontSize: "10px",
                    color: "red",
                  }}
                >
                  <i>Approval Pending</i>
                </small>
              ) : (
                ""
              )}
            </td>
            <td style={{ width: "30%" }}>
              <div className="instructor_information">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    classes?.profilePicture === null ||
                    classes?.profilePicture === ""
                      ? noProfileImage
                      : classes?.profilePicture
                  }
                  alt=""
                  srcset=""
                />
                <div className="instructor_info displayFlexColumn">
                  <p className="boldText">
                    {`${classes?.firstName} ${
                      classes?.lastName === null ? "" : classes?.lastName
                    } `}
                  </p>
                  <small>{classes?.carType ?? ""}</small>
                </div>
              </div>
            </td>
            <td style={{ width: "20%" }}>
              <div className="lectureTiming displayFlexColumn">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20) ?? "No Address"}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td style={{ width: "10%" }}>
              <div className="lectureCost displayFlexColumn">
                <h4 className="boldText">
                  £ {Number(classes?.amount?.toFixed(2))}
                </h4>
                <small>
                  {classes?.lessonCredit
                    ? `Credit: ${classes?.lessonCredit.toFixed(2)}`
                    : ``}
                </small>
              </div>
            </td>
            <td style={{ width: "15%", justifyContent: "end" }}>
              <div className="cancelLecture">
                <button onClick={() => handleCancelOpen(classes?.lessonId)}>
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={
            search.trim() === "" ? data?.length : filteredData?.length
          }
          paginate={paginate}
          currentPage={currentPage}
        />
      )}

      <Modal
        open={openCancel}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Cancel?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Cancellation within 24hrs will be charged. <br /> Do you wish to
            continue?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <button
              style={{ color: "white", backgroundColor: "red" }}
              onClick={handleCancelClose}
            >
              Decline
            </button>

            <button
              style={{ color: "white", backgroundColor: "#2A335F" }}
              onClick={() => handleCancel(cancelData)}
            >
              {isCancelling ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <CircularProgress size={"15px"} color="success" />
                </div>
              ) : (
                `
                  Accept
                  `
              )}
            </button>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export const InstructorUpcomingActivityBlock = ({ data, setReload }) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelData, setCancelData] = useState(null);

  const handleCancel = (cancelData) => {
    if (!cancelData) {
      console.error("Cancellation data is missing.");
      return;
    }

    setIsCancelling(true);
    const { lessonId } = cancelData;

    let data = JSON.stringify({
      lessonId: lessonId,
      userRole: "STUDENT",
      lessonStatus: "CANCELLED",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/cancelLesson`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsCancelling(false);
        setOpenCancel(false);
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
        setIsCancelling(false);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const showPagination = data?.length > ITEMS_PER_PAGE;

  const [openCancel, setOpenCancel] = React.useState(false);

  const handleCancelOpen = (lessonId, instructorId, studentId) => {
    setOpenCancel(true);
    setCancelData({ lessonId, instructorId, studentId });
  };
  const handleCancelClose = () => setOpenCancel(false);

  return (
    <div>
      {currentData?.map((classes, index) => (
        <div
          className="upcomingActivityBlock"
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "#781971"
            }
          `,
          }}
          key={index}
        >
          <tr>
            <td style={{ width: "20%" }}>
              <div className="lectureName_container displayFlexColumn">
                <Tooltip title={classes?.notes} placement="bottom">
                  <p className="boldText">
                    {convertToTitleCase(classes?.lessonName) ??
                      convertToTitleCase(classes?.lessonType)}
                  </p>
                </Tooltip>
                <small>
                  {new Date(classes?.lessonDate)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    .replace(
                      new RegExp(
                        Object.keys(monthAbbreviations).join("|"),
                        "gi"
                      ),
                      (matched) => monthAbbreviations[matched]
                    )}{" "}
                  | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                </small>
              </div>
            </td>

            <td
              style={{
                width: "20%",
                alignItems: "center",
              }}
            >
              {classes?.status === "REQUESTED" ? (
                <small
                  style={{
                    fontSize: "10px",
                    color: "red",
                  }}
                >
                  <i>Approval Pending</i>
                </small>
              ) : (
                ""
              )}
            </td>

            <td style={{ width: "30%" }}>
              <div className="lectureTiming displayFlexColumn  ">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small style={{ marginRight: "10px" }}>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20)}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td style={{ width: "20%" }}>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">
                  £ {Number(classes?.amount?.toFixed(2))}
                </p>
                <small>
                  {classes?.lessonCredit
                    ? `Credit: ${classes?.lessonCredit.toFixed(2)}`
                    : ``}
                </small>
              </div>
            </td>
            <td
              style={{
                width: "10%",
                justifyContent: "end",
              }}
            >
              <div className="cancelLecture">
                <button onClick={() => handleCancelOpen(classes?.lessonId)}>
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
      <Modal
        open={openCancel}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Cancel?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Cancellation within 24hrs will be charged. <br /> Do you wish to
            continue?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <button style={{ color: "black" }} onClick={handleCancelClose}>
              Decline
            </button>

            <button
              style={{ color: "white", backgroundColor: "#2A335F" }}
              onClick={() => handleCancel(cancelData)}
            >
              {isCancelling ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <CircularProgress size={"15px"} color="success" />
                </div>
              ) : (
                `
                  Accept
                  `
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export const CompletedActivityBlock = ({ data, search }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    // Update the filtered data whenever the search term changes
    const filtered = data?.filter((classes) => {
      const lessonNameLower = classes?.lessonName?.toLowerCase();
      const lessonTypeLower = classes?.lessonType?.toLowerCase();
      const firstNameLower = classes?.firstName?.toLowerCase();
      const lastNameLower = classes?.lastName?.toLowerCase();
      const fullNameLower =
        `${classes?.firstName} ${classes?.lastName}`.toLowerCase();
      const searchLower = search?.toLowerCase();

      return (
        search.trim() === "" ||
        lessonNameLower?.includes(searchLower) ||
        lessonTypeLower?.includes(searchLower) ||
        firstNameLower?.includes(searchLower) ||
        lastNameLower?.includes(searchLower) ||
        fullNameLower?.includes(searchLower)
      );
    });
    // ?.slice(indexOfFirstItem, indexOfLastItem);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when the search changes
  }, [data, search]);

  const indexOfLastItemTotal = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemTotal = indexOfLastItemTotal - ITEMS_PER_PAGE;
  const currentDataTotal = data?.slice(
    indexOfFirstItemTotal,
    indexOfLastItemTotal
  );

  // Pagination logic for filtered data when a search is performed
  const indexOfLastItemFiltered = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemFiltered = indexOfLastItemFiltered - ITEMS_PER_PAGE;
  const currentDataFiltered = filteredData?.slice(
    indexOfFirstItemFiltered,
    indexOfLastItemFiltered
  );

  const currentData =
    search.trim() === "" ? currentDataTotal : currentDataFiltered;

  const showPagination =
    search.trim() === ""
      ? data?.length > ITEMS_PER_PAGE
      : filteredData?.length > ITEMS_PER_PAGE;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {currentData?.map((classes, index) => (
        <div
          key={index}
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "#FC9F5D"
            }
          `,
          }}
          className="completedActivityBlock "
        >
          <tr>
            <td style={{ width: "30%" }}>
              <div className="lectureResult">
                {classes?.lessonRefund === false ? (
                  <TiTick
                    style={{
                      background: "green",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    size={30}
                  />
                ) : (
                  <ImCross
                    style={{
                      padding: "5px",
                      background: "red",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    size={20}
                  />
                )}
                <div className="lectureName_container displayFlexColumn ">
                  <p className="boldText">
                    {convertToTitleCase(classes?.lessonName) ??
                      convertToTitleCase(classes?.lessonType)}
                  </p>
                  <small>
                    {new Date(classes?.lessonDate)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                      })
                      .replace(
                        new RegExp(
                          Object.keys(monthAbbreviations).join("|"),
                          "gi"
                        ),
                        (matched) => monthAbbreviations[matched]
                      )}{" "}
                    | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                  </small>
                </div>
              </div>
            </td>

            <td style={{ width: "40%" }}>
              <div className="instructor_information">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    classes?.profilePicture === null ||
                    classes?.profilePicture === ""
                      ? noProfileImage
                      : classes?.profilePicture
                  }
                  alt=""
                  srcset=""
                />
                <div className="instructor_info displayFlexColumn">
                  <p className="boldText">
                    {`${classes?.firstName} ${classes?.lastName ?? ""}`}
                  </p>
                  <small>{classes?.carType}</small>
                </div>
              </div>
            </td>
            <td style={{ width: "20%" }}>
              <div className="lectureTiming displayFlexColumn  ">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20)}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">
                  {classes.markScored === null || classes.markScored === ""
                    ? "-"
                    : classes.markScored}
                </p>
                <small>Score</small>
              </div>
            </td>
          </tr>
        </div>
      ))}

      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={
            search.trim() === "" ? data?.length : filteredData?.length
          }
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export const InstructorCompletedActivityBlock = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const showPagination = data?.length > ITEMS_PER_PAGE;
  return (
    <div>
      {currentData?.map((classes, index) => (
        <div
          key={index}
          className="completedActivityBlock "
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "#FC9F5D"
            }
      `,
          }}
        >
          <tr style={{ alignItems: "center" }}>
            <td style={{ width: "40%" }}>
              <div className="lectureResult">
                {classes?.lessonRefund === false ? (
                  <TiTick
                    style={{
                      background: "green",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    size={30}
                  />
                ) : (
                  <ImCross
                    style={{
                      padding: "5px",
                      background: "red",
                      borderRadius: "50%",
                      color: "white",
                    }}
                    size={20}
                  />
                )}
                <div className="lectureName_container displayFlexColumn">
                  <p className="boldText">
                    {convertToTitleCase(classes?.lessonName) ??
                      convertToTitleCase(classes?.lessonType)}
                  </p>
                  <small>
                    {new Date(classes?.lessonDate)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                      })
                      .replace(
                        new RegExp(
                          Object.keys(monthAbbreviations).join("|"),
                          "gi"
                        ),
                        (matched) => monthAbbreviations[matched]
                      )}{" "}
                    | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                  </small>
                </div>
              </div>
            </td>
            <td style={{ width: "50%" }}>
              <div className="lectureTiming displayFlexColumn">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20)}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td style={{ width: "10%" }}>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">
                  {classes.markScored === null ? "0" : classes.markScored}
                </p>
                <small>Score</small>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export const CancelledActivityBlock = ({ data, search }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    // Update the filtered data whenever the search term changes
    const filtered = data?.filter((classes) => {
      const lessonNameLower = classes?.lessonName?.toLowerCase();
      const lessonTypeLower = classes?.lessonType?.toLowerCase();
      const firstNameLower = classes?.firstName?.toLowerCase();
      const lastNameLower = classes?.lastName?.toLowerCase();
      const fullNameLower =
        `${classes?.firstName} ${classes?.lastName}`.toLowerCase();
      const searchLower = search?.toLowerCase();

      return (
        search.trim() === "" ||
        lessonNameLower?.includes(searchLower) ||
        lessonTypeLower?.includes(searchLower) ||
        firstNameLower?.includes(searchLower) ||
        lastNameLower?.includes(searchLower) ||
        fullNameLower?.includes(searchLower)
      );
    });
    // ?.slice(indexOfFirstItem, indexOfLastItem);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when the search changes
  }, [data, search]);

  const indexOfLastItemTotal = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemTotal = indexOfLastItemTotal - ITEMS_PER_PAGE;
  const currentDataTotal = data?.slice(
    indexOfFirstItemTotal,
    indexOfLastItemTotal
  );

  // Pagination logic for filtered data when a search is performed
  const indexOfLastItemFiltered = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItemFiltered = indexOfLastItemFiltered - ITEMS_PER_PAGE;
  const currentDataFiltered = filteredData?.slice(
    indexOfFirstItemFiltered,
    indexOfLastItemFiltered
  );

  const currentData =
    search.trim() === "" ? currentDataTotal : currentDataFiltered;

  const showPagination =
    search.trim() === ""
      ? data?.length > ITEMS_PER_PAGE
      : filteredData?.length > ITEMS_PER_PAGE;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {currentData.map((classes, index) => (
        <div
          className="cancelledActivityBlock"
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "red"
            }
      `,
          }}
          key={index}
        >
          <tr style={{ alignItems: "center" }}>
            <td style={{ width: "20%" }}>
              <div className="lectureName_container displayFlexColumn ">
                <p className="boldText">
                  {convertToTitleCase(classes?.lessonName) ??
                    convertToTitleCase(classes?.lessonType)}
                </p>
                <small>
                  {new Date(classes?.lessonDate)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    .replace(
                      new RegExp(
                        Object.keys(monthAbbreviations).join("|"),
                        "gi"
                      ),
                      (matched) => monthAbbreviations[matched]
                    )}{" "}
                  | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                </small>
              </div>
            </td>
            <td style={{ width: "30%" }}>
              <div className="instructor_information">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    classes?.profilePicture === null ||
                    classes?.profilePicture === ""
                      ? noProfileImage
                      : classes?.profilePicture
                  }
                  alt=""
                  srcset=""
                />
                <div className="instructor_info displayFlexColumn">
                  <p className="boldText">
                    {`${classes?.firstName} ${classes?.lastName ?? ""} `}
                  </p>
                  <small>{classes?.carType ?? ""}</small>
                </div>
              </div>
            </td>
            <td style={{ width: "30%" }}>
              <div className="lectureTiming displayFlexColumn  ">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 30)}
                  </Tooltip>
                </small>
              </div>
            </td>

            <td style={{ width: "10%" }}>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">£{classes?.amount?.toFixed(2)}</p>
                <small>
                  {classes?.lessonCredit
                    ? `Credit: ${classes?.lessonCredit?.toFixed(2)}`
                    : ``}
                </small>
              </div>
            </td>
            <td style={{ width: "20%", justifyContent: "end" }}>
              <div className="cancelLecture ">
                <button
                  disabled
                  style={{
                    backgroundColor:
                      classes.lessonRefund === true ? "#C1E1C1" : "#ff6961",
                    color: "black",
                    cursor: "no-drop",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  {classes.lessonRefund === true
                    ? "Credit Refunded"
                    : "Credit Charged"}
                </button>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={
            search.trim() === "" ? data?.length : filteredData?.length
          }
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export const InstructorCancelledActivityBlock = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const showPagination = data?.length > ITEMS_PER_PAGE;
  return (
    <div>
      {currentData?.map((classes, index) => (
        <div
          className="cancelledActivityBlock"
          style={{
            borderLeft: `15px solid ${
              classes?.lessonColor !== null ? classes?.lessonColor : "red"
            }`,
          }}
          key={index}
        >
          <tr>
            <td style={{ width: "30%" }}>
              <div className="lectureName_container  displayFlexColumn">
                <p className="boldText">
                  {convertToTitleCase(classes?.lessonName) ??
                    convertToTitleCase(classes?.lessonType)}
                </p>
                <small>
                  {new Date(classes?.lessonDate)
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    .replace(
                      new RegExp(
                        Object.keys(monthAbbreviations).join("|"),
                        "gi"
                      ),
                      (matched) => monthAbbreviations[matched]
                    )}{" "}
                  | {classes?.fromTime?.split(":").slice(0, 2).join(":")}
                </small>
              </div>
            </td>
            <td style={{ width: "30%" }}>
              <div className="lectureTiming displayFlexColumn  ">
                <p className="boldText">{classes?.durationOfClass} mins</p>
                <small>
                  <ImLocation style={{ marginTop: "-3px" }} />
                  <Tooltip title={classes?.addressStudent} placement="bottom">
                    {truncate(classes?.addressStudent, 20)}
                  </Tooltip>
                </small>
              </div>
            </td>
            <td style={{ width: "10%" }}>
              <div className="lectureCost displayFlexColumn">
                <p className="boldText">£{classes?.amount?.toFixed(2)}</p>
                <small>
                  {classes?.lessonCredit
                    ? `Credit: ${classes?.lessonCredit?.toFixed(2)}`
                    : ``}
                </small>
              </div>
            </td>
            <td style={{ width: "30%", justifyContent: "end" }}>
              <div className="cancelLecture ">
                <button
                  disabled
                  style={{
                    margin: "0",
                    backgroundColor:
                      classes.lessonRefund === true ? "#C1E1C1" : "#ff6961",
                    color: "black",
                    cursor: "no-drop",
                    fontWeight: "bold",
                  }}
                >
                  {classes.lessonRefund === true
                    ? "Credit Refunded"
                    : "Credit Charged"}
                </button>
              </div>
            </td>
          </tr>
        </div>
      ))}
      {showPagination && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export const ConnectInstructor = ({
  data,
  apiRunning,
  setReload,
  studentTransmissionPreference,
}) => {
  const [open, setOpen] = useState(false);
  const { userId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleConnect = (instructorId) => {
    setIsLoading(true);
    setOpen(true);
    let data = JSON.stringify({
      studentId: userId,
      instructorId: instructorId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/requestConnectedInstructor`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {apiRunning ? (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "10rem",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <div>
          {data?.instructorsList?.map((classes) => {
            // const costArray = classes?.charges
            //   ? classes?.charges.split(",")
            //   : [];
            // const firstCost = costArray?.length > 0 ? costArray[0] : "0";

            // const costString =
            //   classes?.transmissionType === "Automatic"
            //     ? classes?.autocharges
            //     : classes?.charges;

            // const costArray = costString ? costString.split(",") : [];
            // const firstCost = costArray?.length > 0 ? costArray[0] : "0";

            const filteredCars = classes?.vehicles?.filter(
              (car) => car?.transmissionType === studentTransmissionPreference
            );

            {
              console.log(classes?.vehicles);
            }

            console.log(studentTransmissionPreference);

            console.log(filteredCars);

            let carToShow;

            if (filteredCars && filteredCars?.length > 0) {
              carToShow = filteredCars[0];
            } else if (classes?.vehicles && classes?.vehicles?.length > 0) {
              carToShow = classes.vehicles[0];
            } else {
              carToShow = null; // or provide a suitable default value
            }

            console.log("asdasd", carToShow);

            const transmissionPreference =
              studentTransmissionPreference === "AUTOMATIC"
                ? "autocharges"
                : "charges";

            const costString =
              carToShow?.transmissionType === "MANUAL"
                ? classes?.charges
                : carToShow?.transmissionType === "AUTOMATIC"
                ? classes?.autocharges
                : carToShow === null || carToShow === undefined
                ? classes?.[transmissionPreference] ?? "0"
                : "0";

            const costArray = costString ? costString.split(",") : [];
            const firstCost = costArray?.length > 0 ? costArray[0] : "0";

            return (
              <table style={{ width: "100%" }} className="instructorRow">
                <tr>
                  <td
                    style={{
                      width: "35%",
                    }}
                    className="instructorRow_info"
                  >
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                      alt=""
                      src={
                        classes?.profilePicture === null ||
                        classes?.profilePicture === ""
                          ? noProfileImage
                          : classes?.profilePicture
                      }
                    />
                    <div className="instructorRow_info_name">
                      <p className="boldText">
                        {`${truncateDay(classes?.firstName, 10)} ${
                          classes?.lastName === 0 || classes?.lastName === null
                            ? ""
                            : truncateDay(classes?.lastName, 20)
                        }`}
                      </p>
                      {classes?.postalCode ? (
                        <small>{classes?.postalCode}</small>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                  <td
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="instructorRow_info"
                  >
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                      src={
                        carToShow?.vehicleImage === "" ||
                        carToShow?.vehicleImage === null ||
                        !carToShow?.vehicleImage
                          ? noCarDetail
                          : carToShow?.vehicleImage
                      }
                      alt="images"
                    />
                    {carToShow ? (
                      <div className="instructorRow_info_name">
                        <p className="boldText">
                          {carToShow?.model ?? ""} {carToShow?.make ?? ""}{" "}
                        </p>
                        {carToShow?.transmissionType ? (
                          <small>
                            <TbManualGearbox />{" "}
                            {convertToTitleCase(carToShow?.transmissionType)}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="instructorRow_info_name">
                        <p className="boldText">No Car Found</p>
                      </div>
                    )}
                  </td>
                  <td className="price" style={{ width: "15%" }}>
                    <h4 className="boldText">£{firstCost}/h</h4>
                  </td>
                  <td className="connectButton">
                    <button
                      style={{
                        margin: "0",
                        width: "max-content",
                        padding: ".7rem 2rem",
                      }}
                      onClick={() => handleConnect(classes?.instructorId)}
                    >
                      Connect
                    </button>
                  </td>
                </tr>
              </table>
            );
          })}

          {data?.unRegiInstructorsList?.map((i) => (
            <div className="instructorRow">
              <div className="instructorRow_info">
                <img
                  style={{ width: "50px", height: "50px" }}
                  alt=""
                  src={noProfileImage}
                />
                <div className="instructorRow_info_name">
                  <p className="boldText">
                    {`${truncateDay(i?.firstName, 10)} ${
                      i?.lastName === 0 || i?.lastName === null
                        ? ""
                        : truncateDay(i?.lastName, 20)
                    }`}
                  </p>
                  {i?.postalCode ? <small>{i?.postalCode}</small> : ""}
                </div>
              </div>
              <div className="connectButton">
                <button
                  style={{ margin: "0" }}
                  onClick={() => handleConnect(i?.id)}
                >
                  Send Request
                </button>
              </div>
            </div>
          ))}
          {/* {console.log("datataaaa", data)} */}

          {data === null ||
          data === undefined ||
          data === "" ||
          data?.length === 0 ? (
            <NoDataComponent content="Use Postal code to find Instructors near you" />
          ) : (
            ""
          )}

          {data?.unRegiInstructorsList?.length === 0 &&
          data?.instructorsList?.length === 0 ? (
            <NoDataComponent content="Use Postal code to find Instructors near you" />
          ) : (
            ""
          )}

          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="cancel_modal"
          >
            <Box className={`modal2 ${isLoading ? "modalLoading" : ""}`}>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "10rem",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} />
                </div>
              ) : (
                <>
                  <div style={{ textAlign: "center", padding: "0 1rem" }}>
                    <img
                      src={noProfileImage}
                      style={{ width: "100px" }}
                      alt=""
                    />
                    <h2>Connection with Instructor</h2>
                    <p style={{ marginTop: "1rem" }}>
                      Connection request has been sent and the instructor will
                      be in touch soon.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          color: "white",
                          backgroundColor: "#2A335F",
                          marginTop: "1rem",
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export const ConnectedInstructorBlock = ({ data, search, userId }) => {
  const getLinkForPhoneNumber = (phoneNumber) => {
    const phoneNumberWithoutPlus = phoneNumber?.replace(/\+/g, "");
    return `https://api.whatsapp.com/send?phone=${phoneNumberWithoutPlus}`;
  };

  return (
    <>
      <h1>Connected Instructors</h1>
      <div className="instructorConnected">
        {data?.drivingSchool
          ?.filter((classes) => {
            const firstNameLower = classes?.firstName?.toLowerCase();
            const lastNameLower = classes?.lastName?.toLowerCase();
            const searchLower = search?.toLowerCase();
            const schoolDriving = classes?.businessName?.toLowerCase();
            const fullNameLower =
              `${classes?.firstName} ${classes?.lastName}`.toLowerCase();

            return (
              search.trim() === "" ||
              firstNameLower?.includes(searchLower) ||
              lastNameLower?.includes(searchLower) ||
              fullNameLower?.includes(searchLower) ||
              schoolDriving?.includes(searchLower)
            );
          })
          .sort((a, b) => (a?.active === b?.active ? 0 : a?.active ? -1 : 1))
          .map((i) => (
            <div className="connected_block">
              <img
                style={{ width: "70px", height: "70px", borderRadius: "10px" }}
                alt=""
                src={i?.profilePicture ?? noProfileImage}
              />
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                  height: "20px",
                  alignItems: "center",
                }}
              >
                <h4 className="boldText">{truncate(i?.businessName, 10)} </h4>
                {i?.active === true ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* <a
                      href={getLinkForPhoneNumber(i?.mobileNumber) ?? ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i?.mobileNumber === null || !i?.mobileNumber ? (
                        ""
                      ) : (
                        <LocalPhoneIcon
                          style={{
                            height: "1rem",
                            width: "1rem",
                            padding: "5px",
                            color: "#6FA5EF",
                            borderRadius: "50%",
                            border: "1px solid #6FA5EF",
                          }}
                        />
                      )}
                    </a> */}
                    <a
                      href={`mailto:hello@bookdrive.co.uk`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MailIcon
                        style={{
                          height: "1rem",
                          width: "1rem",
                          padding: "5px",
                          color: "#6FA5EF",
                          borderRadius: "50%",
                          border: "1px solid #6FA5EF",
                        }}
                      />
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <small>
                {i?.adiCode ? i?.adiCode : i?.pdiCode ? i?.pdiCode : "-"}
              </small>

              <hr style={{ margin: "1rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    Credits
                  </small>
                  <p className="boldText">
                    {Number(i?.credit?.toFixed(2)) ?? 0}
                  </p>
                </div>
                <div>
                  <small>Lessons Completed</small>
                  <p className="boldText">{i?.compeleted ?? "0"}</p>
                </div>
              </div>

              {i?.active === true ? (
                <NavLink to={`/adminOrDrivingSchoolProfile/${i?.id}`}>
                  <button className="viewProfile">View Profile</button>
                </NavLink>
              ) : i?.waiting === true && i?.active === true ? (
                <button disabled className="pendingApproval">
                  Waitlisted
                </button>
              ) : i?.requested === true ? (
                <button disabled className="pendingApproval">
                  Pending Approval
                </button>
              ) : i?.waiting === false && i?.active === false ? (
                <button disabled className="pendingApproval">
                  Pending Approval
                </button>
              ) : (
                <button disabled className="pendingApproval">
                  Pending Approval
                </button>
              )}
            </div>
          ))}

        {data?.instructors
          ?.filter((classes) => {
            const firstNameLower = classes?.firstName?.toLowerCase();
            const lastNameLower = classes?.lastName?.toLowerCase();
            const searchLower = search?.toLowerCase();
            const fullNameLower =
              `${classes?.firstName} ${classes?.lastName}`.toLowerCase();
            return (
              search.trim() === "" ||
              firstNameLower?.includes(searchLower) ||
              lastNameLower?.includes(searchLower) ||
              fullNameLower?.includes(searchLower)
            );
          })
          .sort((a, b) => (a?.active === b?.active ? 0 : a?.active ? -1 : 1))
          .map((i) => (
            <div className="connected_block">
              <img
                style={{ width: "70px", height: "70px", borderRadius: "10px" }}
                alt=""
                src={i?.profilePicture ?? noProfileImage}
              />
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                  height: "20px",
                  alignItems: "center",
                }}
              >
                <h4 className="boldText">
                  {truncateDay(i?.firstName, 10)}{" "}
                  {i?.lastName === 0 || i?.lastName === null
                    ? ""
                    : truncate(i?.lastName, 6)}
                </h4>
                {i?.active === true ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <a
                      href={getLinkForPhoneNumber(i?.mobileNumber) ?? ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i?.mobileNumber === null || !i?.mobileNumber ? (
                        ""
                      ) : (
                        <LocalPhoneIcon
                          style={{
                            height: "1rem",
                            width: "1rem",
                            padding: "5px",
                            color: "#6FA5EF",
                            borderRadius: "50%",
                            border: "1px solid #6FA5EF",
                          }}
                        />
                      )}
                    </a>
                    <a
                      href={`mailto:${i?.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MailIcon
                        style={{
                          height: "1rem",
                          width: "1rem",
                          padding: "5px",
                          color: "#6FA5EF",
                          borderRadius: "50%",
                          border: "1px solid #6FA5EF",
                        }}
                      />
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <small>
                {i?.adiCode ? i?.adiCode : i?.pdiCode ? i?.pdiCode : "-"}
              </small>

              <hr style={{ margin: "1rem 0" }} />
              <div className="row1">
                <div className="row1_left">
                  <small
                    style={{
                      fontSize: "14px",
                      fontWeight: "600px",
                      fontFamily: "BookDriveFont",
                      lineHeight: "20px",
                    }}
                  >
                    Credits
                  </small>
                  <p className="boldText">
                    {Number(i?.credit?.toFixed(2)) ?? 0}
                  </p>
                </div>
                <div>
                  <small>Lessons Completed</small>
                  <p className="boldText">{i?.compeleted ?? "0"}</p>
                </div>
              </div>

              {i?.active === true ? (
                <NavLink to={`/instructorProfile/${i?.id}`}>
                  <button className="viewProfile">View Profile</button>
                </NavLink>
              ) : i?.waiting === true && i?.active === true ? (
                <button disabled className="pendingApproval">
                  Waitlisted
                </button>
              ) : i?.requested === true ? (
                <button disabled className="pendingApproval">
                  Pending Approval
                </button>
              ) : i?.waiting === false && i?.active === false ? (
                <button disabled className="pendingApproval">
                  Pending Approval
                </button>
              ) : (
                <button disabled className="pendingApproval">
                  Pending Approval
                </button>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export const Benefitblock = ({ data }) => {
  return (
    <div className="benefits_page">
      {data?.map((item) => (
        <div className="benefit_block">
          <iframe
            scrolling="no"
            src={item.documentImageUrl}
            title={Math.random()}
            style={{ width: "100%", overflowY: "hidden" }}
          />
          <div className="benefit_content">
            <p className="font-weight-bolder">{item.header}</p>
            <small> {truncate(item.text, 100)}</small>
            <NavLink to={item.documentImageUrl} target="_blank">
              Learn More
            </NavLink>
          </div>
        </div>
      ))}
    </div>
  );
};

export const AvailableSlotBlock = ({
  setOpenAvailability,
  instructorId,
  stuInsCredit,
  setDateSelected,
  setOpenPurchase,
}) => {
  const [dates, setDates] = useState([]);
  const [startDate, setStartDate] = useState(new Date()); // Current date
  const [loading, setLoading] = useState(true);
  const [openError, setOpenError] = useState(false);

  const loadApiData = async (date) => {
    try {
      const formattedDate = formatDate(date);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/instructor/${instructorId}/${formattedDate}`
      );
      const newData = response.data.availableTimeSlots;

      return { date, data: newData };
    } catch (error) {
      console.error(`Error fetching data for ${date}: `, error);
      return { date, data: [] };
    }
  };

  useEffect(() => {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    fetchAndSetData(startDate, endDate);
  }, [startDate, instructorId]);

  const fetchAndSetData = async (start, end) => {
    const datesToLoad = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(start);
      currentDate.setDate(start.getDate() + i);
      datesToLoad.push(currentDate);
    }

    const results = await Promise.all(
      datesToLoad.map((date) => loadApiData(date))
    );

    const formattedDates = results.map((result) => ({
      date: result.date,
      api_data: result.data,
      formattedDate_sec: formatDate(result.date),
    }));

    setDates(formattedDates);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleNextWeek = () => {
    const newStartDate = new Date(startDate);
    newStartDate.setDate(startDate.getDate() + 7);
    setStartDate(newStartDate);
  };

  const handlePreviousWeek = () => {
    const newStartDate = new Date(startDate);
    newStartDate.setDate(startDate.getDate() - 7);
    setStartDate(newStartDate);
  };

  const getDayOfWeek = (date) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[date.getDay()];
  };

  const isToday = (date) => {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  };

  const isTomorrow = (date) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return date.toDateString() === tomorrow.toDateString();
  };

  const formatDateWithoutYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return `${month} ${day}`;
  };

  return (
    <>
      <div className="availableSlotsRow">
        {dates.map((dateItem) => (
          <div key={dateItem.formattedDate_sec} className="dateItem">
            <div
              className="boldText"
              style={
                isToday(dateItem.date)
                  ? {
                      backgroundColor: "rgba(111, 165, 239, .2)",
                      borderLeft: "5px solid #6fa5ef",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      whiteSpace: "nowrap", // Corrected property name
                    }
                  : isTomorrow(dateItem.date)
                  ? {
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      whiteSpace: "nowrap", // Corrected property name
                    }
                  : {
                      padding: "10px",
                      marginBottom: "10px",
                    }
              }
            >
              <span style={{ color: "#8990af" }}>
                {getDayOfWeek(dateItem.date)}
              </span>{" "}
              {isToday(dateItem.date) && (
                <span style={{ fontSize: "small", color: "#8990af" }}>
                  Today
                </span>
              )}
              {isTomorrow(dateItem.date) && (
                <span style={{ fontSize: "small", color: "#8990af" }}>Tom</span>
              )}{" "}
              <br />
              <span
                style={{ fontSize: "large", whiteSpace: "nowrap" }}
                className="boldText"
              >
                {formatDateWithoutYear(dateItem.date)}
              </span>{" "}
            </div>
            {/* <br /> */}
            {console.log(dateItem?.api_data)}
            {dateItem?.api_data?.length === 0 ||
            dateItem?.api_data?.length === null ||
            dateItem?.api_data?.length === undefined ? (
              <button
                disabled
                style={{
                  color: "black",
                  backgroundColor: "#D9D9D9",
                  marginTop: "7px",
                }}
              >
                Not Available
              </button>
            ) : stuInsCredit === 0 || stuInsCredit < 0 ? (
              <button
                style={{
                  color: "white",
                  backgroundColor: "#6FA5EF",
                  marginTop: "7px",
                }}
                onClick={() => setOpenError(true)}
              >
                Available
              </button>
            ) : (
              <button
                style={{
                  color: "white",
                  backgroundColor: "#6FA5EF",
                  marginTop: "7px",
                }}
                onClick={() => {
                  setOpenAvailability(true);
                  setDateSelected(dateItem?.formattedDate_sec);
                }}
              >
                Available
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="arrows">
        {startDate >= new Date() ? (
          <button onClick={handlePreviousWeek}>Prev Week</button>
        ) : (
          <button style={{ cursor: "not-allowed" }} disabled>
            Prev Week
          </button>
        )}
        <button onClick={handleNextWeek}>Next Week</button>
      </div>
      <Modal
        open={openError}
        onClose={() => setOpenError(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal2">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <h3>Insufficient Credits! Please Purchase.</h3>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: ".5rem",
              }}
            >
              <button
                style={{ width: "50%", color: "black" }}
                onClick={() => setOpenError(false)}
              >
                Later
              </button>
              <button
                style={{
                  width: "50%",
                  color: "white",
                  backgroundColor: "#242a46",
                }}
                onClick={() => {
                  setOpenError(false);
                  setOpenPurchase(true);
                }}
              >
                Purchase Lessons
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
