import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Select, Space } from "antd";
import {
  AvailableSlotBlock,
  InstructorCancelledActivityBlock,
  InstructorCompletedActivityBlock,
  InstructorUpcomingActivityBlock,
  NoDataComponentInstructorProfile,
} from "../ActivityBlock";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InstructorStudentClasses({
  upcoming,
  completed,
  cancelled,
  setOpenAvailability,
  instructorId,
  setDateSelected,
  setReload,
  stuInsCredit,
  setOpenPurchase,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedComponent, setSelectedComponent] = useState("Upcoming");

  const renderSelectedComponent = () => {
    if (selectedComponent === "Upcoming") {
      return (
        <div>
          {upcoming === null || upcoming === undefined ? (
            <NoDataComponentInstructorProfile tabName="Upcoming" />
          ) : (
            <InstructorUpcomingActivityBlock
              data={upcoming}
              setReload={setReload}
            />
          )}
        </div>
      );
    } else if (selectedComponent === "Completed") {
      return (
        <div>
          {completed === null || completed === undefined ? (
            <NoDataComponentInstructorProfile tabName="Completed" />
          ) : (
            <InstructorCompletedActivityBlock data={completed} />
          )}
        </div>
      );
    } else if (selectedComponent === "Cancelled") {
      return (
        <div>
          {cancelled === null || cancelled === undefined ? (
            <NoDataComponentInstructorProfile tabName="Cancelled" />
          ) : (
            <InstructorCancelledActivityBlock data={cancelled} />
          )}
        </div>
      );
    } else if (selectedComponent === "AvailableSlots") {
      return (
        <div style={{ marginTop: "2rem" }}>
          <AvailableSlotBlock
            setOpenAvailability={setOpenAvailability}
            instructorId={instructorId}
            stuInsCredit={stuInsCredit}
            setOpenPurchase={setOpenPurchase}
            setDateSelected={setDateSelected}
          />
        </div>
      );
    }
  };

  const handleTopicChange = (event) => {
    setSelectedComponent(`${event}`);
  };

  return (
    <>
      <div className="responsiveTabs">
        <Space style={{ width: "100%" }}>
          <Select
            defaultValue="Upcoming"
            style={{
              width: "100%",
            }}
            onChange={handleTopicChange}
            options={[
              {
                value: "Upcoming",
                label: "Upcoming",
              },
              {
                value: "Completed",
                label: "Completed",
              },
              {
                value: "Cancelled",
                label: "Cancelled",
              },
              {
                value: "AvailableSlots",
                label: "Available Slots",
              },
            ]}
          />
        </Space>
        {renderSelectedComponent()}
      </div>

      <div className="mylessontabs_notResponsive">
        <Box>
          <Box className="instructorsTabs" style={{ width: "100%" }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              className="tabsMyLessons"
              style={{ width: "100%" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className={`instructorNavLink ${
                  value === 0 ? "active" : ""
                } boldText`}
                label="Upcoming"
                {...a11yProps(0)}
              />
              <Tab
                className={`instructorNavLink ${
                  value === 1 ? "active" : ""
                } boldText `}
                label="Completed "
                {...a11yProps(1)}
              />
              <Tab
                className={`instructorNavLink ${
                  value === 2 ? "active" : ""
                } boldText`}
                label="Cancelled"
                {...a11yProps(2)}
              />
              <Tab
                className={`instructorNavLink ${
                  value === 3 ? "active" : ""
                } boldText`}
                label="Available Slots"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {upcoming === null || upcoming === undefined ? (
              <NoDataComponentInstructorProfile tabName="Upcoming" />
            ) : (
              <InstructorUpcomingActivityBlock
                data={upcoming}
                setReload={setReload}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {completed === null || completed === undefined ? (
              <NoDataComponentInstructorProfile tabName="Completed" />
            ) : (
              <InstructorCompletedActivityBlock data={completed} />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {cancelled === null || cancelled === undefined ? (
              <NoDataComponentInstructorProfile tabName="Cancelled" />
            ) : (
              <InstructorCancelledActivityBlock data={cancelled} />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <div>
              <AvailableSlotBlock
                setOpenAvailability={setOpenAvailability}
                instructorId={instructorId}
                stuInsCredit={stuInsCredit}
                setOpenPurchase={setOpenPurchase}
                setDateSelected={setDateSelected}
              />
            </div>
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
}
