import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Calendar() {
  const [month, setMonth] = useState();
  const [day, setDay] = useState();
  const [date, setDate] = useState();
  // const [currentDate, setCurrentDate] = useState();

  let currentDate = new Date();
  let formattedDate =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1) +
    "-" +
    currentDate.getDate();
  let yearValue = currentDate.getFullYear();
  let year = new Date(currentDate.getFullYear(), 0, 1);
  let monthValue = currentDate.getMonth() + 1;
  let d = currentDate.getDay();
  let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
  let currentWeek = Math.ceil((d + 1 + days) / 7);
  // console.log(formattedDate);

  const monthView = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/byMonth/fdd8a7fa-063a-4013-b4da-579e515463cc/${yearValue}/${monthValue}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setMonth(response?.data);
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  const weekView = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/byWeek/instructor/54f462bc-f5ed-40ba-af2c-986a4006ebd2?year=${yearValue}&week=${currentWeek}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setMonth(response?.data);
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  const dayView = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/lessonSchedule/54f462bc-f5ed-40ba-af2c-986a4006ebd2/date?date=${formattedDate}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setDay(response?.data);
      })
      .catch((error) => {
        console.log(error);
        // setIsCancelling(false);
      });
  };

  const handleDateClick = (arg) => {
    // bind with an arrow function
    setDate(arg.dateStr);
  };

  useEffect(() => {
    monthView();
    weekView();
    dayView();
  }, []);

  return (
    <div className="admin_outer_container">
      <NavbarAdmin />
      <div style={{ width: "100%", margin: "1rem 2rem" }}>
        <div style={{ padding: "2rem" }}>
          <Fullcalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            droppable
            initialView={"dayGridMonth"}
            headerToolbar={{
              start: "prev,next",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            height={"90vh"}
            // events={calendar}
            dateClick={handleDateClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Calendar;
