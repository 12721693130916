import React from "react";

const Policy = () => {
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      <h2 className="boldText">Privacy Policy Statement</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "1rem 0",
          textAlign: "justify",
        }}
      >
        <p>Your privacy is important to us.</p>
        <p>
          It is bookdrive's policy to respect your privacy regarding any
          information we may collect while operating our website. Accordingly,
          we have developed this privacy policy in order for you to understand
          how we collect, use, communicate, disclose and otherwise make use of
          personal information. We have outlined our privacy policy below.
        </p>
        <ul style={{ marginLeft: "3rem" }}>
          <li>
            <p>
              We will collect personal information by lawful and fair means and,
              where appropriate, with the knowledge or consent of the individual
              concerned.
            </p>
          </li>
          <li>
            <p>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
            </p>
          </li>
          <li>
            <p>
              We will collect and use personal information solely for fulfilling
              those purposes specified by us and for other ancillary purposes,
              unless we obtain the consent of the individual concerned or as
              required by law.
            </p>
          </li>
          <li>
            <p>
              Personal data should be relevant to the purposes for which it is
              to be used, and, to the extent necessary for those purposes,
              should be accurate, complete, and up-to-date.
            </p>
          </li>
          <li>
            <p>
              We will protect personal information by using reasonable security
              safeguards against loss or theft, as well as unauthorized access,
              disclosure, copying, use or modification.
            </p>
          </li>
          <li>
            <p>
              We will make readily available to customers information about our
              policies and practices relating to the management of personal
              information.
            </p>
          </li>
          <li>
            <p>
              We will only retain personal information for as long as necessary
              for the fulfilment of those purposes.
            </p>
          </li>
        </ul>
        <p>
          We are committed to conducting our business in accordance with these
          principles in order to ensure that the confidentiality of personal
          information is protected and maintained. Bookdrive may change this
          privacy policy from time to time at Bookdrive's sole discretion.
        </p>
      </div>
    </div>
  );
};

export default Policy;
