import React, { useContext, useEffect, useState } from "react";
// import SearchBar from "./SearchBar";
import { NoDataComponent, ConnectedInstructorBlock } from "./ActivityBlock";
import noProfileImage from "./../../../assets/img/noProfileImage.png";
import Navbar from "../../../layouts/Navbar";
import axios from "axios";
import { UserContext } from "../../../layouts/UseContext";
import { BsSearch } from "react-icons/bs";
import CircularProgress from "@mui/material/CircularProgress";

const ConnectedInstructor = () => {
  const [connectedIntructorList, setConnectedIntructorList] = useState("");
  const { userId } = useContext(UserContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // https://drive.google.com/uc?export=download&id=1FbFbO4ZAzRHQjOUZVsm9WwFV24vqeZQU
  // https://drive.google.com/uc?export=download&id=15kRLm5_aUkFzoTAOHuSPu1Qi8BczjkgO
  // https://drive.google.com/uc?export=download&id=1MS4vz4HE8y2-SuPSNeaPvAd9XMzfQQJ9

  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/conectedInstructor?studentId=${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setConnectedIntructorList(response?.data);
          console.log("lalalalaa", connectedIntructorList);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId]);

  return (
    <>
      <Navbar />
      <div className="clientdashboard_outer_container">
        <div className="clientdashboard_inner_container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "15rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
          ) : (
            <div>
              <div className="searchBar d-flex align-items-center w-100 border border-secondary my-4 p-2 rounded-3">
                <BsSearch style={{ color: "#6FA5EF" }} />
                <input
                  type="text"
                  placeholder="Search Instructor Name...."
                  style={{ outline: "none" }}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="connectedIntructor_list">
                {connectedIntructorList == null || // Check for null or undefined
                (typeof connectedIntructorList === "string" &&
                  connectedIntructorList.trim() === "") || // Check for an empty string
                (typeof connectedIntructorList === "object" &&
                  Object.keys(connectedIntructorList).length === 0) ? (
                  <div className="noDataComponent">
                    <NoDataComponent
                      content={
                        <>
                          Book Drive is currently sourcing the right driving
                          instructor to meet your needs. Your credit will be
                          assigned to lessons once you are matched with an
                          instructor.
                          <br />
                          In the meantime, please use our search facility to see
                          if any of the instructors are appropriate.
                        </>
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <ConnectedInstructorBlock
                      data={connectedIntructorList}
                      search={searchQuery}
                      userId={userId}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConnectedInstructor;
