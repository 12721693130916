import React, { useEffect, useState } from "react";
import { Select, Space } from "antd";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { useFetchClientList } from "../../../layouts/effects";

const NewReport = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const storedUserRole = localStorage.getItem("userRole");
  const storedUserId = localStorage.getItem("userId");
  const [clientList, setClientList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [selectedReportFor, setSelectedReportFor] = useState("All School");
  const [instList, setInstList] = useState([]);
  const [selectedDS, setSelectedDS] = useState("");
  const [selectedInst, setSelectedInst] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formatType, setFormatType] = useState("");

  useFetchClientList(storedUserId, setClientList);

  useEffect(() => {
    console.log("00000", studentsOption);
  }, [clientList]);

  useEffect(() => {
    if (storedUserId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/drivingSchool/`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          const filteredSchoolList = response?.data.filter(
            (school) => school.id !== storedUserId
          );

          setSchoolList(filteredSchoolList);
          console.log("asdasdasdasd", filteredSchoolList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [storedUserId]);

  const generateReport = () => {
    if (selectedTopic === "" || formatType === "") {
      toast.error("Please complete the form.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else if (
      (selectedTopic === "TOTAL_REVENUE" ||
        selectedTopic === "STRIPE_PAYMENTS") &&
      (startDate === "" || endDate === "")
    ) {
      toast.error("Please complete the form.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }

    setIsLoading(true);
    let data;

    // BD > total revenue >> All School
    if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "TOTAL_REVENUE" &&
      selectedReportFor === "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "TOTAL_REVENUE",
        userRoleName: "BOOK_DRIVE",
        reportFor: "ALL_SCHOOL",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > total revenue > ds id
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "TOTAL_REVENUE" &&
      selectedReportFor === "SPECIFIC_SCHOOL" &&
      selectedDS !== "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        drivingSchoolId: selectedDS,
        reportType: "TOTAL_REVENUE",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > total revenue >> All inst
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "TOTAL_REVENUE" &&
      selectedReportFor === "SPECIFIC_INSTRUCTOR" &&
      selectedInst === "ALL_INSTRUCTOR"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "TOTAL_REVENUE",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > total revenue >> specific inst
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "TOTAL_REVENUE" &&
      selectedReportFor === "SPECIFIC_INSTRUCTOR" &&
      selectedInst !== "ALL_INSTRUCTOR"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "TOTAL_REVENUE",
        userRoleName: "BOOK_DRIVE",
        reportFor: "SPECIFIC_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
        instructorId: selectedInst,
      });
    }

    // BD > STRIPE_PAYMENTS > ds id
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "STRIPE_PAYMENTS" &&
      selectedReportFor === "SPECIFIC_SCHOOL" &&
      selectedDS !== "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        drivingSchoolId: selectedDS,
        reportType: "STRIPE_PAYMENTS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > STRIPE_PAYMENTS >> All inst
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "STRIPE_PAYMENTS" &&
      selectedReportFor === "SPECIFIC_INSTRUCTOR" &&
      selectedInst === "ALL_INSTRUCTOR"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "STRIPE_PAYMENTS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > STRIPE_PAYMENTS > All school
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "STRIPE_PAYMENTS" &&
      selectedReportFor === "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "STRIPE_PAYMENTS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "ALL_SCHOOL",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > STRIPE_PAYMENTS >> specific inst
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "STRIPE_PAYMENTS" &&
      selectedReportFor === "SPECIFIC_INSTRUCTOR" &&
      selectedInst !== "ALL_INSTRUCTOR"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "STRIPE_PAYMENTS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "SPECIFIC_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
        instructorId: selectedInst,
      });
    }

    // BD > Instructor list > All school
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "INSTRUCTOR_LIST" &&
      selectedReportFor === "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "INSTRUCTOR_LIST",
        userRoleName: "BOOK_DRIVE",
        reportFor: "ALL_SCHOOL",
      });
    }

    // BD > Instructor list > Specific school
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "INSTRUCTOR_LIST" &&
      selectedReportFor === "SPECIFIC_SCHOOL" &&
      selectedDS !== "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        drivingSchoolId: selectedDS,
        reportType: "INSTRUCTOR_LIST",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
      });
    }

    // BD > Client list > Specific school
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "INSTRUCTOR_WISE_CLIENT_LIST" &&
      selectedReportFor === "SPECIFIC_SCHOOL" &&
      selectedDS !== "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        drivingSchoolId: selectedDS,
        reportType: "INSTRUCTOR_WISE_CLIENT_LIST",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
      });
    }

    // BD > Client list > Specific inst
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "INSTRUCTOR_WISE_CLIENT_LIST" &&
      selectedReportFor === "SPECIFIC_INSTRUCTOR" &&
      selectedInst !== "ALL_INSTRUCTOR"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "INSTRUCTOR_WISE_CLIENT_LIST",
        userRoleName: "BOOK_DRIVE",
        reportFor: "SPECIFIC_INSTRUCTOR",
        instructorId: selectedInst,
      });
    }

    // DS > Total revenue > all instructor
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedInst === "ALL_INSTRUCTOR" &&
      selectedTopic === "TOTAL_REVENUE"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "TOTAL_REVENUE",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // DS > Total revenue > specific inst
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedInst !== "ALL_INSTRUCTOR" &&
      selectedTopic === "TOTAL_REVENUE"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "TOTAL_REVENUE",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "SPECIFIC_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
        instructorId: selectedInst,
      });
    }

    // DS > STRIPE_PAYMENTS > specific inst
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedInst !== "ALL_INSTRUCTOR" &&
      selectedTopic === "STRIPE_PAYMENTS"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "STRIPE_PAYMENTS",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "SPECIFIC_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
        instructorId: selectedInst,
      });
    }

    //ds > stripe_payments > all instructor
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedInst === "ALL_INSTRUCTOR" &&
      selectedTopic === "STRIPE_PAYMENTS"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "STRIPE_PAYMENTS",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // DS >  Inst list
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedTopic === "INSTRUCTOR_LIST"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "INSTRUCTOR_LIST",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
      });
    }

    // DS >  Client list
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedTopic === "INSTRUCTOR_WISE_CLIENT_LIST"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "INSTRUCTOR_WISE_CLIENT_LIST",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
      });
    }

    // DS > Progress Card > ALl students
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedTopic === "CLIENT_PROGRESS_CARD" &&
      selectedInst === "ALL_STUDENT"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "CLIENT_PROGRESS_CARD",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "DRIVING_SCHOOL_ALL_STUDENT",
      });
    }

    // DS > Progress Card > specific ID
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedTopic === "CLIENT_PROGRESS_CARD" &&
      selectedInst !== "ALL_STUDENT"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "CLIENT_PROGRESS_CARD",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "SPECIFIC_STUDENT",
        studentId: selectedInst,
      });
    }

    // BD > PROGRESS CARD > DS id
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "CLIENT_PROGRESS_CARD" &&
      selectedReportFor === "SPECIFIC_SCHOOL" &&
      selectedDS !== "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        drivingSchoolId: selectedDS,
        reportType: "CLIENT_PROGRESS_CARD",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_STUDENT",
      });
    }

    // BD > PROGRESS CARD > client id
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "CLIENT_PROGRESS_CARD" &&
      selectedReportFor === "SPECIFIC_CLIENT"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "CLIENT_PROGRESS_CARD",
        userRoleName: "BOOK_DRIVE",
        reportFor: "SPECIFIC_STUDENT",
        studentId: selectedInst,
      });
    }

    // BD > CLIENT_LESSONS >> All School
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "CLIENT_LESSONS" &&
      selectedReportFor === "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "CLIENT_LESSONS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "ALL_SCHOOL",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > CLIENT_LESSONS > ds id
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "CLIENT_LESSONS" &&
      selectedReportFor === "SPECIFIC_SCHOOL" &&
      selectedDS !== "ALL_SCHOOL"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        drivingSchoolId: selectedDS,
        reportType: "CLIENT_LESSONS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // BD > CLIENT_LESSONS >> specific inst
    else if (
      storedUserRole === "BOOK_DRIVE" &&
      selectedTopic === "CLIENT_LESSONS" &&
      selectedReportFor === "SPECIFIC_INSTRUCTOR" &&
      selectedInst !== "ALL_INSTRUCTOR"
    ) {
      data = JSON.stringify({
        drivingSchoolIdForBdAdmin: storedUserId,
        reportType: "CLIENT_LESSONS",
        userRoleName: "BOOK_DRIVE",
        reportFor: "SPECIFIC_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
        instructorId: selectedInst,
      });
    }

    // DS > CLIENT_LESSONS > all instructor
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedInst === "ALL_INSTRUCTOR" &&
      selectedTopic === "CLIENT_LESSONS"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "CLIENT_LESSONS",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "DRIVING_SCHOOL_ALL_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
      });
    }

    // DS > CLIENT_LESSONS > specific inst
    else if (
      storedUserRole === "DRIVING_SCHOOL" &&
      selectedInst !== "ALL_INSTRUCTOR" &&
      selectedTopic === "CLIENT_LESSONS"
    ) {
      data = JSON.stringify({
        drivingSchoolId: storedUserId,
        reportType: "CLIENT_LESSONS",
        userRoleName: "DRIVING_SCHOOL",
        reportFor: "SPECIFIC_INSTRUCTOR",
        fromDate: startDate,
        toDate: endDate,
        instructorId: selectedInst,
      });
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/reports/export`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log(data);

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        let fileUrl;
        if (formatType === "PDF") {
          fileUrl = response.data.reports.downloadPdf;
        } else {
          fileUrl = response.data.reports.downloadExcel;
        }
        window.open(fileUrl, "_blank");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let url;

    if (storedUserRole === "BOOK_DRIVE") {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=${storedUserId}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {},
    };
    axios
      .request({ ...config, url: url })
      .then((response) => {
        setInstList(response?.data?.data?.instructor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storedUserId]);

  const getOptionsByRole = () => {
    switch (storedUserRole) {
      case "BOOK_DRIVE":
        return [
          { value: "TOTAL_REVENUE", label: "Total Revenue" },
          {
            value: "INSTRUCTOR_LIST",
            label: "Instructor List",
          },
          {
            value: "STRIPE_PAYMENTS",
            label: "Stripe Payments",
          },
          {
            value: "INSTRUCTOR_WISE_CLIENT_LIST",
            label: "Instructor Wise Client List",
          },
          {
            value: "CLIENT_PROGRESS_CARD",
            label: "Client Progress Card",
          },
          {
            value: "CLIENT_LESSONS",
            label: "Client Lessons",
          },
        ];
      case "DRIVING_SCHOOL":
        return [
          { value: "TOTAL_REVENUE", label: "Total Revenue" },
          {
            value: "INSTRUCTOR_LIST",
            label: "Instructor List",
          },
          {
            value: "STRIPE_PAYMENTS",
            label: "Stripe Payments",
          },
          {
            value: "INSTRUCTOR_WISE_CLIENT_LIST",
            label: "Instructor Wise Client List",
          },
          {
            value: "CLIENT_PROGRESS_CARD",
            label: "Client Progress Card",
          },
          {
            value: "CLIENT_LESSONS",
            label: "Client Lessons",
          },
        ];

      default:
        return [];
    }
  };

  const studentsOption =
    storedUserRole === "DRIVING_SCHOOL"
      ? [
          { value: "ALL_STUDENT", label: "All Clients" },
          ...clientList.map(
            (business) => (
              console.log("333", business),
              {
                value: business?.profile?.studentId,
                label: `${business?.profile?.firstName} `,
              }
            )
          ),
        ]
      : [
          ...clientList.map(
            (business) => (
              console.log("333", business),
              {
                value: business?.profile?.studentId,
                label: `${business?.profile?.firstName} `,
              }
            )
          ),
        ];

  const instructorOption =
    selectedTopic === "INSTRUCTOR_WISE_CLIENT_LIST"
      ? [
          ...instList.map(
            (business) => (
              console.log(business),
              {
                value: business?.profile?.instructorId,
                label: `${business?.profile?.firstName} ${business?.profile?.lastName}`,
              }
            )
          ),
        ]
      : [
          { value: "ALL_INSTRUCTOR", label: "All Instructor" },
          ...instList.map(
            (business) => (
              console.log(business),
              {
                value: business?.profile?.instructorId,
                label: `${business?.profile?.firstName} ${business?.profile?.lastName}`,
              }
            )
          ),
        ];

  const options = getOptionsByRole();

  const schoolsOptions =
    selectedTopic === "INSTRUCTOR_WISE_CLIENT_LIST"
      ? [
          ...schoolList.map((business) => ({
            value: business.id,
            label: business?.businessName,
          })),
        ]
      : [
          { value: "ALL_SCHOOL", label: "All School" },
          ...schoolList.map((business) => ({
            value: business.id,
            label: business?.businessName,
          })),
        ];

  return (
    <>
      <div className="admin_outer_container">
        <NavbarAdmin />

        <div style={{ width: "100%", margin: "3rem" }}>
          <h1>New Report</h1>
          <div
            style={{
              width: "35%",
              margin: "auto",
              marginTop: "2rem",
            }}
          >
            <div style={{ marginTop: "1rem" }}>
              <h4>Report Type</h4>
              <Space style={{ width: "100%", marginTop: "1rem" }}>
                <Select
                  onChange={(selectedOption) => {
                    setSelectedTopic(selectedOption);
                  }}
                  placeholder="Select"
                  style={{
                    width: "100%",
                  }}
                  options={options}
                />
              </Space>
            </div>

            {storedUserRole === "BOOK_DRIVE" ? (
              <div style={{ marginTop: "1rem" }}>
                <h4>Report For</h4>
                <Space style={{ width: "100%", marginTop: "1rem" }}>
                  <Select
                    onChange={(selectedOption) => {
                      setSelectedReportFor(selectedOption);
                    }}
                    placeholder="Select"
                    style={{
                      width: "100%",
                    }}
                    options={
                      selectedTopic === "INSTRUCTOR_LIST"
                        ? [
                            { value: "ALL_SCHOOL", label: "All School" },
                            {
                              value: "SPECIFIC_SCHOOL",
                              label: "Specific School",
                            },
                          ]
                        : selectedTopic === "INSTRUCTOR_WISE_CLIENT_LIST"
                        ? [
                            {
                              value: "SPECIFIC_SCHOOL",
                              label: "Specific School",
                            },
                            {
                              value: "SPECIFIC_INSTRUCTOR",
                              label: "Specific Instructor",
                            },
                          ]
                        : selectedTopic === "CLIENT_PROGRESS_CARD"
                        ? [
                            {
                              value: "SPECIFIC_SCHOOL",
                              label: "Specific School",
                            },
                            {
                              value: "SPECIFIC_CLIENT",
                              label: "Specific Client",
                            },
                          ]
                        : [
                            { value: "ALL_SCHOOL", label: "All School" },
                            {
                              value: "SPECIFIC_SCHOOL",
                              label: "Specific School",
                            },
                            {
                              value: "SPECIFIC_INSTRUCTOR",
                              label: "Specific Instructor",
                            },
                          ]
                    }
                  />
                </Space>
              </div>
            ) : (
              ""
            )}

            {storedUserRole === "BOOK_DRIVE" &&
            selectedReportFor === "SPECIFIC_SCHOOL" ? (
              <div style={{ marginTop: "1rem" }}>
                <h4>Schools</h4>
                <Space style={{ width: "100%", marginTop: "1rem" }}>
                  <Select
                    onChange={(selectedOption) => {
                      setSelectedDS(selectedOption);
                    }}
                    placeholder="Select School"
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={schoolsOptions}
                  />
                </Space>
              </div>
            ) : (
              ""
            )}

            {(storedUserRole === "BOOK_DRIVE" &&
              selectedReportFor === "SPECIFIC_INSTRUCTOR") ||
            (storedUserRole === "DRIVING_SCHOOL" &&
              selectedTopic !== "CLIENT_PROGRESS_CARD") ? (
              <div style={{ marginTop: "1rem" }}>
                <h4>Instructor</h4>
                <Space style={{ width: "100%", marginTop: "1rem" }}>
                  <Select
                    placeholder="Select Instructor"
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(selectedOption) =>
                      setSelectedInst(selectedOption)
                    }
                    options={instructorOption}
                  />
                </Space>
              </div>
            ) : (
              ""
            )}

            {(storedUserRole === "BOOK_DRIVE" &&
              selectedReportFor === "SPECIFIC_CLIENT") ||
            (storedUserRole === "DRIVING_SCHOOL" &&
              selectedTopic === "CLIENT_PROGRESS_CARD") ? (
              <div style={{ marginTop: "1rem" }}>
                <h4>Client</h4>
                <Space style={{ width: "100%", marginTop: "1rem" }}>
                  <Select
                    placeholder="Select Client"
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(selectedOption) =>
                      setSelectedInst(selectedOption)
                    }
                    options={studentsOption}
                  />
                </Space>
              </div>
            ) : (
              ""
            )}

            {selectedTopic === "TOTAL_REVENUE" ||
            selectedTopic === "STRIPE_PAYMENTS" ||
            selectedTopic === "CLIENT_LESSONS" ? (
              <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                <div style={{ width: "100%" }}>
                  <h4>Start Date</h4>
                  <input
                    type="date"
                    placeholder="Start Time"
                    style={{
                      border: "1px solid #8990AF",
                      marginTop: "1rem",
                      width: "100%",
                      padding: ".5rem",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                    }}
                    value={startDate}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <h4>End Date</h4>
                  <input
                    type="date"
                    placeholder="Start Time"
                    max={new Date().toISOString().split("T")[0]}
                    style={{
                      border: "1px solid #8990AF",
                      marginTop: "1rem",
                      width: "100%",
                      padding: ".5rem",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                    }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
            ) : null}

            <div style={{ marginTop: "1rem" }}>
              <h4>Format</h4>
              <Space style={{ width: "100%", marginTop: "1rem" }}>
                <Select
                  defaultValue="Select"
                  style={{
                    width: "100%",
                  }}
                  onChange={(selectedOption) => setFormatType(selectedOption)}
                  options={[
                    { value: "PDF", label: "PDF" },
                    { value: "Excel", label: "Excel" },
                  ]}
                />
              </Space>
            </div>

            <div
              className="reports_buttons"
              style={{ marginTop: "1rem", display: "flex", gap: "1rem" }}
            >
              <NavLink to={"/admin/report"}>
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "#6FA5EF",
                    border: "1px solid #6FA5EF",
                  }}
                >
                  Cancel
                </button>
              </NavLink>
              <button
                onClick={() => generateReport()}
                style={{ backgroundColor: "#242a46" }}
              >
                {isLoading === true ? <CircularProgress size={20} /> : "Create"}
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default NewReport;
