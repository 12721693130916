import React, { useContext, useState } from "react";
import Navbar from "../../../layouts/Navbar";
import Slider from "@mui/material/Slider";
import { ConnectInstructor } from "./ActivityBlock";
import { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { UserContext } from "../../../layouts/UseContext";
import { BsFilterCircle } from "react-icons/bs";
import { Button, Drawer } from "antd";

const FindInstructor = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const nextWeekDate = new Date(
    currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
  );
  const nYear = nextWeekDate.getFullYear();
  const nMonth = String(nextWeekDate.getMonth() + 1).padStart(2, "0");
  const nDay = String(nextWeekDate.getDate()).padStart(2, "0");
  const nextWeekFormattedDate = `${nYear}-${nMonth}-${nDay}`;
  const [instructorList, setInstrctorList] = useState([]);
  const { userId } = useContext(UserContext);
  const [reload, setReload] = useState(0);
  const [studentPostalCode, setStudentPostalCode] = useState("");
  const [studentTransmissionPreference, setStudentTransmissionProference] =
    useState("");

  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setStudentPostalCode(response?.data?.data?.profile?.postalCode);
          console.log("postal code", response?.data?.data?.profile?.postalCode);
          setPostalCode(response?.data?.data?.profile?.postalCode);
          setStudentTransmissionProference(
            response?.data?.data?.profile?.transmissionTypePreference
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId]);

  const [radius, setRadius] = useState(30);
  const [creditPrice, setCreditPrice] = useState(25);
  const [postalCode, setPostalCode] = useState("");
  const [gender, setGender] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [availabilityDate, setAvailabilityDate] = useState("");
  const [fromTime, setFromTime] = useState("09:00:00");
  const [transmissionType, setTransmissionType] = useState("");

  const [apiRunning, setApiRunning] = useState(true);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleChangeRadius = (event, newValue) => {
    setRadius(newValue);
  };
  const handleChangeCreditPrice = (event, newValue) => {
    setCreditPrice(newValue);
  };

  const valuetextRadius = (radius) => {
    return `${radius}`;
  };

  const valuetextPrice = (creditPrice) => {
    return `${creditPrice}`;
  };

  useEffect(() => {
    if (!userId) {
      setInstrctorList([]);
      setApiRunning(false);
    }

    if (postalCode?.length === 0) {
      setInstrctorList([]);
      setApiRunning(false);
      return;
    } else if (postalCode?.length !== 2) {
      setApiRunning(false);
      return;
    }

    setApiRunning(true);
    let transmisstionType2;
    if (transmissionType === "BOTH" || transmissionType === "") {
      transmisstionType2 = ["BOTH", "MANUAL", "AUTOMATIC"];
    } else if (transmissionType === "MANUAL") {
      transmisstionType2 = ["BOTH", "MANUAL"];
    } else {
      transmisstionType2 = ["BOTH", "AUTOMATIC"];
    }

    let gender2;
    if (gender === "MALE") {
      gender2 = ["MALE"];
    } else if (gender === "FEMALE") {
      gender2 = ["FEMALE"];
    } else if (gender === "ALL") {
      gender2 = ["MALE", "FEMALE"];
    }

    let vehicle2;
    if (vehicle === "ALL") {
      vehicle2 = ["HATCHBACK", "SEDAN", "CROSSOVER", "COUPE"];
    } else if (vehicle === "HACTHBACK") {
      vehicle2 = ["HACTHBACK"];
    } else if (vehicle === "SEDAN") {
      vehicle2 = ["SEDAN"];
    } else if (vehicle === "CROSSOVER") {
      vehicle2 = ["CROSSOVER"];
    } else if (vehicle === "COUPE") {
      vehicle2 = ["COUPE"];
    }

    let date2;
    if (availabilityDate === "ALL") {
      date2 = formattedDate;
    } else if (availabilityDate === "TODAY") {
      date2 = formattedDate;
    } else if (availabilityDate === "NEXTWEEK") {
      date2 = nextWeekFormattedDate;
    }

    let data = JSON.stringify({
      studentId: userId,
      postalCode: postalCode?.toUpperCase()?.slice(0, 2),
      radius: radius,
      priceRange: creditPrice,
      gender: gender2,
      vehicle: vehicle2,
      availability: date2,
      fromTime: fromTime,
      transmissionType: transmisstionType2,
      limit: 100,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/fillterInstructorList`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setInstrctorList(response?.data?.data);
        setApiRunning(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    userId,
    postalCode,
    radius,
    creditPrice,
    gender,
    reload,
    vehicle,
    availabilityDate,
    fromTime,
    transmissionType,
  ]);

  const clearButton = () => {
    setPostalCode(postalCode);
    setRadius(30);
    setCreditPrice(25);
    setGender("");
    setVehicle("");
    setAvailabilityDate(formattedDate);
    setFromTime("09:00:00");
    setTransmissionType("");
  };

  return (
    <>
      <Navbar />
      <div className="clientdashboard_outer_container">
        <div className="clientdashboard_inner_container">
          <div className="findInstructor">
            <div className="findInstructor_filter">
              <div className="heading">
                <h3>Filter</h3>
                <p
                  onClick={clearButton}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  Clear All
                </p>
              </div>
              <div className="radius">
                <div className="inner_heading">
                  <bold>Radius</bold>
                  <p>0 mi - {radius} mi</p>
                </div>
                <Slider
                  value={radius}
                  onChange={handleChangeRadius}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextRadius}
                  min={1}
                  max={50}
                  step={1}
                />
              </div>
              <div className="price_range">
                <div className="inner_heading">
                  <bold>Price Range </bold>
                  <p>0 - £{creditPrice}/hr</p>
                </div>
                <Slider
                  value={creditPrice}
                  onChange={handleChangeCreditPrice}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetextPrice}
                  min={25}
                  max={100}
                  step={5}
                />
              </div>
              <div className="transmission">
                <h4>Transmission</h4>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="transmission"
                    value="BOTH"
                    checked={transmissionType === "BOTH"}
                    // setUserData?.profile?.transmissionTypePreference
                    onChange={(e) => setTransmissionType(e.target.value)}
                  />
                  <label> All </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="transmission"
                    value="AUTOMATIC"
                    checked={transmissionType === "AUTOMATIC"}
                    onChange={(e) => setTransmissionType(e.target.value)}
                  />
                  <label> Automatic </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="transmission"
                    value="MANUAL"
                    checked={transmissionType === "MANUAL"}
                    onChange={(e) => setTransmissionType(e.target.value)}
                  />
                  <label> Manual </label>
                </div>
              </div>
              <div className="availability">
                <h4>Availability</h4>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="availability"
                    value="ALL"
                    checked={availabilityDate === "ALL"}
                    onChange={(e) => setAvailabilityDate(e.target.value)}
                  />
                  <label> All </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="availability"
                    value="TODAY"
                    checked={availabilityDate === "TODAY"}
                    onChange={(e) => setAvailabilityDate(e.target.value)}
                  />
                  <label> Available Today </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="availability"
                    value="NEXTWEEK"
                    checked={availabilityDate === "NEXTWEEK"}
                    onChange={(e) => setAvailabilityDate(e.target.value)}
                  />
                  <label> Available Next Week </label>
                </div>
                {/* <div className="filterRadio">
                  <input
                    type="radio"
                    name="availability"
                    value=""
                    onChange={(e) => {
                      if (e.target.value === "MANUAL") {
                        setAvailabilityDate(`${nextWeekFormattedDate}`);
                      }
                    }}
                  />
                  <label> Specific Date and Time </label>
                </div> */}
              </div>
              <div className="cartypes">
                <h4>Vehicle </h4>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="vehicle"
                    value="ALL"
                    checked={vehicle === "ALL"}
                    onChange={(e) => setVehicle(e.target.value)}
                  />
                  <label> All </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="vehicle"
                    value="HATCHBACK"
                    checked={vehicle === "HATCHBACK"}
                    onChange={(e) => setVehicle(e.target.value)}
                  />
                  <label> Hatchback </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="vehicle"
                    value="SEDAN"
                    checked={vehicle === "SEDAN"}
                    onChange={(e) => setVehicle(e.target.value)}
                  />
                  <label> Sedan </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="vehicle"
                    value="CROSSOVER"
                    checked={vehicle === "CROSSOVER"}
                    onChange={(e) => setVehicle(e.target.value)}
                  />
                  <label> Crossover </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="vehicle"
                    value="COUPE"
                    checked={vehicle === "COUPE"}
                    onChange={(e) => setVehicle(e.target.value)}
                  />
                  <label> Coupe </label>
                </div>
              </div>
              <div className="gender">
                <h4>Gender </h4>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="gender"
                    value="ALL"
                    checked={gender === "ALL"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label> All </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="gender"
                    value="MALE"
                    checked={gender === "MALE"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label> Male </label>
                </div>
                <div className="filterRadio">
                  <input
                    type="radio"
                    name="gender"
                    value="FEMALE"
                    checked={gender === "FEMALE"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label> Female </label>
                </div>
              </div>
            </div>
            <div className="instructorList">
              <div
                className="row1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="searchBar" style={{ width: "100%" }}>
                  <BsSearch style={{ color: "#6FA5EF" }} />
                  <input
                    type="text"
                    placeholder="Type first 2 letters of the postcode, for ex: 'TW'"
                    onChange={(e) => {
                      const input = e.target.value.substring(0, 2);
                      const processedInput = input?.replace(/\s/g, "");
                      setPostalCode(processedInput);
                    }}
                    style={{ outline: "none" }}
                  />
                </div>

                <BsFilterCircle
                  onClick={showDrawer}
                  size="3rem"
                  style={{
                    marginLeft: "1rem",
                  }}
                  className="findInstructor_filter_responsive"
                />

                <Drawer
                  title="Filter"
                  placement="right"
                  onClose={onClose}
                  open={open}
                >
                  <div>
                    <p
                      onClick={clearButton}
                      style={{
                        color: "red",
                        cursor: "pointer",
                        textAlign: "end",
                      }}
                    >
                      Clear All
                    </p>

                    <div className="radius" style={{ marginTop: "1rem" }}>
                      <div
                        className="inner_heading"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <bold>Radius</bold>
                        <p>0 mi - {radius} mi</p>
                      </div>
                      <Slider
                        value={radius}
                        onChange={handleChangeRadius}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetextRadius}
                        min={1.5}
                        max={50}
                        step={0.5}
                      />
                    </div>
                    <div className="price_range" style={{ marginTop: "1rem" }}>
                      <div
                        className="inner_heading"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <bold>Price Range</bold>
                        <p>0 - £{creditPrice}/hr</p>
                      </div>
                      <Slider
                        value={creditPrice}
                        onChange={handleChangeCreditPrice}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetextPrice}
                        min={25}
                        max={100}
                        step={5}
                      />
                    </div>
                    <div className="transmission" style={{ marginTop: "1rem" }}>
                      <h4>Transmission</h4>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="transmission"
                          value="BOTH"
                          checked={transmissionType === "BOTH"}
                          // setUserData?.profile?.transmissionTypePreference
                          onChange={(e) => setTransmissionType(e.target.value)}
                        />
                        <label> All </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="transmission"
                          value="AUTOMATIC"
                          checked={transmissionType === "AUTOMATIC"}
                          onChange={(e) => setTransmissionType(e.target.value)}
                        />
                        <label> Automatic </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="transmission"
                          value="MANUAL"
                          checked={transmissionType === "MANUAL"}
                          onChange={(e) => setTransmissionType(e.target.value)}
                        />
                        <label> Manual </label>
                      </div>
                    </div>
                    <div className="availability" style={{ marginTop: "1rem" }}>
                      <h4>Availability</h4>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="availability"
                          value="ALL"
                          checked={availabilityDate === "ALL"}
                          onChange={(e) => setAvailabilityDate(e.target.value)}
                        />
                        <label> All </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="availability"
                          value="TODAY"
                          checked={availabilityDate === "TODAY"}
                          onChange={(e) => setAvailabilityDate(e.target.value)}
                        />
                        <label> Available Today </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="availability"
                          value="NEXTWEEK"
                          checked={availabilityDate === "NEXTWEEK"}
                          onChange={(e) => setAvailabilityDate(e.target.value)}
                        />
                        <label> Available Next Week </label>
                      </div>
                      {/* <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="availability"
                          value=""
                          onChange={(e) => {
                            if (e.target.value === "MANUAL") {
                              setAvailabilityDate(`${nextWeekFormattedDate}`);
                            }
                          }}
                        />
                        <label> Specific Date and Time </label>
                      </div> */}
                    </div>
                    <div className="cartypes" style={{ marginTop: "1rem" }}>
                      <h4>Vehicle </h4>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="vehicle"
                          value="ALL"
                          checked={vehicle === "ALL"}
                          onChange={(e) => setVehicle(e.target.value)}
                        />
                        <label> All </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="vehicle"
                          value="HATCHBACK"
                          checked={vehicle === "HATCHBACK"}
                          onChange={(e) => setVehicle(e.target.value)}
                        />
                        <label> Hatchback </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="vehicle"
                          value="SEDAN"
                          checked={vehicle === "SEDAN"}
                          onChange={(e) => setVehicle(e.target.value)}
                        />
                        <label> Sedan </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="vehicle"
                          value="CROSSOVER"
                          checked={vehicle === "CROSSOVER"}
                          onChange={(e) => setVehicle(e.target.value)}
                        />
                        <label> Crossover </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="vehicle"
                          value="COUPE"
                          checked={vehicle === "COUPE"}
                          onChange={(e) => setVehicle(e.target.value)}
                        />
                        <label> Coupe </label>
                      </div>
                    </div>
                    <div className="gender" style={{ marginTop: "1rem" }}>
                      <h4>Gender </h4>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="gender"
                          value="ALL"
                          checked={gender === "ALL"}
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label> All </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="gender"
                          value="MALE"
                          checked={gender === "MALE"}
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label> Male </label>
                      </div>
                      <div className="filterRadio" style={{ marginTop: "5px" }}>
                        <input
                          type="radio"
                          name="gender"
                          value="FEMALE"
                          checked={gender === "FEMALE"}
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label> Female </label>
                      </div>
                    </div>
                  </div>
                </Drawer>
              </div>
              <ConnectInstructor
                data={instructorList}
                apiRunning={apiRunning}
                setReload={setReload}
                studentTransmissionPreference={studentTransmissionPreference}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindInstructor;
