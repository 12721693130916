import React from "react";
import { BsSearch } from "react-icons/bs";

const SearchBar = ({ setSearchQuery }) => {
  return (
    <div className="searchBar ">
      <BsSearch style={{ color: "#6FA5EF" }} />
      <input
        type="text"
        placeholder="Search...."
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ outline: "none" }}
        className=""
      />
    </div>
  );
};

export default SearchBar;
