import React, { useEffect, useState } from "react";
// import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
// import { UserContext } from "../../../layouts/UseContext";
import bookdriveLogo from "./../../../assets/img/BookDrive_logo-invert.jpg";
import axios from "axios";
import fpBanner from "./../../../assets/img/fpBanner.png";
// import { Select, Space } from "antd";
import { Dropdown } from "antd";
import androidApp from "./../../../assets/img/googleAndroid.webp";
import appleStore from "./../../../assets/img/appstore.png";
import secondBanner from "./../../../assets/img/second_banner.png";
import performance1 from "./../../../assets/img/performance1.svg";
import performance2 from "./../../../assets/img/performance2.svg";
import performance3 from "./../../../assets/img/performance3.svg";
import banner3 from "./../../../assets/img/banner3.png";
import banner4 from "./../../../assets/img/banner4.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiFacebookCircleLine, RiInstagramLine } from "react-icons/ri";
import { MenuItem, Select } from "@mui/material";

const ClientLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParamsStatus = new URLSearchParams(location.search);
  const role = queryParamsStatus.get("r");

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const [selectedRole, setSelectedRole] = useState("INSTRUCTOR");
  const [visitorEmail, setVisitorEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // const [emailError, setEmailError] = useState("");

  const roleOptions = ["INSTRUCTOR", "STUDENT"];

  useEffect(() => {
    if (role === "c") {
      toast.error("Invalid ID! Choose the correct portal for Login.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }, [role]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      userRoleName: selectedRole,
      email: visitorEmail,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/landingPage/welcomeUser`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Message Sent", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setVisitorEmail("");
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    console.log(selectedRole);
    console.log(visitorEmail);
  }, [selectedRole, visitorEmail]);

  const items = [
    {
      label: (
        <p
          className="boldText"
          style={{ padding: ".5rem 1rem" }}
          onClick={() => navigate("/auth/?Role=STUDENT")}
        >
          Student
        </p>
      ),
      key: "0",
    },
    {
      label: (
        <p
          className="boldText"
          style={{ padding: ".5rem 1rem" }}
          onClick={() => navigate("/auth/?Role=DRIVING_SCHOOL")}
        >
          School
        </p>
      ),
      key: "1",
    },
    {
      label: (
        <p
          className="boldText"
          style={{ padding: ".5rem 1rem" }}
          onClick={() => navigate("/auth/?Role=BOOK_DRIVE")}
        >
          Admin
        </p>
      ),
      key: "2",
    },
  ];

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        className="login_outer_container"
        style={{
          height: "max-content",
          backgroundColor: "#242a46",
        }}
      >
        <div className="fp_page" style={{ overflowX: "hidden" }}>
          <div className="fp_header" style={{ overflowX: "hidden" }}>
            <img src={bookdriveLogo} alt="" />
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              className="loginButton boldText"
            >
              <button onClick={(e) => e.preventDefault()}>Login As</button>
            </Dropdown>
          </div>

          <div
            className="fp_row1"
            style={{ overflowX: "hidden" }}
            data-aos="fade-in"
          >
            <div className="leftSection">
              <h1 className="boldText">
                Grow your business with our powerful system
              </h1>
              <h5>
                Are you a driving instructor or student that finds booking
                driving classes hard? Check out our fully integrated digital
                solution.
              </h5>

              <form onSubmit={handleSubmit} className="visitor_info">
                <div className="visitor_info_role ">
                  <h3 className="boldText">I'm a(n)</h3>
                  <span className="boldText"> |</span>
                  <select
                    id="role"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    {roleOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {/* <Select
                    className="visitorSelect"
                    labelId="role-label"
                    id="role"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    {roleOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select> */}
                </div>

                <div className="visitor_info_email">
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    className="boldText"
                    value={visitorEmail}
                    onChange={(e) => setVisitorEmail(e.target.value)}
                    required
                  />
                </div>

                <button type="submit" className="submitButton">
                  Submit
                </button>
              </form>
            </div>
            <div className="rightSection">
              <img src={fpBanner} className="phone_image" alt="" />
              <div className="fp_connect">
                <h2 className="boldText">
                  For Instructors, Download our mobile applicaion.
                </h2>
                <div className="downloadOptions">
                  <img
                    src={androidApp}
                    className="downloadImage"
                    alt=""
                    onClick={() =>
                      openLinkInNewTab(
                        "https://play.google.com/store/apps/details?id=com.lokd.bookdrive"
                      )
                    }
                  />
                  <img
                    src={appleStore}
                    className="downloadImage"
                    alt=""
                    onClick={() =>
                      openLinkInNewTab(
                        "https://apps.apple.com/us/app/book-drive/id6451147269"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fp_row2">
        <div className="leftSection" data-aos="slide-right">
          <h1 className="boldText">
            An app for instructors and students alike
          </h1>
          <p>
            Book Drive is a modern, user-friendly app designed efficiently for
            driving instructors that streamlines all administrative and
            middle-level business management, including diary management,
            schedule management and finance management. It integrates with pupil
            flow, and they can manage their bookings, money, credits and live
            track with a tap.
          </p>
        </div>
        <div className="rightSection" data-aos="slide-left">
          <img src={secondBanner} alt="" />
        </div>
      </div>
      <div className="fp_row3" data-aos="fade-in">
        <h1 className="boldText">Improved Business Performance</h1>
        <div className="performance">
          <div className="performanceBlock" data-aos="slide-right">
            <img src={performance1} alt="" />
            <h1>Data Driven insights</h1>
            <p>
              Capture your business data and measure business performance with a
              few clicks. Easily track revenue, pupil progress, completed hours
              and hours planned to improve productivity.
            </p>
          </div>
          <div className="performanceBlock" data-aos="slide-up">
            <img src={performance2} alt="" />
            <h1>Save Time</h1>
            <p>
              The days of manually tracking hours, booking appointments, and
              manually completing accounts are over, and our App does that with
              a few clicks.
            </p>
          </div>
          <div className="performanceBlock" data-aos="slide-left">
            <img src={performance3} alt="" />
            <h1>Pupil friendly</h1>
            <p>
              Pupil interface includes live tracking, system, progress report,
              payments, manage credits and communication.
            </p>
          </div>
        </div>
      </div>
      <div className="fp_row4" data-aos="fade-in">
        <div className="leftSection" data-aos="slide-right">
          <img src={banner3} alt="" />
        </div>
        <div className="rightSection" data-aos="slide-left">
          <h1 className="boldText">Too busy is a thing of the past</h1>
          <p>
            Our App frees up your time to focus on what you do best, and all the
            hassle of numbers, scheduling, and tracking appointments are over.
          </p>
          <p style={{ marginTop: "2rem" }}>
            Book Drive App integrates with your calendar, and it can send
            automated email notifications messages and take
            appointments/payments in your sleep for free.
          </p>
        </div>
      </div>
      <div className="fp_row5" data-aos="fade-in">
        <div className="leftSection">
          <h1 className="boldText">To decide against this defies logic</h1>
          <p>
            We are so convinced of our product, and we are giving out 3 months
            for free. We are willing to put our product to the test, are you?
            Subscribe to our newsletter and keep yourself updated about Book
            Drive!
          </p>
        </div>
        <div className="rightSection">
          <img src={banner4} alt="" />
        </div>
      </div>
      <div className="fp_footer">
        <div className="footer_row1">
          <div className="leftSection">
            <img src={bookdriveLogo} alt="" />
            <h2>Hello@bookdrive.co.uk</h2>
          </div>

          <div className="rightSection">
            <RiFacebookCircleLine size={40} />
            <RiInstagramLine size={40} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ClientLogin;
