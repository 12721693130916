import React, { useState } from "react";
import Navbar from "../../../../layouts/Navbar";
import noProfilePicture from "../../../../assets/img/noProfileImage.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useEffect } from "react";
import { UserContext } from "../../../../layouts/UseContext";
import { useContext } from "react";
import axios from "axios";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CreateIcon from "@mui/icons-material/Create";
import { CircularProgress } from "@mui/material";
import { NavLink } from "react-router-dom";
import { FaStripe } from "react-icons/fa";

const UserProfile = () => {
  const { userId } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/${userId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setUserData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [userId]);

  return (
    <>
      <Navbar />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="clientdashboard_inner_container userProfile">
          <h1>My Profile</h1>
          <div className="changeProfilePicture">
            <div
              class="circle"
              style={{
                backgroundColor:
                  userData?.data?.profile?.profileColor ?? "#6FA5EF",
              }}
            >
              <img
                class="image"
                src={
                  userData?.data?.profile?.profilePicture ?? noProfilePicture
                }
                alt="profile"
              />
            </div>
          </div>
          <div className="editProfile">
            <NavLink to="/userProfileEdit">
              <CreateIcon
                style={{
                  float: "right",
                  backgroundColor: "#D9D9D9",
                  padding: ".5rem",
                  color: "black",
                  borderRadius: "50%",
                }}
              />
            </NavLink>
          </div>
          <div className="userInputField">
            <p>
              <b>Name</b>
            </p>
            <input
              type="text"
              disabled
              value={userData?.data?.profile?.firstName}
            />
          </div>
          <div className="userInputField ">
            <p>
              <b>Email</b>
            </p>

            <input
              type="text"
              value={userData?.data?.profile?.email}
              disabled
            />
          </div>
          <div className="userInputField">
            <p>
              <b>Phone Number</b>
            </p>
            <input
              type="text"
              value={userData?.data?.profile?.phnNumber}
              disabled
            />
          </div>
          <div className="userInputField">
            <p>
              <b>Address</b>
            </p>
            <input
              type="text"
              value={userData?.data?.address[0]?.address ?? "Select an Address"}
              disabled
            />
          </div>
          <div className="userInputField">
            <p>
              <b>Payment Method</b>
            </p>
            <div className="paymentInfo">
              <p style={{ marginRight: ".5rem", color: "#8990AF" }}>
                Powered by
              </p>
              <FaStripe size={"3rem"} style={{ color: "#8990AF" }} />
            </div>
          </div>
          <div className="userInputField">
            <p>
              <b>Preference For Lessons</b>
            </p>
            <input
              type="text"
              disabled
              value={userData?.data?.profile?.preferenceLesson}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
