import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";
import SuccessTick from "./../../../assets/img/success.gif";
import { useParams } from "react-router";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();
  const { insId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");

  useEffect(() => {
    let data = JSON.stringify({
      stripeSessionId: sessionId,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/stripeTransaction/updateTransaction`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const delayInSeconds = 3;

        setTimeout(() => {
          navigate(`/instructorProfile/${insId}`);
        }, delayInSeconds * 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="login_outer_container" style={{ backgroundColor: "white" }}>
      <div className="login_inner_container">
        <div
          style={{
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "black",
          }}
        >
          <img src={SuccessTick} alt="" />
          <h1>Success</h1>
          <h2>Thank you for your purchase!</h2>
          <NavLink to={`/instructorProfile/${insId}`}>
            <button
              style={{
                width: "max-content",
                padding: ".5rem 1rem ",
                borderRadius: "10px",
                marginTop: "2rem",
                border: "1px solid gray",
              }}
            >
              OK
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Success;
