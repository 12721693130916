import React, { useEffect, useState, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import Table from "@mui/material/Table";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal, { ModalRoot } from "@mui/material/Modal";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Space } from "antd";
import { NavLink, useParams } from "react-router-dom";
import AddInstructor from "./AddInstructor";
import AddCredits from "./AddCredits";
import DemoIns from "./../../../assets/img/demo_image.png";
import NavbarAdmin from "../../../layouts/AdminNavbar";
import axios from "axios";
import noProfileImage from "../../../assets/img/noProfileImage.png";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export const AdminInstructors = () => {
  const storedUserId = localStorage.getItem("userId");
  const storedUserRole = localStorage.getItem("userRole");
  const { DSid } = useParams();
  const [instructorList, setInstructorList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [reload, setReload] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeleteInst, setSelectedDeleteInst] = useState([]);

  console.log("asdasdasdas", storedUserRole);

  const getDropdownItems = (row) => {
    console.log(")))))))))", row);
    const suspendOption = {
      key: "1",
      label: (
        <div onClick={() => handleSuspendClick(row?.profile?.instructorId)}>
          Suspend
        </div>
      ),
    };

    const activeOption = {
      key: "2",
      label: (
        <div onClick={() => handleActivateClick(row?.profile?.instructorId)}>
          Activate
        </div>
      ),
    };

    const calendarOption = {
      key: "3",
      label: (
        <NavLink to={`/inst/calendar/${row?.profile?.instructorId}`}>
          Calendar
        </NavLink>
      ),
    };

    const addCredit = {
      key: "4",
      label: <AddCredits instructorId={row?.profile?.instructorId} />,
    };

    const studentOptions = {
      key: "5",
      label: (
        <NavLink to={`/admin/students?instId=${row?.profile?.instructorId}`}>
          Students
        </NavLink>
      ),
    };

    let deleteOption = null;

    if (storedUserRole === "BOOK_DRIVE") {
      deleteOption = {
        key: "6",
        label: (
          <div
            style={{
              backgroundColor: "red",
              color: "white",
              width: "100%",
              padding: "0.5rem 0",
              borderRadius: "10px",
              textAlign: "center",
            }}
            onClick={() => {
              setSelectedDeleteInst(row?.profile);
              setDeleteModal(true);
            }}
          >
            Delete
          </div>
        ),
      };
    }

    return row?.profile?.isActive === false
      ? [activeOption, deleteOption]
      : [
          suspendOption,
          calendarOption,
          addCredit,
          studentOptions,
          deleteOption,
        ];
  };

  function formatDate1(inputDate) {
    const parts = inputDate?.split("-");
    if (parts?.length === 3) {
      const [year, month, day] = parts;
      return `${day}/${month}/${year}`;
    } else {
      return "Invalid Date";
    }
  }

  useEffect(() => {
    let url;

    setLoading(true);
    if (storedUserRole === "BOOK_DRIVE") {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/api/instructor?pageNumber=0&pageSize=9999&searchString=&drivingId=${storedUserId}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {},
    };
    axios
      .request({ ...config, url: url })
      .then((response) => {
        // console.log(response?.data?.data?.instructor);
        setInstructorList(response?.data?.data?.instructor);
        setFilteredList(response?.data?.data?.instructor);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [storedUserId, reload]);

  const handleSuspendClick = (instId) => {
    let data = JSON.stringify({
      id: instId,
      userRole: "INSTRUCTOR",
      active: false,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteInst = (itemId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/instructor/${itemId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setDeleteModal(false);
        setReload(Math.random());
        toast.success("Instructor Deleted!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error! Try Again Later!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleActivateClick = (instId) => {
    let data = JSON.stringify({
      id: instId,
      userRole: "INSTRUCTOR",
      active: true,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/student/softDelete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setReload(Math.random());
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let lowercaseValue = searchQuery?.toLowerCase();
    const filtered = instructorList?.filter((classes) => {
      return (
        lowercaseValue.trim() === "" ||
        classes?.profile?.lastName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.firstName?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.adiCode?.toLowerCase()?.includes(lowercaseValue) ||
        classes?.profile.pdiCode?.toLowerCase()?.includes(lowercaseValue)
      );
    });

    setFilteredList(filtered);
  }, [searchQuery, instructorList]);

  return (
    <div className="admin_outer_container">
      <NavbarAdmin />
      <div style={{ width: "100%", margin: "1rem 2rem" }}>
        <div className="adminInstructors_list_row1">
          <h1>Instructors</h1>
          <AddInstructor setReload={setReload} />
        </div>

        <div className="searchBar">
          <BsSearch style={{ color: "#6FA5EF" }} />
          <input
            type="text"
            placeholder="Search...."
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ outline: "none" }}
          />
        </div>

        {loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10rem",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeading" align="center">
                    Name
                  </TableCell>
                  <TableCell className="tableHeading" align="center">
                    Contact
                  </TableCell>
                  <TableCell className="tableHeading" align="center">
                    Badge Number
                  </TableCell>
                  <TableCell className="tableHeading" align="center">
                    Subscription Expiry
                  </TableCell>
                  <TableCell className="tableHeading" align="center">
                    Status
                  </TableCell>
                  <TableCell className="tableHeading" align="center">
                    Registered
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredList?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.profile?.isActive === true ? (
                        <NavLink
                          to={`/admin/instructorsProfile/${row?.profile?.instructorId}`}
                        >
                          <div className="instructor_info_1">
                            <img
                              style={{ borderRadius: "50%" }}
                              src={
                                row?.profile?.profilePicture ?? noProfileImage
                              }
                              alt="asds"
                            />
                            <div className="license_name">
                              <p>{`${row?.profile?.firstName} ${row?.profile?.lastName}`}</p>
                            </div>
                          </div>
                        </NavLink>
                      ) : (
                        <div className="instructor_info_1">
                          <img
                            style={{ borderRadius: "50%" }}
                            src={row?.profile?.profilePicture ?? noProfileImage}
                            alt="asds"
                          />
                          <div className="license_name">
                            <p>{`${row?.profile?.firstName} ${row?.profile?.lastName}`}</p>
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div className="contact_info">
                        <p> {row?.profile?.phoneNumber}</p>
                        <p>
                          {row?.profile?.email && row.profile?.email.length > 20
                            ? `${row.profile?.email.substring(0, 20)}...`
                            : row.profile?.email}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="contact_info">
                        <p> {row?.profile?.adiCode}</p>
                        <p> {row?.profile?.pdiCode}</p>
                        <p> {row?.profile?.badgeExpiry}</p>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {formatDate1(row?.profile?.planExpiryDate)}
                    </TableCell>
                    <TableCell align="center">
                      {row?.profile?.isActive === true ? "Active" : "Suspend"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.profile?.register === true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="right">
                      <Space direction="vertical">
                        <Space wrap>
                          <Dropdown
                            menu={{
                              items: getDropdownItems(row),
                            }}
                            placement="bottomRight"
                          >
                            <BsThreeDotsVertical
                              style={{ cursor: "pointer" }}
                            />
                          </Dropdown>
                        </Space>
                      </Space>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Delete Instructor
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div
              style={{
                marginBottom: "1rem",
                textAlign: "center",
                margin: "0 auto",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* {JSON.stringify(selectedDeleteInst, null, 2)} */}
              {selectedDeleteInst && typeof selectedDeleteInst === "object" && (
                <>
                  <div
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      style={{
                        aspectRatio: 1,
                        width: "5rem",
                        borderRadius: "50%",
                      }}
                      src={selectedDeleteInst?.profilePicture ?? noProfileImage}
                    />
                    <div style={{ textAlign: "start" }}>
                      <p>
                        Name: {selectedDeleteInst?.firstName}{" "}
                        {selectedDeleteInst?.lastName}
                      </p>
                      <p>Email: {selectedDeleteInst?.email}</p>
                      <p>
                        Status:{" "}
                        {selectedDeleteInst?.isActive === false
                          ? "Suspend"
                          : "Active"}
                      </p>
                      <p>
                        Registered:{" "}
                        {selectedDeleteInst?.register === false ? "No" : "Yes"}
                      </p>
                    </div>
                  </div>
                  <h3 style={{ marginTop: "1rem" }}>
                    Are you sure you want to delete this Instructor?
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "1rem",
                      gap: "1rem",
                    }}
                  >
                    <button
                      style={{ color: "black" }}
                      onClick={() => setDeleteModal(false)}
                    >
                      Exit
                    </button>

                    <button
                      style={{ color: "white", backgroundColor: "#2A335F" }}
                      onClick={() =>
                        handleDeleteInst(selectedDeleteInst?.instructorId)
                      }
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};
